import React from 'react'
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { message } from 'antd';

import Login from "./pages/login/login";
import Main from './pages/main/main'
import YellowPages from './pages/yellowpages/YellowPagesIndex'
import yellowPageList from './pages/yellowpages/yellowpages-list/yellowPagesList'
import yellowPagesDetail from './pages/yellowpages/yellowpages-detail/yellowPagesDetail'
import bbsPages from './pages/BBS/bbsPagesIndex'
import bbsPageList from './pages/BBS/bbspage-list/bbsPageList'
import bbsPageDetail from './pages/BBS/bbspage-detail/bbsPageDetail'
import bbsPageNew from './pages/BBS/bbspage-new/bbsPageNew'
import Myplace from './pages/myplace/myPlaceIndex'
// import Myplacepageperson from './pages/myplace/myplace-page-person'
import Merchantsettled from './pages/merchantsettled/merchantsettled'
import Connectus from './pages/connectus/connectus'
import './App.css';
import ScrollToTop from '../src/component/scrolltotop/ScrollToTop'
import intl from 'react-intl-universal';
import Cookies from 'js-cookie'

import person from './api/user'


// locale data
const locales = {
  "en-US": require('./locales/en-US.json'),
  "zh-CN": require('./locales/zh-CN.json'),
  "zh-CH": require('./locales/ZH-CH.json'),
};


export default class App extends React.Component {
  state = { initDone: false }

  componentDidMount() {
    this.getCookies()
    this.loadLocales();
    window.addEventListener('resize', this.resizeListener);
    this.resizeListener();
  }

  getCookies() {
    let userId = Cookies.get('id')
    let token = Cookies.get('token')
    if (userId && token) {
      person.customerDetail({ customerId: userId }, token).then(res => res.json())
        .then(result => {
          console.log('result', result)
          if (result.ret === 200) {
            if (result.data.isMerchants === 1) {
              message.warning(intl.get('ZHANGHAOSHENHEZHONG'), 2.5)
              return
            }
            let obj = {
              customer: result.data,
              token
            }
            sessionStorage.setItem("USERDATA", JSON.stringify(obj));
            this.setState({})
          }
        })
    }
  }

  loadLocales() {
    console.log('app=====aaaa', sessionStorage['languageFilter'])
    // react-intl-universal 是单例模式, 只应该实例化一次

    console.log('sessionStorage ====>language', sessionStorage['language'])
    if (!sessionStorage['language']) {
      sessionStorage['language'] = 1
      sessionStorage['languageTitle'] = '简体中文'
    }
    intl.init({
      currentLocale: sessionStorage['languageFilter'] ? sessionStorage['languageFilter'] : 'zh-CN', // TODO: determine locale here
      locales,
    })
      .then(() => {
        this.setState({ initDone: true });
      });
  }
  // 在组件从 DOM 中移除之前立刻被调用
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);

  }

  resizeListener() {
    // 定义设计图的尺寸 1920
    let designSize = 1920;
    // 获取 html 元素
    let html = document.documentElement;
    // 定义窗口的宽度
    let clientW = html.clientWidth;

    let sizeNum = 100;
    if (clientW <= 1000) {
      sizeNum = 100
    } else if (clientW <= 1360) {
      sizeNum = 130
    } else if (clientW <= 1400) {
      sizeNum = 120
    } else if (clientW <= 1600) {
      sizeNum = 110
    }


    // html 的fontsize 大小
    let htmlRem = clientW * sizeNum / designSize;
    html.style.fontSize = htmlRem + 'px';
  }



  render() {
    return (
      this.state.initDone &&
      <Router>
        <ScrollToTop>
          <div>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/main" component={Main} />
              <Route path="/" component={Main} exact />
              <Route path="/yellowpages" component={YellowPages} exact />
              <Route path="/yellowPageList" component={yellowPageList} exact />
              <Route path="/yellowPagesDetail/:detailParms" component={yellowPagesDetail} exact />
              <Route path="/bbspages" component={bbsPages} exact />
              <Route path="/bbspagelist" component={bbsPageList} exact />
              <Route path="/bbspagedetail/:manyParams" component={bbsPageDetail} exact />
              <Route path="/bbspagenew" component={bbsPageNew} exact />
              <Route path="/myplace" component={Myplace} exact />
              <Route path="/merchantsettled" component={Merchantsettled} exact />
              <Route path="/connectus" component={Connectus} />
            </Switch>
          </div>
        </ScrollToTop>
      </Router>
    )
  }

}

