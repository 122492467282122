import intl from "react-intl-universal";
export function homeRentType() {
  return (
    [

      {
        baseComponent: intl.get("JICHUXINXI"),
        show: true,
        id: 1,
        data: [
          {
            type: "specialSelect",
            name: "childCategory",
            require: false,
            requireMsg: 'please select',
            label: intl.get("XINGZXHI"),
            typeData: [],
          },
          {
            type: "input",
            name: "mainTitle",
            require: true,
            requireMsg: 'please input',
            label: intl.get("BIAOTIE"),
          },
          {
            type: "radio",
            name: "sellerType",
            require: true,
            requireMsg: 'please select',
            label: intl.get("FATIELEIXING"),
            typeData: [
              {
                label: intl.get("GEREN"),
                value: 1,
              },
              {
                label: intl.get("SHANGJIA"),
                value: 2,
              },
            ],
          },
          {
            type: "radio",
            name: "letOutType",
            require: true,
            requireMsg: 'please select',
            label: intl.get("CHUZHUFANGSHI"),
            typeData: [
              {
                label: intl.get("DANJIAN"),
                value: 1,
              },
              {
                label: intl.get("KETING"),
                value: 2,
              },
              {
                label: intl.get("ZHENGZU"),
                value: 3,
              },
              {
                label: intl.get("HEZHU"),
                value: 4,
              },
              {
                label: intl.get("ZHUANGZU"),
                value: 5,
              },
              {
                label: intl.get("CHUANGWEI"),
                value: 6,
              },
            ],
          },
          {
            type: "input",
            name: "rentMoney",
            require: true,
            requireMsg: 'please input',
            label: intl.get("FNAGWUZUJIN"),
          },
          {
            type: "select",
            name: "checkInTime",
            mutial: false,
            require: true,
            requireMsg: 'please select',
            label: intl.get("RUZHUSHIJIAN"),
            typeData: [
              {
                label: 'Any date',
                value: 1,
              },
              {
                label: 'Avail now',
                value: 2,
              },
              {
                label: 'Before',
                value: 3,
              },
              {
                label: 'Today',
                value: 4,
              }
            ]
          },
          {
            type: "input",
            name: "rentTime",
            require: true,
            requireMsg: 'please input',
            label: intl.get("ZUIDUANZUQI"),
          },
          {
            type: "select",
            name: "roomType",
            mutial: true,
            require: true,
            requireMsg: 'please select',
            label: intl.get("FANGWUHUXING"),
            typeData: [
              {
                label: intl.get("BUXIAN"),
                value: 1,
              },
              {
                label: intl.get("DULIWU"),
                value: 2,
              },
              {
                label: intl.get("GONGYU"),
                value: 3,
              },
              {
                label: intl.get("DANYUAN"),
                value: 4,
              },
              {
                label: intl.get("GONGZUOSHI"),
                value: 5,
              },
              {
                label: intl.get("LIANPAIBIESHU"),
                value: 6,
              },
              {
                label: intl.get("CANGKU"),
                value: 7,
              },
              {
                label: intl.get("BANGONGSHI"),
                value: 8,
              },
              {
                label: intl.get("SHANGPU"),
                value: 9,
              },
              {
                label: intl.get("QITA"),
                value: 10,
              }
            ]
          },

          {
            type: "select",
            name: "regionId",
            mutial: true,
            require: true,
            requireMsg: 'please select',
            label: intl.get("SUOZAIQUYU"),
            typeData: [
              {
                label: intl.get("BULISB"),
                value: 1,
              },
              {
                label: intl.get("HUANGJIANHAN"),
                value: 2,
              },
              {
                label: intl.get("YANGHUANGHANHAN"),
                value: 3,
              },
              {
                label: intl.get("KAIENSI"),
                value: 4,
              }
            ]
          },

          {
            type: "input",
            name: "address",
            require: false,
            requireMsg: 'please input',
            label: intl.get("DIZHI"),
          },



          {
            type: "checkbox",
            name: "roomConfig",
            require: false,
            requireMsg: 'please select',
            label: intl.get("FANGWUPEIZHI"),
            typeData: [
              {
                label: intl.get("BAOSHUI"),
                value: 1,
              },
              {
                label: intl.get("BAODIAN"),
                value: 2,
              },
              {
                label: intl.get("MEIQI"),
                value: 3,
              },
              {
                label: intl.get("WUXIANWANGLUO"),
                value: 4,
              },
              {
                label: intl.get("KONGTIAO"),
                value: 5,
              },
              {
                label: intl.get("TINGCHEWEI"),
                value: 6,
              },
              {
                label: intl.get("BINGXIANG"),
                value: 7,
              },
              {
                label: intl.get("WEIBOLU"),
                value: 8,
              },
              {
                label: intl.get("XIYIJI"),
                value: 9,
              },
              {
                label: intl.get("DIANSHIJI"),
                value: 10,
              },
              {
                label: intl.get("QITA"),
                value: 11,
              },
            ],
          },

          {
            type: "checkbox",
            name: "nearConfig",
            require: false,
            requireMsg: 'please select',
            label: intl.get("FUJINSESHI"),
            typeData: [
              {
                label: intl.get("XUEXIAO"),
                value: 1,
              },
              {
                label: intl.get("CHAOSHI"),
                value: 2,
              },
              {
                label: intl.get("HUOCHEZHAN"),
                value: 3,
              },
              {
                label: intl.get("GONGCHEZHAN"),
                value: 4,
              },
              {
                label: intl.get("YOUYONGCHE"),
                value: 5,
              },
              {
                label: intl.get("JIANSHENFANG"),
                value: 6,
              },
              {
                label: intl.get("QITA"),
                value: 7,
              },
            ],
          }
        ]
      },
      {
        baseComponent: intl.get("XIANGQINGMIAOSHU"),
        show: true,
        id: 2,
        data: [
          {
            type: "edit",
            name: "context",
            require: true,
            label: intl.get("XIANGQINGMIAOSHU"),
          }
        ]
      },
      {
        baseComponent: intl.get("SHANGCHUANGTUPIAN"),
        show: true,
        id: 3,
        data: [
          {
            type: "upload",
            name: "path",
            require: false,
            label: intl.get("SHANGCHUANGTUPIAN"),
          }
        ]
      },

      {
        baseComponent: intl.get("LIANXIFANGSHI"),
        show: true,
        id: 4,
        data: [
          {
            type: "input",
            name: "phone",
            require: true,
            requireMsg: 'please input',
            label: intl.get("LIANXIDIANHUA"),
          },
          {
            type: "input",
            name: "email",
            require: false,
            label: intl.get("DIANZIYOUJIAN"),
          },
          {
            type: "input",
            name: "wechat",
            require: false,
            label: intl.get("WEIXIN"),
          },
          {
            type: "input",
            name: "line",
            require: false,
            label: intl.get("LINE"),
          },
          {
            type: "input",
            name: "facebook",
            require: false,
            label: intl.get("Facebook"),
          },
          {
            type: "input",
            name: "qq",
            require: false,
            label: intl.get("QQ"),
          },
        ]
      }


    ]
  )
}