import { httpPost, httpPostWithUUID, httpPostByJSON } from '../utils/request'

const forum = {
    // 获取论坛分类
    forumCategoryListWeb(parms) {
        const url = '/website/forumBack/forumCategoryListWeb'
        let query = {}
        console.log('parms', parms)
        if (parms.categoryName) {
            query.categoryName = parms.categoryName
        }
        if (parms.categoryId) {
            query.categoryId = parms.categoryId
        }

        if (parms.parentId !== null && parms.parentId !== undefined && parms.parentId !== '') {
            query.parentId = parms.parentId
        }

        if (parms.language) {
            query.language = parms.language
        }

        if (parms.type) {
            query.type = parms.type
        }

        query.stat = 1
        return httpPost(url, query)
    },

    // 获取论坛列表
    forumListWeb(parms) {
        const url = '/website/forumBack/forumListWeb'
        let query = {}
        if (parms.mainTitle) {
            query.mainTitle = parms.mainTitle
        }
        if (parms.subTitle) {
            query.subTitle = parms.subTitle
        }
        // if (parms.language) {
        //     query.language = parms.language
        // }
        if (parms.categoryId) {
            query.categoryId = parms.categoryId
        }
        if (parms.customerId) {
            query.customerId = parms.customerId
        }

        if (parms.childId) {
            query.childId = parms.childId
        }

        if (parms.regionId) {
            query.regionId = parms.regionId
        }

        if (parms.sort) {
            query.sort = parms.sort
        }



        if (parms.industry) {
            query.industry = parms.industry
        }
        if (parms.sellerType) {
            query.sellerType = parms.sellerType
        }

        if (parms.dealType) {
            query.dealType = parms.dealType
        }

        if (parms.newOrOld) {
            query.newOrOld = parms.newOrOld
        }

        if (parms.letOutType) {
            query.letOutType = parms.letOutType
        }

        if (parms.beginTime) {
            query.beginTime = parms.beginTime
        }

        if (parms.endTime) {
            query.endTime = parms.endTime
        }

        if (parms.isHot) {
            query.isHot = parms.isHot
        }
        query.endStat = 1
        query.pageSize = parms.pageSize
        query.pageNum = parms.pageNum
        return httpPost(url, query)
    },

    // 发帖
    addForumTopic(parms, token, uuid) {
        const url = '/website/forumWeb/addForumTopic'
        let query = {}
        if (parms.mainTitle) {
            query.mainTitle = parms.mainTitle
        }
        if (parms.subTitle) {
            query.subTitle = parms.subTitle
        }

        if (parms.categoryId) {
            query.categoryId = parms.categoryId
        }
        if (parms.customerId) {
            query.customerId = parms.customerId
        }

        if (parms.regionId) {
            query.regionId = parms.regionId
        }

        if (parms.childId) {
            query.childId = parms.childId
        }

        if (parms.context) {
            query.context = parms.context
        }
        if (parms.path) {
            query.path = parms.path
        }
        if (parms.childCategory) {
            query.childCategory = parms.childCategory
        }

        if (parms.sellerType) {
            query.sellerType = parms.sellerType
        }
        if (parms.serviceType) {
            query.serviceType = parms.serviceType
        }

        if (parms.industry) {
            query.industry = parms.industry
        }
        if (parms.companyName) {
            query.companyName = parms.companyName
        }

        if (parms.address) {
            query.address = parms.address
        }

        if (parms.price) {
            query.price = parms.price
        }

        if (parms.phone) {
            query.phone = parms.phone
        }
        if (parms.email) {
            query.email = parms.email
        }
        if (parms.wechat) {
            query.wechat = parms.wechat
        }









        if (parms.line) {
            query.line = parms.line
        }
        if (parms.facebook) {
            query.facebook = parms.facebook
        }

        if (parms.qq) {
            query.qq = parms.qq
        }

        if (parms.letOutType) {
            query.letOutType = parms.letOutType
        }

        if (parms.rentMoney) {
            query.rentMoney = parms.rentMoney
        }

        if (parms.checkInTime) {
            query.checkInTime = parms.checkInTime
        }

        if (parms.rentTime) {
            query.rentTime = parms.rentTime
        }
        if (parms.roomType) {
            query.roomType = parms.roomType
        }
        if (parms.roomConfig) {
            query.roomConfig = parms.roomConfig
        }




        if (parms.nearConfig) {
            query.nearConfig = parms.nearConfig
        }
        if (parms.workType) {
            query.workType = parms.workType
        }

        if (parms.post) {
            query.post = parms.post
        }

        if (parms.salary) {
            query.salary = parms.salary
        }

        if (parms.salaryType) {
            query.salaryType = parms.salaryType
        }

        if (parms.eduBack) {
            query.eduBack = parms.eduBack
        }

        if (parms.expType) {
            query.expType = parms.expType
        }
        if (parms.gender) {
            query.gender = parms.gender
        }
        if (parms.signType) {
            query.signType = parms.signType
        }





        if (parms.productName) {
            query.productName = parms.productName
        }
        if (parms.dealType) {
            query.dealType = parms.dealType
        }

        if (parms.productType) {
            query.productType = parms.productType
        }

        if (parms.newOrOld) {
            query.newOrOld = parms.newOrOld
        }

        if (parms.sendType) {
            query.sendType = parms.sendType
        }

        if (parms.eduBack) {
            query.eduBack = parms.eduBack
        }

        if (parms.expType) {
            query.expType = parms.expType
        }
        if (parms.gender) {
            query.gender = parms.gender
        }
        if (parms.signType) {
            query.signType = parms.signType
        }

        if (parms.isExtend) {
            query.isExtend = parms.isExtend  // isExtend 0 非推广贴    1推广帖
        }
        return httpPostWithUUID(url, query, token, uuid)
    },

    // 帖子详情
    topicDetail(parms) {
        const url = '/website/forumWeb/topicDetail'
        let query = {}
        if (parms.topicId) {
            query.topicId = parms.topicId
        }
        if (parms.customerId) {
            query.customerId = parms.customerId
        }
        if (parms.language) {
            query.language = parms.language
        }
        if (parms.pageSize) {
            query.pageSize = parms.pageSize
        }
        if (parms.pageNum) {
            query.pageNum = parms.pageNum
        }
        return httpPost(url, parms)
    },

    // 回帖
    addForumReply(parms, token, uuid) {
        const url = '/website/forumWeb/addForumReply'
        let query = {}
        if (parms.responderId) {
            query.responderId = parms.responderId
        }
        if (parms.belongId) {
            query.belongId = parms.belongId
        }
        if (parms.language) {
            query.language = parms.language
        }
        if (parms.context) {
            query.context = parms.context
        }
        if (parms.topicId) {
            query.topicId = parms.topicId
        }
        if (parms.replyId) {
            query.replyId = parms.replyId
        }

        return httpPostWithUUID(url, parms, token, uuid)
    },

    // 帖子收藏
    addForumCollect(parms, token) {
        const url = '/website/forumWeb/addForumCollect'
        let query = {}
        if (parms.topicId) {
            query.topicId = parms.topicId
        }
        if (parms.customerId) {
            query.customerId = parms.customerId
        }
        return httpPost(url, parms, token)
    },
    // 帖子取消收藏
    cancelForumCollect(parms, token) {
        const url = '/website/forumWeb/cancelForumCollect'
        let query = {}
        if (parms.topicId) {
            query.topicId = parms.topicId
        }
        if (parms.customerId) {
            query.customerId = parms.customerId
        }
        return httpPost(url, parms, token)
    },

    // 修改帖子

    lockTopic(parms, token) {
        let query = {}
        if (parms.id) {
            query.id = parms.id
        }
        if (parms.mainTitle) {
            query.mainTitle = parms.mainTitle
        }
        if (parms.subTitle) {
            query.subTitle = parms.subTitle
        }

        if (parms.categoryId) {
            query.categoryId = parms.categoryId
        }
        if (parms.customerId) {
            query.customerId = parms.customerId
        }

        if (parms.regionId) {
            query.regionId = parms.regionId
        }

        if (parms.childId) {
            query.childId = parms.childId
        }

        if (parms.context) {
            query.context = parms.context
        }
        if (parms.path) {
            query.path = parms.path
        }
        if (parms.childCategory) {
            query.childCategory = parms.childCategory
        }

        if (parms.sellerType) {
            query.sellerType = parms.sellerType
        }
        if (parms.serviceType) {
            query.serviceType = parms.serviceType
        }

        if (parms.industry) {
            query.industry = parms.industry
        }
        if (parms.companyName) {
            query.companyName = parms.companyName
        }

        if (parms.address) {
            query.address = parms.address
        }

        if (parms.price) {
            query.price = parms.price
        }

        if (parms.phone) {
            query.phone = parms.phone
        }
        if (parms.email) {
            query.email = parms.email
        }
        if (parms.wechat) {
            query.wechat = parms.wechat
        }









        if (parms.line) {
            query.line = parms.line
        }
        if (parms.facebook) {
            query.facebook = parms.facebook
        }

        if (parms.qq) {
            query.qq = parms.qq
        }

        if (parms.letOutType) {
            query.letOutType = parms.letOutType
        }

        if (parms.rentMoney) {
            query.rentMoney = parms.rentMoney
        }

        if (parms.checkInTime) {
            query.checkInTime = parms.checkInTime
        }

        if (parms.rentTime) {
            query.rentTime = parms.rentTime
        }
        if (parms.roomType) {
            query.roomType = parms.roomType
        }
        if (parms.roomConfig !== undefined && parms.roomConfig !== null) {
            query.roomConfig = parms.roomConfig
        }




        if (parms.nearConfig !== undefined && parms.nearConfig !== null) {
            query.nearConfig = parms.nearConfig
        }
        if (parms.workType) {
            query.workType = parms.workType
        }

        if (parms.post) {
            query.post = parms.post
        }

        if (parms.salary) {
            query.salary = parms.salary
        }

        if (parms.salaryType) {
            query.salaryType = parms.salaryType
        }

        if (parms.eduBack) {
            query.eduBack = parms.eduBack
        }

        if (parms.expType) {
            query.expType = parms.expType
        }
        if (parms.gender) {
            query.gender = parms.gender
        }
        if (parms.signType) {
            query.signType = parms.signType
        }





        if (parms.productName) {
            query.productName = parms.productName
        }
        if (parms.dealType) {
            query.dealType = parms.dealType
        }

        if (parms.productType) {
            query.productType = parms.productType
        }

        if (parms.newOrOld) {
            query.newOrOld = parms.newOrOld
        }

        if (parms.sendType) {
            query.sendType = parms.sendType
        }

        if (parms.eduBack) {
            query.eduBack = parms.eduBack
        }

        if (parms.expType) {
            query.expType = parms.expType
        }
        if (parms.gender) {
            query.gender = parms.gender
        }
        if (parms.signType) {
            query.signType = parms.signType
        }
        const url = '/website/forumBack/lockTopic'
        return httpPost(url, query, token)
    },

    // 校验验证码
    checkVerify(parms, token) {
        const url = '/website/customer/checkVerify'
        return httpPostByJSON(url, parms, token)
    },

     // 微信分享获取数据
     getSign(parms) {
        const url = '/website/weixin/getSign'
        let query = {}
        if (parms.url) {
            query.url = parms.url
        }
        return httpPost(url, parms)
    },

}


export default forum