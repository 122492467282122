import { USERDATA } from '../constants'

const initialState = {
    user: {
        customer: {
            account: ''
        }
    }
}


const user = (state = initialState, action) => {
    switch(action.type) {
        case USERDATA: 
        return {
          user:action.user  
        };
    default: 
        return state
    }
}

export default user

