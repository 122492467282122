import React from 'react'
import { GlobalOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import * as userActions from '../../actions/user'
import * as navActions from '../../actions/nav'
import * as lang from '../../actions/lang'
import { bindActionCreators } from 'redux';
import { Menu, Dropdown, notification, Avatar } from 'antd';
import intl from 'react-intl-universal';
import Cookies from 'js-cookie'

import './nav.css'


const locales = {
    "en-US": require('../../locales/en-US.json'),
    "zh-CN": require('../../locales/zh-CN.json'),
    "zh-CH": require('../../locales/ZH-CH.json'),
};


class Nav extends React.Component {
    constructor() {
        super()
        this.state = {
            ISLOGIN: false,
            navList: [],
            clickstat: 1,
            userdata: {
                customer: {
                    account: ''
                }
            },
            visible: false,
            cn: 'zh-CN',
            us: 'en-US',
            ch: 'zh-TW',
            intlDone: false,
            languageTitle: intl.get('JIANTIZHONGWEN')
        }
    }


    componentDidMount() {
        console.log('nav=====aaaa', sessionStorage['languageFilter'])
        this.judgeUser()
        this.getLocation()
    }

    componentWillReceiveProps(nextProps) {
        console.log('nav=====navnextProps', nextProps)
        this.judgeUser()
    }

    getLocation = () => {
        console.log('this.propsNav', this.props);

        switch (this.props?.props?.location?.pathname) {
            case '/main':
                sessionStorage['NAVITEM'] = 1

                break;
            case '/':
                sessionStorage['NAVITEM'] = 1
                break;
            case '/yellowpages':
                sessionStorage['NAVITEM'] = 2
                break;
            case '/yellowPageList':
                sessionStorage['NAVITEM'] = 2
                break;
            case '/yellowPagesDetail':
                sessionStorage['NAVITEM'] = 2
                break;
            case '/bbspages':
                sessionStorage['NAVITEM'] = 3
                break;
            case '/bbspagelist':
                sessionStorage['NAVITEM'] = 3
                break;
            case '/bbspagedetail':
                sessionStorage['NAVITEM'] = 3
                break;
            case '/bbspagenew':
                sessionStorage['NAVITEM'] = 3
                break;
            default:
                sessionStorage['NAVITEM'] = ''
                break
        }
    }

    initLocale(val = 'zh-CN') {
        intl.init({
            currentLocale: sessionStorage['languageFilter'] ? sessionStorage['languageFilter'] : val,
            locales
        }).then(() => {
            this.setState({ intlDone: true })
        })

    }
    changeLanguage = (val) => {
        if (val === 'zh-CN') {
            sessionStorage['language'] = 1
            sessionStorage['languageTitle'] = '简体中文'
            sessionStorage['languageFilter'] = val
            this.props.langActions.get_lang(1)
        } else if (val === 'en-US') {
            sessionStorage['language'] = 2
            this.props.langActions.get_lang(2)
            sessionStorage['languageTitle'] = 'English'
            sessionStorage['languageFilter'] = val
        } else if (val === 'zh-CH') {
            sessionStorage['language'] = 3
            this.props.langActions.get_lang(3)
            sessionStorage['languageTitle'] = '繁體中文'
            sessionStorage['languageFilter'] = val
        }
        window.location.reload()
    }



    selectPerson = val => {
        console.log('JSON.parse(sessionStorage.getItem("USERDATA"))', JSON.parse(sessionStorage.getItem("USERDATA")))
        if (!JSON.parse(sessionStorage.getItem("USERDATA"))) {
            notification['warning']({
                message: intl.get('QINGXIANDENGLU'),
                description:
                    intl.get('QINGXIANDENGLU'),
            });
            return false
        }
        console.log('nav ==== val', val)
        if (val === 1) {
            sessionStorage['checkedtap'] = 2
            this.props.history.push({ pathname: '/myplace', query: { checkedtap: 2 } })
        } else if (val === 2) {
            console.log(2222222)
            sessionStorage['checkedtap'] = 3
            this.props.history.push({ pathname: '/myplace', query: { checkedtap: 3 } })
        } else if (val === 3) {
            console.log(3333333)
            this.props.history.push('/myplacepageperson')
        } else {
            sessionStorage['checkedtap'] = 1
            this.props.history.push({ pathname: '/myplace', query: { checkedtap: 1 } })

        }
    }

    hide = () => {
        this.setState({
            visible: false,
        });
    };


    judgeUser = () => {
        console.log('this.props', this.props.user.user)
        let userdata = JSON.parse(sessionStorage.getItem("USERDATA"))

        console.log('userdata', userdata)
        if (!userdata) {
            this.setState({
                ISLOGIN: false
            })
        } else {
            this.setState({
                ISLOGIN: true,
                userdata
            })
        }
    }

    handleChangeNav = (val) => {
        console.log('val', val)
        sessionStorage['NAVITEM'] = val
        sessionStorage['MAINTYPE'] = ''
        sessionStorage['SECONDTYPE'] = ''
        sessionStorage['CITY'] = ''

        this.setState({})
        switch (Number(val)) {
            case 1:
                this.props.history.push('/main')
                break;
            case 2:
                this.props.history.push('/yellowpages')
                break;
            case 3:
                this.props.history.push('/bbspages')
                break;
            default:
                break;

        }
    }

    loginOut = () => {
        sessionStorage.removeItem("USERDATA");
          Cookies.remove('id')
         Cookies.remove('token')
        this.props.history.push('/login')

    }

    handleReginst = () => {
        sessionStorage["ISREGINS"] = 'true'
        this.props.history.push('/login')
    }

    render() {
        return (
            <div className={`${sessionStorage['NAVITEM'] === '1' ? "nav_content" : 'nav_content_white'}`}>
                {/* 语言切换 */}
                <Dropdown overlay={
                    <Menu>
                        <Menu.Item key="0" onClick={this.changeLanguage.bind(this, "zh-CN")}>
                            {intl.get('JIANTIZHONGWEN')}
                        </Menu.Item>
                        <Menu.Item key="1" onClick={this.changeLanguage.bind(this, "en-US")}>
                            {intl.get('ENGLISH')}
                        </Menu.Item>
                        <Menu.Item key="2" onClick={this.changeLanguage.bind(this, "zh-CH")}>
                            {intl.get('FANTIZHONGWEN')}
                        </Menu.Item>

                    </Menu>
                } trigger={['click']}>
                    <div className="nav_content_left" style={{width: '10%'}}>
                        <GlobalOutlined className="languageicon" style={{ color: '#37D0D2', paddingRight: '10px' }} />{sessionStorage['languageTitle']}
                    </div>

                </Dropdown>
                <div onClick={this.changeLanguage.bind(this, "en-US")} className="nav_content_left" style={{width: '10%'}}>
                    EN
                </div>

                {/* 导航栏 */}
                <div className="nav_content_right">

                    <div className="nav_content_right_left">

                    </div>

                    <div className="nav_content_right_content">
                        <div className={`${sessionStorage['NAVITEM'] === '1' ? "nav_content_right_content_item_active" : 'nav_content_right_content_item customer_hover'}`} onClick={this.handleChangeNav.bind(this, 1)}>
                            {intl.get('SHOUYE')}
                        </div>
                        <div className={`${sessionStorage['NAVITEM'] === '2' ? "nav_content_right_content_item_active" : 'nav_content_right_content_item customer_hover'}`} onClick={this.handleChangeNav.bind(this, 2)}>
                            {intl.get('SHANGJIAHUANG')}
                        </div>
                        <div className={`${sessionStorage['NAVITEM'] === '3' ? "nav_content_right_content_item_active" : 'nav_content_right_content_item customer_hover'}`} onClick={this.handleChangeNav.bind(this, 3)}>
                            {intl.get('YISHOULUNT')}
                        </div>


                        <div className={`${this.state.ISLOGIN === true && this.state.userdata !== '' ? 'display nav_content_right_content_item' : 'nodisplay'}`}>
                            <Dropdown overlay={
                                <Menu>
                                    <Menu.Item key="0" onClick={this.selectPerson.bind(this, '')} className="customer_hover">
                                        {intl.get('GERENZHONGXIN')}
                                    </Menu.Item>

                                    <Menu.Item key="1" onClick={this.loginOut} className="customer_hover">
                                        {intl.get('TUICHUDENGLU')}
                                    </Menu.Item>
                                </Menu>
                            } trigger={['hover']}>

                                <div>
                                    <Avatar src={this.state.userdata.customer.path} />
                                </div>
                            </Dropdown>
                        </div>


                        <div className={`${this.state.ISLOGIN === false || this.state.userdata === '' ? 'display nav_content_right_content_item' : 'nodisplay'}`}>
                            <div className="gotoregins customer_hover" onClick={this.handleReginst}>
                                {intl.get('ZHUCE')}
                            </div>
                            <div className="nowlogin">
                                <Link to="/login">
                                    {intl.get('LIJIDENGLU')}
                                </Link>
                            </div>

                        </div>
                    </div>

                </div>


            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        navdata: state.navdata,
        lang: state.lang
    }
}

const mapDispatchProps = (dispatch) => {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        navDataACtions: bindActionCreators(navActions, dispatch),
        langActions: bindActionCreators(lang, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchProps)(Nav)