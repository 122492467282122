import React from 'react'
import './Pagination.css'
import { Pagination } from 'antd';






export default class APagination extends React.Component {
    constructor() {
        super()
        this.state = {
        }
    }
    changepagin = (page, pageSize) => {
        if(!this.props?.weatherTop) {
            window.scrollTo(0, 0);
        }
        this.props.changePage(page, pageSize)
    }


    render() {
        return (
            <div className='pagination'>
                <Pagination defaultCurrent={1} current={this.props.pageNum} total={this.props.total} onChange={this.changepagin} />
            </div>
        )
    }
}


