import React from "react";
import { Modal, Select, message, Input, Button } from "antd";
import intl from 'react-intl-universal';
import ReactWebsocket from '../../pages/myplace/components/ReactWebsocket'
import CHAT from '../../api/chat'
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

const { TextArea } = Input;
const { Option } = Select;
console.log('sessionStorage.getItem("USERDATA"))', sessionStorage.getItem("USERDATA"))


const controls = ['emoji', 'media']
export default class ChatModal extends React.Component {
    constructor() {
        super()
        this.state = {
            modalVisible: false,
            value: undefined,
            liveMessage: BraftEditor.createEditorState(),
            chatData: []
        }

    }


    static getDerivedStateFromProps = (props, state) => {
        if (props.modalVisible !== state.modalVisible) {
            return {
                modalVisible: props.modalVisible,
            };
        }
        return null
    }

    componentDidMount() {
        this.props.pcChildEvevnt(this);
    }

    componentWillUnmount() {
        this.onClose()
    }


    static getDerivedStateFromProps = (props, state) => {
        if (props.modalVisible !== state.modalVisible) {
            return {
                modalVisible: props.modalVisible,
            };
        }
        return null
    }


    sendChild = () => {
        console.log('112212', 'test')
        CHAT.queryFrame({
            memberId: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
            userId: this.props.shopUserData.id
        }, JSON.parse(sessionStorage.getItem("USERDATA"))?.token).then(res => res.json())
            .then(result => {
                console.log('result121212=======>', result)
                if (result.ret === 200 && result.data.content.length !== 0) {
                    this.setState({
                        chatData: result.data.content
                    })
                } else {
                    this.setState({
                        chatData: [{
                            chatRecords: []
                        }]
                    }, () => {
                        var div = document.getElementById('message');
                        div.scrollTop = div.scrollHeight;
                    })
                }
            })
    }

    handleOk = () => {
        this.props.modalsuccess(this.state.value)
    }
    handleCancel = () => {
        this.props.modalCancel()
    }



    handleChange = value => {
        console.log('value', value)
        this.setState({ value });
    };



    // 即时通讯
    onMessage = (e) => {
        console.log('message', e)
        let arr = this.state.chatData
        let objmessage = JSON.parse(e)
        arr[0].chatRecords.push(objmessage)
        console.log('this.state.chatData ===> ', this.state.chatData)
        console.log('objmessage ===> ', objmessage)

        this.setState({
            chatData: arr,
        }, () => {
            var div = document.getElementById('message');
            div.scrollTop = div.scrollHeight;
        })
    }

    onOpen = (e) => {
        console.log('open', e)
        console.log('this.refWebSocket', this.refWebSocket)
    }

    onClose = (e) => {
        console.log('close', e)
    }



    handleChangeMessage = (e) => {
        this.setState({
            liveMessage: e
        })
    }

    handleSendMessage = (e) => {

        let sendMessage = this.state.liveMessage.toHTML()
        sendMessage = sendMessage.replace(/\s/g, '')
        if (sendMessage === '' || sendMessage === '<p></p>') {
            message.warning(intl.get('QINGSHURUNEIRONG'))
            return false

        }
        let willmessage = {
            memberId: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
            memberName: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.account,
            content: this.state.liveMessage.toHTML(),
            shopUserId: this.props.shopUserData.id,
            shopUserName: this.props.shopUserData.account
        }
        // message : {
        //  memberId;  用户id
        //  memberName;用户名
        //  userId; 商家id
        //  userName;商家名
        //  content; 聊天数据
        //  shopUserId (用户发商家需要, shopUserData商家用户id )
        // shopUserName (用户发商家需要, shopUserData商家用户名字 )
        // }
        let arr = this.state.chatData
        let objmessage = {
            content: this.state.liveMessage.toHTML(),
            memberId: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
            memberName: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.account,
            shopUserId: this.props.shopUserData.id,
            shopUserName: this.props.shopUserData.account
        }
        arr[0].chatRecords.push(objmessage)
        this.refWebSocket.refWebSocket.sendMessage(JSON.stringify(willmessage))
        this.setState({
            chatData: arr,
            liveMessage: BraftEditor.createEditorState()
        }, () => {
            var div = document.getElementById('message');
            div.scrollTop = div.scrollHeight;
        })
    }


    chatContent = () => {

        if (JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.isMerchants === 2) {
            return (
                this.state.chatData[0]?.chatRecords?.map((item, index) => {
                    if (item.userId === JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id) {
                        return (
                            <div key={index} style={{ paddingTop: '0.3rem' }}>
                                {
                                    (index / 10 | 0) === index / 10 ? (
                                        <div className="message_time">{item.time}</div>
                                    ) : ''
                                }

                                <div className="people_message_right_message_right">
                                    <div className="people_message_right_message_right_name">{item.userName}：</div>
                                    <div className="people_message_right_message_right_text">
                                        <span className="people_message_right_message_right_text_content" dangerouslySetInnerHTML={{ __html: item.content }}>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} style={{ paddingTop: '0.3rem' }}>
                                <div className="people_message_right_message_left">
                                    <div className="people_message_right_message_left_name">{item.memberName}：</div>
                                    <div className="people_message_right_message_left_text" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                </div>
                            </div>

                        )
                    }
                })

            )
        } else {
            return (
                this.state.chatData[0]?.chatRecords?.map((item, index) => {
                    if (item.memberId === JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id) {
                        return (
                            <div key={index} style={{ paddingTop: '0.3rem' }}>
                                {
                                    (index / 10 | 0) === index / 10 ? (
                                        <div className="message_time">{item.time}</div>
                                    ) : ''
                                }

                                <div className="people_message_right_message_right">
                                    <div className="people_message_right_message_right_name">{item.memberName}：</div>
                                    <div className="people_message_right_message_right_text">
                                        <span className="people_message_right_message_right_text_content" dangerouslySetInnerHTML={{ __html: item.content }}>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} style={{ paddingTop: '0.3rem' }}>
                                <div className="people_message_right_message_left">
                                    <div className="people_message_right_message_left_name">{item.userName}：</div>
                                    <div className="people_message_right_message_left_text" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                </div>
                            </div>

                        )
                    }
                })

            )
        }

    }


    render() {
        const obj = {
            user_id: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
            user_type: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer?.isMerchants === 2 ? 1 : 2, // 1商家， 2普通客户
        }
        let webSocketUrl = 'wss://' + process.env.REACT_APP_WEBSOCKET_API + '/website/websocket?msg=' + encodeURI(JSON.stringify(obj))
        return (
            <Modal title={this.props.chatObject.merchantsName} visible={this.state.modalVisible} onOk={this.handleOk} onCancel={this.handleCancel} width={900} footer={null}>
                <ReactWebsocket
                    url={webSocketUrl}
                    onMessage={this.onMessage} //接受信息的回调
                    onOpen={this.onOpen} //websocket打开
                    onClose={this.onClose} //websocket关闭
                    reconnect={true}
                    debug={true}
                    ref={Websocket => {
                        this.refWebSocket = Websocket;
                    }}
                />
                <div className="chat_people_message_right">
                    <div className="chat_people_message_right_message" id="message">

                        {
                            this.chatContent()
                        }
                    </div>

                    <div className="chat_people_message_right_send_message">
                        {/* <TextArea rows={6} value={this.state.liveMessage} onChange={this.handleChangeMessage} onPressEnter={this.handleSendMessage} /> */}

                        <BraftEditor
                            className="sendMesage_editor"
                            controls={controls}
                            placeholder="請輸入店鋪介紹"
                            value={this.state.liveMessage}
                            onChange={this.handleChangeMessage}
                        />
                        <div className="people_message_right_send_message_btn"> <Button type="primary" onClick={this.handleSendMessage}>{intl.get('FASONG')}</Button></div>
                    </div>
                </div>

            </Modal>
        );
    }
}


