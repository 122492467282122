import intl from "react-intl-universal";
//根据时间戳判断几分钟前，几小时前，几天前，几月前
export function timeFormat(date) {
    let time = new Date(date).getTime();;
    if (time > 0) {
        var result
        time = parseInt(time);
        var minute = 1000 * 60;
        var hour = minute * 60;
        var day = hour * 24;
        var month = day * 30;
        var now = new Date().getTime();
        var diffValue = now - time;
        if (diffValue < 0) {
            return
        }
        var monthC = diffValue / month;
        var weekC = diffValue / (7 * day);
        var dayC = diffValue / day;
        var hourC = diffValue / hour;
        var minC = diffValue / minute;
        if (monthC >= 1) {
            if (monthC <= 12) {
                result = "" + parseInt(monthC) + intl.get("YUEQIAN");
            } else {
                result = "" + parseInt(monthC / 12) + intl.get("NIANQIAN");
            }
        } else if (weekC >= 1) {
            result = "" + parseInt(weekC) + intl.get("ZHOUQIAN");
        } else if (dayC >= 1) {
            result = "" + parseInt(dayC) + intl.get("TIANQIAN");
        } else if (hourC >= 1) {
            result = "" + parseInt(hourC) + intl.get("XIAOSHIQIAN");
        } else if (minC >= 1) {
            result = "" + parseInt(minC) + intl.get("FENZHONGQIAN");
        } else {
            result = intl.get("NOW");
        }
        return result
    } else {
        return '';
    }
}


// 过滤敏感字方法
export function filterText(Text) {
    // 敏感词库
    var sensitiveWordsList = ["狗", "草", "傻比", "尼玛", "FUCK", "fuck"];
    // 过滤后的文字
    var outText = "";
    // 遍历敏感词库，替换为*，并把处理后的文字赋值给outText
    for (var i = 0; i < sensitiveWordsList.length; i++) {
        var pattern = new RegExp(sensitiveWordsList[i], "g");
        if (i === 0) {
            outText = Text.replace(pattern, "*");
        } else {
            outText = outText.replace(pattern, "*");
        }
    };
    return outText;

};


// uuid
const hexList = [];
for (let i = 0; i <= 15; i++) {
    hexList[i] = i.toString(16);
}

export function buildUUID() {
    let uuid = '';
    for (let i = 1; i <= 36; i++) {
        if (i === 9 || i === 14 || i === 19 || i === 24) {
            uuid += '-';
        } else if (i === 15) {
            uuid += 4;
        } else if (i === 20) {
            uuid += hexList[(Math.random() * 4) | 8];
        } else {
            uuid += hexList[(Math.random() * 16) | 0];
        }
    }
    return uuid.replace(/-/g, '');
}


// 函数节流
export function throttle(fn, delay) {
    var timer;
    return function () {
        var _this = this;
        var args = arguments;
        if (timer) {
            return;
        }
        timer = setTimeout(function () {
            fn.apply(_this, args);
            timer = null; // 在delay后执行完fn之后清空timer，此时timer为假，throttle触发可以进入计时器
        }, delay)
    }
}

// 函数防抖
export function debounce(fn, ms) {
	let timerId // 创建一个标记用来存放定时器的返回值
	return function () {
		timerId && clearTimeout(timerId) // 每当用户输入的时候把前一个 setTimeout clear 掉
		// 然后又创建一个新的 setTimeout, 这样就能保证输入字符后的 interval 间隔内如果还有字符输入的话，就不会执行 fn 函数
		timerId = setTimeout(() => { 
			fn.apply(this, arguments)
		}, ms)
	}
}

