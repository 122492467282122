import React from 'react'
import { Steps, Result } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';

import "braft-editor/dist/index.css";
import SpecialForm from '../components/BbsForm/SpecialForm'
import PhoneSpecialForm from '../components/BbsForm/PhoneSpecialForm'


import Nav from '../../../component/nav/nav'
import Banner from '../../../component/Banner/Banner'
import BreadCrumb from '../../../component/BreadCrumb/BreadCrumb'

import Foot from '../../../component/Foot/Foot'


import forum from '../../../api/bbs'


import '../components/bbs.css'
const { Step } = Steps;
export default class bbsPageNew extends React.Component {
    constructor() {
        super()
        this.state = {
            breadArray: [
                {
                    name: intl.get('KUNSHILANYISHOU'),
                    path: '/main',
                    navData: '1'
                },
                {
                    name: intl.get('YISHOULUNT'),
                    path: '/bbspages',
                    navData: '2'
                },
                {
                    name: intl.get('FATIE'),
                    path: '/bbspagenew',
                    navData: '2'
                }
            ],
            current: 0,
            bbsCategories: [],
            time: 4
        }
    }

    componentDidMount() {
        this.getlistData()
        this.judgeEdit()
    }


    componentWillUnmount() {
        sessionStorage.removeItem('BBSEDITDATA')
        clearInterval(this.id)
    }

    judgeEdit = () => {
        if (sessionStorage["BBSEDITDATA"]) {
            this.setState({
                current: 1,
            })
        }
    }

    handleFilter = () => {
        this.props.history.push('/bbspagelist')
    }

    getlistData = () => {
        const parms = {
            parentId: 0,
            language: sessionStorage["language"],
        };
        forum
            .forumCategoryListWeb(parms)
            .then((res) => res.json())
            .then((result) => {
                console.log("result", result);
                if (result.ret === 200) {
                    this.setState({
                        bbsCategories: result.data.sort((ar1, ar2) => {
                            return ar1.id - ar2.id
                        })
                    });
                }
            });
    };

    handleSelectCategory = (e) => {
        sessionStorage['BBSCATEGORYID'] = e.id
        this.setState({
            current: 1
        })
    }

    onChange = (e) => {
        if (sessionStorage["BBSEDITDATA"]) {
            return
        }
        this.setState({
            current: e === 2 ? 0 : e
        })
    }

    handleCountDown = () => {
        if (this.state.time === 0) {
            this.props.history.goBack()
        }
        this.setState({
            time: this.state.time - 1
        })
    }

    handleGetData = (e) => {
        if (e === 'success') {
            this.setState({
                current: 2
            }, () => {
                this.id = setInterval(() => {
                    this.handleCountDown()
                }, 1000);
            })
        }
    }

    switchContent = () => {
        switch (this.state.current) {
            case 0:
                return (
                    <div className="bbs_new_category">
                        {
                            this.state.bbsCategories.map((item) => {
                                return (
                                    <div className={Number(sessionStorage['BBSCATEGORYID']) === item.id ? "bbs_new_category_item_active" : "bbs_new_category_item"} key={item.id} onClick={this.handleSelectCategory.bind(this, item)}>{item.categoryName}</div>
                                )
                            })
                        }
                    </div >
                )
            case 1:
                return (
                    <div className="bbs_new_form bbs_new_content">
                        <SpecialForm history={this.props.history} createPostSuccess={this.handleGetData} />
                    </div>
                )
            case 2:
                return (
                    <div className="bbs_new_category">
                        <Result
                            status="success"
                            title={intl.get('FABUCHENGGONG')}
                            extra={
                                <div style={{ fontSize: '0.12rem', color: "rgba(42, 44, 44, 0.65)" }} onClick={this.handleGotoList}>
                                    {intl.get('FANHUILUNTANSHOUYE')}<RightOutlined style={{ color: '#18b2b0' }} />
                                    <div style={{ paddingTop: '0.1rem', fontSize: '0.1rem' }}> {intl.get('YEMIANHAIYOU')}{this.state.time}{intl.get('MIAOHOUTIAOZ')}</div>
                                </div>
                            }
                        />
                    </div>
                )
            default:
                break
        }
    }

    handleGotoList = () => {
        this.props.history.goBack()
    }

    render() {
        document.title = "EQLD昆士兰易搜论坛BBS";
        document.querySelector('meta[property="og:title"]').setAttribute('content', 'EQLD昆士兰易搜论坛BBS');
        document.querySelector('meta[property="og:description"]').setAttribute('content', '找租房、找生意，找房源，找工作，找二手，选易搜！');
        document.querySelector('meta[itemprop="description"]').setAttribute('content', '找租房、找生意，找房源，找工作，找二手，选易搜！');
        document.querySelector('meta[property="og:type"]').setAttribute('content', 'website');
        document.querySelector('meta[property="og:image"]').setAttribute('content', 'http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png');
        document.querySelector('meta[itemprop="image"]').setAttribute('content', 'http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png');
        document.querySelector('meta[itemprop="name"]').setAttribute('content', 'EQLD昆士兰易搜论坛BBS');
        return (
            <>
                <div className="main_content">
                    <Nav history={this.props.history} props={this.props} />
                    <Banner type="2" history={this.props.history} setBannerSearch={this.handleFilter} />
                    <BreadCrumb history={this.props.history} breadData={this.state.breadArray} style={{ paddingBottom: '0' }} />
                    <div className="bbs_new">
                        <div className="bbs_new_tabs">
                            <Steps
                                type="navigation"
                                size="small"
                                current={this.state.current}
                                onChange={this.onChange}
                                className="site-navigation-steps"
                            >
                                <Step
                                    status="process"
                                    icon={<div className="icon_name">1.{intl.get('XUANZHELEIBIE')}</div>}
                                />
                                <Step
                                    status="wait"
                                    icon={<div className="icon_name">2.{intl.get('TIANXIEXINXI')}</div>}
                                />
                                <Step
                                    status="wait"
                                    icon={<div className="icon_name">3.{intl.get('WANCHENGFABU')}</div>}
                                />
                            </Steps>
                        </div>

                        {

                            this.switchContent()
                        }
                    </div>
                    <Foot />
                </div>

                {/* 移动端==============================================================================================================================================================================================================================> */}



                <div className="phone_content bbs_phone_content">


                    <PhoneSpecialForm history={this.props.history} props={this.props} />

                </div>
            </>
        )
    }
}