import React from 'react'
import './login.css'
import imgUrl from '../../assets/login/logo-02.png'
import bottomicon1 from '../../assets/login/icon1.png'
import bottomicon2 from '../../assets/login/icon2.png'
// import bottomicon3 from '../../assets/login/icon3.png'
import login from '../../api/login'

import { Input, message, Radio, Checkbox, Button } from 'antd';
import { UserOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone, MailOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import * as userActions from '../../actions/user'
import { bindActionCreators } from 'redux';
import { buildUUID } from '../../utils/index'

import intl from 'react-intl-universal';
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, FacebookAuthProvider, getAuth, signInWithPopup, getRedirectResult } from 'firebase/auth'


const firebaseConfig = {
    apiKey: "AIzaSyAzj6zSp0AGd7lsQGqPyAi3_Ju2-vSMqok",
    authDomain: "eqld-76f0d.firebaseapp.com",
    projectId: "eqld-76f0d",
    storageBucket: "eqld-76f0d.appspot.com",
    messagingSenderId: "792447714561",
    appId: "1:792447714561:web:428e81c2fddebd87810700"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
auth.languageCode = 'it';
const provider = new GoogleAuthProvider();
const Facebookprovider = new FacebookAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

class Login extends React.Component {
    constructor() {
        super()
        this.state = {
            userName: '',
            passWord: '',
            confirmPasswd: '',
            checktap: 1,
            email: '',
            passwd: '',
            account: '',
            readoption: false,
            msg: intl.get('HUOQUYANZHENGM'),
            tag: 1,
            restemail: '',
            restcode: '',
            newpass: '',
            confirmnewpass: '',
            servecode: '',
            checkBoxValue: false,
            secondCount: 0,
            msgLoading: false,
            UUID: ''
        }
    }

    componentDidMount() {
        if (sessionStorage['ISREGINS']) {
            this.setState({
                checktap: 2,
            })
        }

        this.setState({
            UUID: buildUUID()
        })
    }

    componentWillUnmount() {
        sessionStorage.removeItem('ISREGINS')
    }

    loginByFaceBook = () => {
        signInWithPopup(auth, Facebookprovider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;

                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                console.log('user12121212', user)
                login.uIdLoginuId({
                    uId: user.uid
                }).then(res => res.json())
                    .then(result => {
                        console.log('result', result)
                        // 已存在账号并且登录
                        if (result.ret === 100) {
                            login.userregister({
                                passwd: '12345678',
                                // account: user.displayName,
                                account: user.uid+user.displayName,
                                email: user.email,
                                isMerchants: 0,
                                uId: user.uid
                            }, '', this.state.UUID).then(res => res.json())
                                .then(result => {
                                    if (result.ret === 200) {
                                        // message.success(intl.get('ZHUCECHENGGG'), 2.5)
                                        this.setState({
                                            // userName: user.displayName,
                                            userName: user.uid+user.displayName,
                                            passWord: '12345678'
                                        })
                                        this.handleLogin()
                                    } else {
                                        message.error(result.msg, 2.5)
                                    }
                                })


                        } else {
                            this.props.userActions.get_user(result.data)
                            const userdata = JSON.stringify(result.data)
                            sessionStorage.setItem("USERDATA", userdata);
                            message.success(intl.get('LOGINSUCCESS'), 2.5)
                            this.props.history.goBack()
                        }
                    })
                // ...
            })
            .catch((error) => {
                console.log('error', error)
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = FacebookAuthProvider.credentialFromError(error);

                // ...
            });
    }

    loginByGoogle = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                console.log('user', user)
                login.uIdLoginuId({
                    uId: user.uid
                }).then(res => res.json())
                    .then(result => {
                        console.log('result', result)
                        // 已存在账号并且登录
                        if (result.ret === 100) {
                            login.userregister({
                                passwd: '12345678',
                                // account: user.displayName,
                                account: user.uid+user.displayName,
                                email: user.email,
                                isMerchants: 0,
                                uId: user.uid
                            }, '', this.state.UUID).then(res => res.json())
                                .then(result => {
                                    if (result.ret === 200) {
                                        // message.success(intl.get('ZHUCECHENGGG'), 2.5)
                                        this.setState({
                                            // userName: user.displayName,
                                            userName: user.uid+user.displayName,
                                            passWord: '12345678'
                                        })
                                        this.handleLogin()
                                    } else {
                                        message.error(result.msg, 2.5)
                                    }
                                })


                        } else {
                            this.props.userActions.get_user(result.data)
                            const userdata = JSON.stringify(result.data)
                            sessionStorage.setItem("USERDATA", userdata);
                            message.success(intl.get('LOGINSUCCESS'), 2.5)
                            this.props.history.goBack()
                        }
                    })
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }
    // 登录事件
    handleLogin = () => {
        console.log(this.state)
        const loginParms = {
            userName: this.state.userName,
            passWord: this.state.passWord
        }
        message.loading(intl.get('LOGINING'), 2.5)
        this.props.userActions.getUserInfo(loginParms)
            .then(res => res.json())
            .then(result => {
                console.log('result', result)
                message.destroy()
                if (result.ret === 200) {
                    if (result.data.customer.isMerchants === 1) {
                        message.warning(intl.get('ZHANGHAOSHENHEZHONG'), 2.5)
                        return
                    }
                    this.props.userActions.get_user(result.data)
                    const userdata = JSON.stringify(result.data)
                    sessionStorage.setItem("USERDATA", userdata);
                    message.success(intl.get('LOGINSUCCESS'), 2.5)
                    this.props.history.goBack()
                } else {
                    message.error(result.msg, 2.5)
                }
            })
    }
    // 绑定用户名数据
    handleChangeUserName = (e) => {
        this.setState({ userName: e.target.value })
    }
    // 绑定密码数据
    handleChangePassword = (e) => {
        this.setState({ passWord: e.target.value })
    }
    // 动态切换tab
    handlechangeTab = () => {
        if (this.state.checktap === 1) {
            this.setState({
                checktap: 2
            })
        } else {
            this.setState({
                checktap: 1
            })
        }
    }

    // 跳转切换
    gotocheck = () => {
        this.setState({
            checktap: 2
        })
    }

    handleChangepersonName = (e) => {
        this.setState({ account: e.target.value })

    }

    handleChangeemaile = (e) => {
        this.setState({ email: e.target.value })

    }

    handleChangepasswd = (e) => {
        this.setState({ passwd: e.target.value })

    }

    handleChangeConfirmPasswd = (e) => {
        this.setState({ confirmPasswd: e.target.value })

    }
    // 注册
    handleRegister = () => {
        console.log('this.state', this.state.UUID)
        console.log('this.state.secondCount', this.state.secondCount)


        if (Number(this.state.restcode) !== Number(this.state.servecode)) {
            message.error(intl.get('YANZHENGMABUZHENGQU'), 2.5)
            return false
        }

        if (this.state.secondCount > 120) {
            message.error(intl.get('YANZHENGMAYISHIXIAO'), 2.5)
            return false
        }

        if (!this.state.confirmPasswd) {
            message.error(intl.get('QINGQUERENMIMA'), 2.5)
            return false
        }


        if (this.state.confirmPasswd !== this.state.passwd) {
            message.error(intl.get('LIANGCISHURUMIMABUYIZHI'), 2.5)
            return false
        }

        if (!this.state.account) {
            message.error(intl.get('ZHANGHUCUO'), 2.5)
            return false
        }

        if (!this.state.passwd) {
            message.error(intl.get('MMWK'), 2.5)
            return false
        }
        if (!this.state.email) {
            message.error(intl.get('YOUXIANGWEIKONG'), 2.5)
            return false
        }
        if (this.state.passwd.length < 6) {
            message.error(intl.get('MMWSXYLIUWEI'), 2.5)
            return false
        }
        if (!this.state.readoption) {
            message.error(intl.get('QINGXIANGOUXUANXI'), 2.5)
            return false
        }

        // eslint-disable-next-line no-useless-escape
        if (!(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.state.email))) {
            message.error(intl.get('YOUXIANGCUOWU'), 2.5)
            return false
        }
        const parms = {
            passwd: this.state.passwd,
            account: this.state.account,
            email: this.state.email,
            isMerchants: this.state.checkBoxValue ? 1 : 0
        }

        login.userregister(parms, '', this.state.UUID).then(res => res.json())
            .then(result => {
                if (result.ret === 200) {
                    message.success(intl.get('ZHUCECHENGGG'), 2.5)
                    this.setState({
                        userName: parms.account,
                        passWord: parms.passwd
                    })
                    if (!this.state.checkBoxValue) {
                        this.handleLogin()
                    } else {
                        message.warning(intl.get('ZHANGHAOSHENHEZHONG'), 2.5)
                    }
                } else {
                    message.error(result.msg, 2.5)
                }
            })


    }

    changeradio = (e) => {
        console.log('e', e)
        this.setState({
            readoption: e.target.checked
        })
    }

    changeCheckBox = (e) => {
        console.log('e', e)
        this.setState({
            checkBoxValue: e.target.checked
        })
    }
    checkemail = (e) => {
        console.log('e', e)
        const parmsObj = {
            email: e.target.value
        }
        login.checkEmail(parmsObj).then(res => res.json())
            .then(result => {
                if (result.data === 1) {
                    message.error(intl.get('CUNZAIXIAONT'), 2.5)
                    this.setState({
                        email: ''
                    })
                }
            })
    }

    checkaccount = (e) => {
        console.log('e', e)
        const parmsObj = {
            account: e.target.value
        }
        login.checkAccount(parmsObj).then(res => res.json())
            .then(result => {
                if (result.data === 1) {
                    message.error(intl.get('CUNZAIXIASO'), 2.5)
                    this.setState({
                        account: ''
                    })
                }
            })
    }

    timeTransition = () => {
        // eslint-disable-next-line no-useless-escape
        if (!(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.state.restemail))) {
            message.error(intl.get('YOUXIANGCUOWU'), 2.5)
            return false
        }
        let sendparsm = {
            email: this.state.restemail
        }
        this.setState({
            msgLoading: true,
            UUID: buildUUID()
        })
        login.sendContent(sendparsm)
            .then(res => res.json())
            .then(result => {
                console.log('result')
                if (result.ret === 200) {
                    this.setState({
                        servecode: result.data,
                    })
                    let maxtime = 120000 / 1000; //按秒计算
                    // if(maxtime !== 0) {
                    //     return false
                    // }
                    let timer = null;
                    let _this = this;

                    setTimeout(function f() {
                        if (maxtime >= 0) {
                            let minutes = Math.floor(maxtime / 60);
                            let seconds = maxtime;
                            // eslint-disable-next-line no-self-assign
                            minutes < 10 ? minutes = '0' + minutes : minutes = minutes;
                            // eslint-disable-next-line no-self-assign
                            seconds < 10 ? seconds = '0' + seconds : seconds = seconds;
                            let msg = seconds + intl.get('MIAOHOUHUOQU');
                            _this.setState({
                                msg,
                                tag: 2
                            });
                            --maxtime;
                        } else {
                            _this.setState({
                                msg: intl.get('HUOQUYANZHENGM'),
                                tag: 1,
                                secondCount: 122,
                                msgLoading: false
                            });
                            clearTimeout(timer);
                            return;
                        }
                        timer = setTimeout(f, 1000);
                    }, 1000);
                }
            })



    }


    // 注册邮箱验证
    resigntimeTransition = () => {
        // eslint-disable-next-line no-useless-escape
        if (!(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.state.email))) {
            message.error(intl.get('YOUXIANGCUOWU'), 2.5)
            return false
        }
        let sendparsm = {
            email: this.state.email
        }

        this.setState({
            msgLoading: true,
            UUID: buildUUID()
        })

        login.sendContent(sendparsm)
            .then(res => res.json())
            .then(result => {
                console.log('result')
                if (result.ret === 200) {
                    this.setState({
                        servecode: result.data,

                    })
                    let maxtime = 120000 / 1000; //按秒计算
                    // if(maxtime !== 0) {
                    //     return false
                    // }
                    let timer = null;
                    let _this = this;

                    setTimeout(function f() {
                        if (maxtime >= 0) {
                            let minutes = Math.floor(maxtime / 60);
                            let seconds = maxtime;
                            // eslint-disable-next-line no-self-assign
                            minutes < 10 ? minutes = '0' + minutes : minutes = minutes;
                            // eslint-disable-next-line no-self-assign
                            seconds < 10 ? seconds = '0' + seconds : seconds = seconds;
                            let msg = seconds + intl.get('MIAOHOUHUOQU');
                            _this.setState({
                                msg,
                                tag: 2
                            });
                            --maxtime;
                        } else {
                            _this.setState({
                                msg: intl.get('HUOQUYANZHENGM'),
                                tag: 1,
                                secondCount: 122,
                                msgLoading: false
                            });
                            clearTimeout(timer);
                            return;
                        }
                        timer = setTimeout(f, 1000);
                    }, 1000);
                }
            })



    }

    handleChangerestemail = (e) => {
        console.log()
        this.setState({
            restemail: e.target.value
        })
    }

    handleChangerestcode = (e) => {
        this.setState({
            restcode: e.target.value
        })
    }


    handleChangenewpass = (e) => {
        this.setState({
            newpass: e.target.value
        })
    }
    handleChangeconfirmnewpass = (e) => {
        this.setState({
            confirmnewpass: e.target.value
        })
    }


    handleRest = (e) => {
        // eslint-disable-next-line no-useless-escape
        if (!(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.state.restemail))) {
            message.error(intl.get('YOUXIANGCUOWU'), 2.5)
            return false
        }

        console.log('this.state.restcode', this.state.restcode, 'this.state.servecode', this.state.servecode)

        if (this.state.secondCount > 120) {
            message.error(intl.get('YANZHENGMAYISHIXIAO'), 2.5)
            return false
        }

        if (Number(this.state.restcode) !== Number(this.state.servecode)) {
            message.error(intl.get('YANZHENGMABUZHENGQU'), 2.5)
            return false
        }
        if (this.state.newpass !== this.state.confirmnewpass) {
            message.error(intl.get('LIANGCISHURUMIMABUZHENQUE'), 2.5)
            return false
        }

        let restparsm = {
            email: this.state.restemail,
            newPassWord: this.state.confirmnewpass
        }

        login.updatePasswd(restparsm).then(res => res.json())
            .then(result => {
                console.log('result', result)
                if (result.ret === 200) {
                    message.success(intl.get('XIUGAICHENGGG'), 2.5)
                    this.setState({
                        checktap: 1
                    })
                } else {
                    message.error(result.msg, 2.5)
                }
            })


    }

    handlegotoforget = () => {
        this.setState({
            checktap: 3
        })
    }


    handleback = () => {
        this.setState({
            checktap: 1
        })
    }

    handlegotomain = () => {
        this.props.history.replace('/')
    }





    render() {
        document.title = "EQLD昆士兰易搜-专注服务昆州本地社区";
        document.querySelector('meta[property="og:title"]').setAttribute('content', 'EQLD昆士兰易搜');
        document.querySelector('meta[property="og:description"]').setAttribute('content', '专注服务昆州本地社区');
        document.querySelector('meta[itemprop="description"]').setAttribute('content', '专注服务昆州本地社区');
        document.querySelector('meta[property="og:type"]').setAttribute('content', 'website');
        document.querySelector('meta[property="og:image"]').setAttribute('content', 'http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png');
        document.querySelector('meta[itemprop="image"]').setAttribute('content', 'http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png');
        document.querySelector('meta[itemprop="name"]').setAttribute('content', '专注服务昆州本地社区');

        const { userName, passWord, checktap, email, account, passwd, readoption, confirmPasswd } = this.state
        return (
            <div className="content">
                <div className="content_Logo" onClick={this.handlegotomain}>
                    <img src={imgUrl} className="logo_detail" alt="logo"></img>
                </div>
                <div className="content_login_box">
                    <div className="login_box_content">
                        <div className={`${checktap === 3 ? 'nodisplay' : 'box_title'}`}>
                            <div className={`${checktap === 1 ? 'box_title_item_active' : 'box_title_item'}`} onClick={this.handlechangeTab}>{intl.get('DENGLU')}</div>
                            <div className={`${checktap === 2 ? 'box_title_item_active' : 'box_title_item'}`} onClick={this.handlechangeTab}>{intl.get('ZHUCE')}</div>
                        </div>

                        <div className={`${checktap === 3 ? 'box_title' : 'nodisplay'}`} >
                            <div className='box_title_item_active' >{intl.get('WANGJIMIM')}</div>
                        </div>

                        <div className={`${checktap === 1 ? 'display' : 'nodisplay'}`} style={{ width: '100%', paddingTop: '5%' }}>
                            <div className="input_box">
                                <Input placeholder={intl.get('YONGHUMING')} value={userName} onChange={this.handleChangeUserName} className="input_box_username" prefix={<UserOutlined />} />
                            </div>
                            <div className="input_box">
                                <Input.Password placeholder={intl.get('ZHANGHAOMIMA')} value={passWord} onChange={this.handleChangePassword} iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} className="input_box_username" prefix={<LockOutlined />} />
                            </div>
                            <div className="forget_pass" onClick={this.handlegotoforget}>
                                {intl.get('WANGJIMIMA')}
                            </div>
                            <div className="login_button_box">
                                <div className="box_login_button" onClick={this.handleLogin}> {intl.get('DNEGLU2')}</div>

                            </div>
                            <div className="forget_pass_left" onClick={this.gotocheck}>
                                {intl.get('HAIMEIYOUZHANGHAO')}
                            </div>
                            <div className="bottom_content">
                                <div className="left_lines"></div>
                                <div className="other_type">{intl.get('QITASHAGNSHIDENGL')}</div>
                                <div className="left_lines"></div>

                            </div>

                            <div className="bottom_icons">
                                <div className="bottom_icons_item" onClick={this.loginByFaceBook}>
                                    <img src={bottomicon1} alt="icons" />
                                </div>
                                <div className="bottom_icons_item" onClick={this.loginByGoogle}>
                                    <img src={bottomicon2} alt="icons" />

                                </div>

                            </div>
                        </div>

                        <div className={`${checktap === 2 ? 'display' : 'nodisplay'}`} style={{ width: '100%' }}>
                            <div className="input_box">
                                <Input placeholder={intl.get('ZHANGHAO')} value={account} onChange={this.handleChangepersonName} onBlur={this.checkaccount} className="input_box_username" prefix={<UserOutlined />} />
                            </div>
                            <div className="input_box">
                                <Input placeholder={intl.get('YOUXIANG')} value={email} onChange={this.handleChangeemaile} onBlur={this.checkemail} className="input_box_username" prefix={<MailOutlined />} />
                            </div>
                            <div className="input_box get_code">
                                <Input placeholder={intl.get('QINGSHURUYANZM')} value={this.state.restcode} onChange={this.handleChangerestcode} onBlur={this.checkaccount} className="input_box_username_get_code" />
                                <div className={`${this.state.tag === 1 ? 'display get_code_tag' : 'nodisplay'}`} >
                                    <Button style={{ border: 'none', boxShadow: 'none' }} onClick={this.resigntimeTransition} loading={this.state.msgLoading}>{this.state.msg}</Button>
                                </div>
                                <div className={`${this.state.tag === 2 ? 'display get_code_tag' : 'nodisplay'}`}  >{this.state.msg}</div>
                            </div>
                            <div className="input_box">
                                <Input.Password placeholder={intl.get('MIMA')} value={confirmPasswd} onChange={this.handleChangeConfirmPasswd} iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} className="input_box_username" prefix={<LockOutlined />} />
                            </div>

                            <div className="input_box">
                                <Input.Password placeholder={intl.get('MIMA')} value={passwd} onChange={this.handleChangepasswd} iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} className="input_box_username" prefix={<LockOutlined />} />
                            </div>

                            <div className="login_button_box">
                                <div className="box_login_button" onClick={this.handleRegister}>{intl.get('ZHUCE')}</div>

                            </div>
                            <div className="bottom_radio">
                                <Radio value={readoption} onChange={this.changeradio}>{intl.get('YUDUBINGTONGXIYIX')}</Radio>
                            </div>

                            <div className="bottom_radio">
                                <span className="text_type">{intl.get('YONGHUYISNS')}</span>
                                <span className="text_type">、{intl.get('YONGHUYISNDENGC')}</span>
                            </div>
                            <div className="bottom_radio">
                                <Checkbox onChange={this.changeCheckBox}>{intl.get('SHIFOUWEISHANGJIAZHANGHAO')}</Checkbox>
                            </div>
                            <div className="haveaccoutgotoparentlogin" onClick={this.handleback}>
                                <span className="haveaccoutgotologin">已有账号，我要登录</span>
                            </div>

                        </div>


                        <div className={`${checktap === 3 ? 'display' : 'nodisplay'}`} style={{ width: '100%', paddingTop: '5%' }}>
                            <div className="input_box">
                                <Input placeholder={intl.get('QINGSHURUZUCEDEYOUXIANG')} value={this.state.restemail} onChange={this.handleChangerestemail} className="input_box_username" />
                            </div>
                            <div className="input_box get_code">
                                <Input placeholder={intl.get('QINGSHURUYANZM')} value={this.state.restcode} onChange={this.handleChangerestcode} onBlur={this.checkaccount} className="input_box_username_get_code" />
                                <div className={`${this.state.tag === 1 ? 'display get_code_tag' : 'nodisplay'}`} onClick={this.timeTransition}>

                                    {this.state.msg}</div>
                                <div className={`${this.state.tag === 2 ? 'display get_code_tag' : 'nodisplay'}`}  >{this.state.msg}</div>

                            </div>
                            <div className="input_box">
                                <Input.Password placeholder={intl.get('XINMIMA')} value={this.state.newpass} onChange={this.handleChangenewpass} className="input_box_username" />
                            </div>
                            <div className="input_box">
                                <Input.Password placeholder={intl.get('QUERENXINMIM')} value={this.state.confirmnewpass} onChange={this.handleChangeconfirmnewpass} className="input_box_username" />
                            </div>

                            <div className="login_button_box">
                                <div className="box_login_button" onClick={this.handleRest}>{intl.get('TIJIAO')}</div>

                            </div>

                            <div className="login_button_box">
                                <div className="box_login_button" onClick={this.handleback}>{intl.get('FANHUI')}</div>

                            </div>


                        </div>



                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchProps = (dispatch) => {
    return {
        userActions: bindActionCreators(userActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchProps)(Login)