import React from 'react'
import Nav from '../../component/nav/nav'
import Banner from '../../component/Banner/Banner'
import BreadCrumb from '../../component/BreadCrumb/BreadCrumb'
import { LeftOutlined } from '@ant-design/icons';

import Foot from '../../component/Foot/Foot'
import intl from 'react-intl-universal';
import connect from '../../api/connnect'

import './components/merchantsettled.css'


export default class Merchantsettled extends React.Component {
    constructor() {
        super()
        this.state = {
            breadArray: [
                {
                    name: intl.get('KUNSHILANYISHOU'),
                    path: '/main',
                    navData: '1'
                },
                {
                    name: intl.get('SHANGJAIRUZHU'),
                    path: '/merchantsettled',
                    navData: '2'
                }
            ],
        }
    }
    handleFilter = () => {
        this.props.history.push('/yellowPageList')
    }
    componentDidMount() {
        this.getpagedata()
    }
    getpagedata = () => {
        const parms = {
            columnId: 5,
            pageSize: 10,
            language: sessionStorage['language'],
            pageNum: 1
        }
        connect.webBodyForWeb(parms)
            .then(res => res.json())
            .then(result => {
                console.log('result', result)
                if (result.ret === 200 && result.data.list.length !== 0) {
                    this.setState({
                        context: result.data.list[0].context,
                        context2: result.data.list[0].context2,
                        context3: result.data.list[0].context3,
                        title: result.data.list[0].mainTitle
                    })
                }
            })
    }

    filterContent = () => {
        switch (sessionStorage['language']) {
            case '1':
                return (
                    <div className="why_choose_content" dangerouslySetInnerHTML={{ __html: this.state.context }}></div>
                )
            case '2':
                return (
                    <div className="why_choose_content" dangerouslySetInnerHTML={{ __html: this.state.context2 }}></div>
                )
            case '3':
                return (
                    <div className="why_choose_content" dangerouslySetInnerHTML={{ __html: this.state.context3 }}></div>
                )
            default:
                break
        }
    }

    handleBack = () => {
        this.props.history.goBack()
    }
    render() {
        document.title = "EQLD昆士兰易搜-专注服务昆州本地社区";
        document.querySelector('meta[property="og:title"]').setAttribute('content', 'EQLD昆士兰易搜');
        document.querySelector('meta[property="og:description"]').setAttribute('content', '专注服务昆州本地社区');
        document.querySelector('meta[itemprop="description"]').setAttribute('content', '专注服务昆州本地社区');
        document.querySelector('meta[property="og:type"]').setAttribute('content', 'website');
        document.querySelector('meta[property="og:image"]').setAttribute('content', 'http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png');
        document.querySelector('meta[itemprop="image"]').setAttribute('content', 'http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png');
        document.querySelector('meta[itemprop="name"]').setAttribute('content', '专注服务昆州本地社区');

        return (
            <>
                <div className="mainmer_content">
                    <Nav history={this.props.history} props={this.props} />
                    <Banner type="1" history={this.props.history} setBannerSearch={this.handleFilter} />
                    <BreadCrumb history={this.props.history} breadData={this.state.breadArray} />
                    <div className="middle_content">
                        <div className="why_choose">
                            <div className="why_choose_title">{intl.get('WEISHENMEYAU')}</div>
                        </div>
                        {
                            this.filterContent()
                        }
                    </div>

                    {/* <div className="middle_tow_content">
                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title"> {intl.get('ZIZHIXIAONG')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS')}
                            </div>

                        </div>


                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title">{intl.get('NEIRONGQUANMIAN')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS1')}
                            </div>

                        </div>



                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title">{intl.get('MEITIZHICHI')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS2')}
                            </div>

                        </div>



                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title">{intl.get('TUIGUANGCEHUA')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS3')}
                            </div>

                        </div>



                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title">{intl.get('LIULIANGRUKOU')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS4')}
                            </div>

                        </div>



                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title">{intl.get('HOUTAIGUANLI')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS5')}
                            </div>

                        </div>
                    </div> */}

                    {/* <div className="learn_more">
                        <div className="learn_more_btn">{intl.get('LIAOJIEGENGDUO')}</div>
                    </div> */}

                    <div className="middle_content_bottom">
                        <div className="why_choose">
                            <div className="why_choose_title">{intl.get('RUHEJIARUWOMEN')}</div>
                        </div>
                        <div className="why_choose_content">
                            {intl.get('TAGS6')}
                        </div>
                    </div>
                    <Foot history={this.props.history} />
                </div>

                {/* 移动端==============================================================================================================================================================================================================================> */}

                <div className="phone_content bbs_phone_content">
                    <div className="merch_top">
                        <div className="phone_person_top_first">
                            <LeftOutlined style={{ color: "#000", fontSize: '15px' }} onClick={this.handleBack} />
                        </div>
                    </div>
                    <div className="middle_content">
                        <div className="why_choose">
                            <div className="why_choose_title">{intl.get('WEISHENMEYAU')}</div>
                        </div>
                        {
                            this.filterContent()
                        }
                    </div>

                    {/* <div className="middle_tow_content">
                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title"> {intl.get('ZIZHIXIAONG')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS')}
                            </div>

                        </div>


                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title">{intl.get('NEIRONGQUANMIAN')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS1')}
                            </div>

                        </div>



                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title">{intl.get('MEITIZHICHI')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS2')}
                            </div>

                        </div>



                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title">{intl.get('TUIGUANGCEHUA')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS3')}
                            </div>

                        </div>



                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title">{intl.get('LIULIANGRUKOU')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS4')}
                            </div>

                        </div>



                        <div className="middle_tow_content_item">
                            <div className="middle_tow_content_item_img"></div>
                            <div className="middle_tow_content_item_title">{intl.get('HOUTAIGUANLI')}</div>
                            <div className="middle_tow_content_item_text">
                                {intl.get('TAGS5')}
                            </div>

                        </div>
                    </div> */}


                    <div className="middle_content">
                        <div className="why_choose">
                            <div className="why_choose_title">{intl.get('RUHEJIARUWOMEN')}</div>
                        </div>
                        <div className="why_choose_content">
                            {intl.get('TAGS6')}
                        </div>
                    </div>

                </div>
            </>
        )
    }


}