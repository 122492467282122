import React from 'react'
import './breadcrumb.css'
import { Breadcrumb } from 'antd';
import intl from 'react-intl-universal';


export default class BreadCrumb extends React.Component {
    constructor() {
        super()
        this.state = {
            serachResult: ''
        }
    }

    routerTo = (e) => {
        console.log('e', e)
        this.props.history.push(e.path)
        // sessionStorage['NAVITEM'] = e.navData
    }


    render() {
        return (
            <div className="bread_crumb">
                <Breadcrumb separator=">">
                    {
                        this.props.breadData.map((item, index) => {
                            return (
                                <Breadcrumb.Item key={index} onClick={this.routerTo.bind(this, item)}>
                                    {item.name}
                                </Breadcrumb.Item>
                            )
                        })
                    }
                </Breadcrumb>
            </div>
        )
    }
}


