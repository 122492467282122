import { httpPost } from '../utils/request'


const person = {
    // 店铺收藏
    myCollectList(parms, token) {
        let query = {}
        if (parms.customerId) {
            query.customerId = parms.customerId
        }
        if (parms.categoryId) {
            query.categoryId = parms.categoryId
        }
        if (parms.pageSize) {
            query.pageSize = parms.pageSize
        }
        if (parms.pageNum) {
            query.pageNum = parms.pageNum
        }
        
        const url = '/website/merchantsInfo/myCollectList'
        return httpPost(url, parms, token)
    },
    // 帖子收藏
    myForumwebCollectList(parms, token) {
        let query = {}
        if (parms.customerId) {
            query.customerId = parms.customerId
        }
        if (parms.pageSize) {
            query.pageSize = parms.pageSize
        }
        if (parms.pageNum) {
            query.pageNum = parms.pageNum
        }
        const url = '/website/forumWeb/myCollectList'
        return httpPost(url, parms, token)
    },
    // 用户信息
    customerDetail(parms, token) {
        let query = {}
        if (parms.customerId) {
            query.customerId = parms.customerId
        }
        const url = '/website/customer/customerDetail'
        return httpPost(url, parms, token)
    },

    // 删除帖子
    deleteForumTopic(parms, token) {
        const url = '/website/forumBack/deleteForumTopic'
        return httpPost(url, parms, token)
    },

    // 用户修改
    updaeCus(parms, token) {
        let query = {}
        if (parms.id) {
            query.id = parms.id
        }
        if (parms.account) {
            query.account = parms.account
        }

        if (parms.passwd) {
            query.passwd = parms.passwd
        }

        if (parms.personName) {
            query.personName = parms.personName
        }

        if (parms.phone) {
            query.phone = parms.phone
        }

        if (parms.email) {
            query.email = parms.email
        }

        if (parms.wechat) {
            query.wechat = parms.wechat
        }

        if (parms.path) {
            query.path = parms.path
        }
        const url = '/website/customer/updaeCus'
        return httpPost(url, parms, token)
    },

    // 用戶列表
    customerList(parms, token) {
        let query = {}
        if (parms.account) {
            query.account = parms.account
        }
        if (parms.phone) {
            query.phone = parms.phone
        }
        if (parms.email) {
            query.email = parms.email
        }
        if (parms.personName) {
            query.personName = parms.personName
        }

        query.pageSize = 10

        query.pageNum = 1

        const url = '/website/customer/customerList'
        return httpPost(url, parms, token)
    },


}


export default person