import { httpPost, httpPostWithUUID } from '../utils/request'


const login = {
    // 登录
    userlogin(parms) {
        const url = '/website/customer/login'
        return httpPost(url, parms)
    },
    // 注册
    userregister(parms, token, uuid) {
        const url = '/website/customer/register'
        return httpPostWithUUID(url, parms, token, uuid)
    },
    // 邮箱验证
    checkEmail(parms) {
        const url = '/website/customer/checkEmail'
        return httpPost(url, parms)
    },
    // 账号验证
    checkAccount(parms) {
        const url = '/website/customer/checkAccount'
        return httpPost(url, parms)
    },
    // 邮箱发送
    sendContent(parms) {
        const url = '/website/customer/sendEmail'
        return httpPost(url, parms)
    },
    // 修改密码
    updatePasswd(parms) {
        const url = '/website/customer/updatePasswd'
        return httpPost(url, parms)
    },

    // uid登录
    uIdLoginuId(parms) {
        const url = '/website/customer/uIdLogin'
        return httpPost(url, parms)
    },


}


export default login