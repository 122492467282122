import React from "react";
import { notification, Anchor, Modal, Select, Upload, message, Input, Radio, Button, Checkbox, Steps } from "antd";
import { Form, } from 'antd-mobile'

import { PlusOutlined } from '@ant-design/icons';
import forum from "../../../../api/bbs";
import person from "../../../../api/user";

import intl from "react-intl-universal";
// import BraftEditor from "braft-editor";
// import "braft-editor/dist/index.css";
import { filterText, buildUUID } from "../../../../utils";
import { secondHandFormType } from './secondHandData';
import { businessTransferType } from './businessTransfer';
import { homeRentType } from './homeRent';
import { jobRecruitmentType } from './jobRecruitment';
import { nomalType } from './nomalData';

const { Option } = Select;
// eslint-disable-next-line no-unused-vars
const { Step } = Steps;
// eslint-disable-next-line no-unused-vars
const { Link } = Anchor;
const { TextArea } = Input;
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export default class PhoneSpecialForm extends React.Component {
    constructor() {
        super();
        this.state = {
            formTypeData: [],
            selectstat: "",
            bbslist: [],
            childlistdata: [],
            mainTitle: "",
            childCategory: "",
            regionId: "",
            subTitle: "",
            // 上传组件
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileList: [],
            mainpathparms: {
                type: 6
            },
            headers: {
                'X-Requested-With': null,
                // authorization: 'authorization-text',
                token: this.props?.user?.user?.token,
            },
            activeCurrent: 0,
            loadings: false,
            UUID: ''

        };
    }

    formRef = React.createRef();
    componentDidMount() {
        this.getlistData();
        this.initCategory()
        this.setState({
            UUID: buildUUID()
        })
    }

    initCategory = () => {
        let bbsCategoryId = null
        let arr = []
        let mainarr = []

        if (sessionStorage["BBSEDITDATA"]) {
            console.log('JSON.parse(sessionStorage["BBSEDITDATA"])', JSON.parse(sessionStorage["BBSEDITDATA"]))
            this.formRef.current.setFieldsValue(JSON.parse(sessionStorage["BBSEDITDATA"]))
            this.formRef.current.setFieldsValue({
                roomType: JSON.parse(sessionStorage["BBSEDITDATA"]).roomType?.split(','),
            })
            bbsCategoryId = JSON.parse(sessionStorage["BBSEDITDATA"])?.categoryId

            if (JSON.parse(sessionStorage["BBSEDITDATA"])?.path) {
                arr = JSON.parse(sessionStorage["BBSEDITDATA"])?.path?.split(",")
            }
            if (JSON.parse(sessionStorage["BBSEDITDATA"])?.roomType) {
                const roomType = JSON.parse(sessionStorage["BBSEDITDATA"])?.roomType?.split(",").map(item => {
                    return Number(item)
                })
                this.formRef.current.setFieldsValue({
                    roomType,
                })
            }


            if (JSON.parse(sessionStorage["BBSEDITDATA"])?.productType) {
                const productType = JSON.parse(sessionStorage["BBSEDITDATA"])?.productType?.split(",").map(item => {
                    return Number(item)
                })
                this.formRef.current.setFieldsValue({
                    productType,
                })
            }


            if (JSON.parse(sessionStorage["BBSEDITDATA"])?.serviceType) {
                const serviceType = JSON.parse(sessionStorage["BBSEDITDATA"])?.serviceType?.split(",").map(item => {
                    return Number(item)
                })
                this.formRef.current.setFieldsValue({
                    serviceType,
                })
            }


            if (JSON.parse(sessionStorage["BBSEDITDATA"])?.industry) {
                const industry = JSON.parse(sessionStorage["BBSEDITDATA"])?.industry?.split(",").map(item => {
                    return Number(item)
                })
                this.formRef.current.setFieldsValue({
                    industry,
                })
            }


            if (JSON.parse(sessionStorage["BBSEDITDATA"])?.eduBack) {
                const eduBack = JSON.parse(sessionStorage["BBSEDITDATA"])?.eduBack?.split(",").map(item => {
                    return Number(item)
                })
                this.formRef.current.setFieldsValue({
                    eduBack,
                })
            }

            if (JSON.parse(sessionStorage["BBSEDITDATA"])?.signType) {
                const signType = JSON.parse(sessionStorage["BBSEDITDATA"])?.signType?.split(",").map(item => {
                    return Number(item)
                })
                this.formRef.current.setFieldsValue({
                    signType,
                })
            }

            if (JSON.parse(sessionStorage["BBSEDITDATA"])?.roomConfig) {
                const roomConfig = JSON.parse(sessionStorage["BBSEDITDATA"])?.roomConfig?.split(",").map(item => {
                    return Number(item)
                })
                this.formRef.current.setFieldsValue({
                    roomConfig,
                })
            }

            if (JSON.parse(sessionStorage["BBSEDITDATA"])?.nearConfig) {
                const nearConfig = JSON.parse(sessionStorage["BBSEDITDATA"])?.nearConfig?.split(",").map(item => {
                    return Number(item)
                })
                this.formRef.current.setFieldsValue({
                    nearConfig,
                })
            }


            if (arr.length > 0) {
                mainarr = arr.map((item, index) => {
                    return {
                        uid: index,
                        url: item
                    }
                })
            }



        } else {
            console.log('12121', Number(sessionStorage['BBSCATEGORYID']))
            bbsCategoryId = isNaN(Number(sessionStorage['BBSCATEGORYID'])) ? 11 : Number(sessionStorage['BBSCATEGORYID'])
        }
        switch (bbsCategoryId) {
            case 11:
                this.setState(
                    {
                        formTypeData: secondHandFormType(),
                    })
                break;
            case 9:
                this.setState(
                    {
                        formTypeData: businessTransferType(),
                    })
                break;
            case 7:
                this.setState(
                    {
                        formTypeData: homeRentType(),
                    })
                break;
            case 6:
                this.setState(
                    {
                        formTypeData: jobRecruitmentType(),
                    })
                break;
            default:
                this.setState(
                    {
                        formTypeData: nomalType(),
                    })
        }
        this.setState(
            {
                selectstat: bbsCategoryId,
                fileList: mainarr
            },
            () => {
                this.getselectdata();
            }
        );
    }

    getselectdata = () => {
        const parms = {
            parentId: this.state.selectstat,
            language: sessionStorage["language"],
        };
        forum
            .forumCategoryListWeb(parms)
            .then((res) => res.json())
            .then((result) => {
                console.log("result", result);
                if (result.ret === 200) {
                    this.setState({
                        childlistdata: result.data,
                    });
                }
            });
    };

    getlistData = () => {
        const parms = {
            parentId: 0,
            language: sessionStorage["language"],
        };
        forum
            .forumCategoryListWeb(parms)
            .then((res) => res.json())
            .then((result) => {
                console.log("result", result);
                if (result.ret === 200) {
                    this.setState({
                        bbslist: result.data.map((item) => {
                            return {
                                title: item.categoryName.split("/")[0],
                                subtitle: item.categoryName.split("/")[1],
                                id: item.id,
                            };
                        }),
                    });
                }
            });
    };

    handleEditorChange = (context) => {
        this.setState({ context });
    };

    onFinish = async (values) => {


        if (!JSON.parse(sessionStorage.getItem("USERDATA"))) {
            notification["warning"]({
                message: intl.get("QINGXIANDENGLU"),
                description: intl.get("QINGXIANDENGLU"),
            });
            return false;
        }

        this.setState({
            loadings: true
        })
        const parms = {
            customerId: JSON.parse(sessionStorage.getItem("USERDATA")).customer.id,
        };

        const persondata = await person
            .customerDetail(
                parms,
                JSON.parse(sessionStorage.getItem("USERDATA")).token
            )
            .then((res) => res.json())
            .then((result) => {
                return result;
            });

        console.log("persondata", persondata);

        if (persondata.data.canPost === 1) {
            message.warning(intl.get("NIYIJINGBEIXIANZHIFATIE"))
            return false;
        }

        let picpath = []
        if (this.state.fileList.length > 0 && this.state.fileList[0] !== '') {
            picpath = this.state.fileList.map(item => {
                if (item.response) {
                    return item.response.data.content.picPath[0]
                } else {
                    return item.url
                }
            })
        }
        console.log('picpath', picpath)
        console.log("values", values);
        let bbsParms = values
        if (sessionStorage["BBSEDITDATA"]) {
            bbsParms.categoryId = JSON.parse(sessionStorage["BBSEDITDATA"])?.categoryId
        } else {
            bbsParms.categoryId = Number(sessionStorage['BBSCATEGORYID'])
        }
        bbsParms.customerId = JSON.parse(sessionStorage.getItem("USERDATA")).customer.id
        bbsParms.context = values.context ? filterText(values.context) : ''

        if (typeof (values?.productType) === 'string') {
            bbsParms.productType = values?.productType ? values?.productType : ''
        } else {
            bbsParms.productType = values?.productType ? values.productType.join() : null
        }

        if (bbsParms.categoryId === 11) {
            bbsParms.mainTitle = values.productName ? values.productName : ''
        }

        if (Number(JSON.parse(sessionStorage.getItem("USERDATA")).customer.id) === 2) {
            bbsParms.isExtend = 1
        } else {
            bbsParms.isExtend = 0
        }


        bbsParms.mainTitle = values.mainTitle ? values.mainTitle : ''
        bbsParms.workType = values.workType ? values.workType.join() : ''
        bbsParms.subTitle = values.subTitle ? values.subTitle : ''
        bbsParms.productName = values.productName ? values.productName : ''

        if (typeof (values?.industry) === 'string') {
            bbsParms.industry = values?.industry ? values?.industry : ''
        } else {
            bbsParms.industry = values?.industry ? values.industry.join() : ''
        }
        if (typeof (values?.serviceType) === 'string') {
            bbsParms.serviceType = values?.serviceType ? values?.serviceType : ''
        } else {
            bbsParms.serviceType = values?.serviceType ? values.serviceType.join() : ''
        }


        if (typeof (values?.roomType) === 'string') {
            bbsParms.roomType = values?.roomType ? values?.roomType : ''
        } else {
            bbsParms.roomType = values?.roomType ? values?.roomType?.join(",") : ''
        }

        if (typeof (values?.roomConfig) === 'string') {
            bbsParms.roomConfig = values?.roomConfig ? values?.roomConfig : ''
        } else {
            bbsParms.roomConfig = values?.roomConfig ? values.roomConfig.join() : ''
        }
        if (typeof (values?.nearConfig) === 'string') {
            bbsParms.nearConfig = values?.nearConfig ? values?.nearConfig : ''
        } else {
            bbsParms.nearConfig = values?.nearConfig ? values.nearConfig.join() : ''
        }


        if (typeof (values?.eduBack) === 'string') {
            bbsParms.eduBack = values?.eduBack ? values?.eduBack : ''
        } else {
            bbsParms.eduBack = values?.eduBack ? values.eduBack.join() : ''
        }

        if (typeof (values?.signType) === 'string') {
            bbsParms.signType = values?.signType ? values?.signType : ''
        } else {
            bbsParms.signType = values?.signType ? values.signType.join() : ''
        }

        bbsParms.regionId = values.regionId ? values.regionId.join() : ''


        bbsParms.path = picpath?.join(",")

        console.log('bbsParms', bbsParms)
        if (sessionStorage["BBSEDITDATA"]) {
            bbsParms.id = JSON.parse(sessionStorage["BBSEDITDATA"]).id
            forum
                .lockTopic(
                    bbsParms,
                    JSON.parse(sessionStorage.getItem("USERDATA")).token
                )
                .then((res) => res.json())
                .then((result) => {
                    if (result.ret === 200) {
                        this.setState({
                            loadings: false
                        }, () => {
                            message.success(intl.get("XIUGAICHNGGONG"))
                            this.props.history.goBack()
                            this.formRef.current.resetFields()
                        })

                    }

                    this.setState({
                        loadings: false
                    })
                });
        } else {
            forum
                .addForumTopic(
                    bbsParms,
                    JSON.parse(sessionStorage.getItem("USERDATA")).token,
                    this.state.UUID
                )
                .then((res) => res.json())
                .then((result) => {
                    if (result.ret === 200) {

                        this.setState({
                            loadings: false
                        }, () => {
                            message.success(intl.get("QINGFATEIE"))
                            this.props.history.goBack()
                            this.formRef.current.resetFields()
                        })
                    }
                });
        }

    };



    onFinishFailed = (failedValues) => {
        console.log('this.state.fileList', this.state.fileList)

        console.log("failedValues", failedValues);
        this.setState({
            loadings: false
        })
    };



    goto = (id) => {
        // 找到锚点
        let anchorElement = document.getElementById("sp" + id);
        console.log('anchorElement.scrollIntoView()', anchorElement)
        // 如果对应id的锚点存在，就跳转到锚点
        if (anchorElement) { anchorElement.scrollIntoView(); }

    }

    judgeComponent = (type, data, parm) => {
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        switch (type) {
            case "input":
                return <Input bordered={false} placeholder="Please input" />;
            case "radio":
                return (
                    <Radio.Group bordered={false}>
                        {data.map((item) => {
                            return <Radio value={item.value} key={item.value}>{item.label}</Radio>;
                        })}
                    </Radio.Group>
                );
            case "select":
                return (
                    <Select mode={parm ? "multiple" : ''} placeholder="Please select" bordered={false} style={{ width: '100%' }}>
                        {data.map((item) => {
                            return <Option value={item.value} key={item.value}>{item.label}</Option>;
                        })}
                    </ Select>
                );
            case "specialSelect":
                return (
                    <Select placeholder="Please select " bordered={false}>
                        {this.state.childlistdata.map((item) => {
                            return <Option value={item.id} key={item.id}>{item.categoryName}</Option>;
                        })}
                    </Select>
                );
            case "checkbox":
                return (
                    <Checkbox.Group options={data} bordered={false} />
                );
            case "edit":
                return (
                    <TextArea maxLength={{ minRows: 3, maxRows: 5 }} />
                )

            case "upload":
                return (
                    <>
                        <Upload
                            action={process.env.REACT_APP_UPLOAD_API + "/website/upload/uploadpic"}
                            listType="picture-card"
                            data={this.state.mainpathparms}
                            headers={this.state.headers}
                            fileList={fileList}
                            multiple
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                        >
                            {fileList.length >= 8 ? null : uploadButton}
                        </Upload>
                        <Modal
                            visible={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={this.handleCancel}
                        >
                            <img alt="img" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </>
                )
            default:
                break;
        }
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChange = ({ fileList }) => this.setState({ fileList });

    handleGoToBBS = () => {
        this.props.history.push('/bbspages')
    }

    render() {

        return (
            <>
                <div className="phone_form">

                    <Form
                        layout='horizontal'
                        className="send_bbs_reply_form_item_form"
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <div className="new_bbs_top">
                            <div onClick={this.handleGoToBBS}>取消</div>
                            <Button htmlType="submit" className="new_bbs_top_right" loading={this.state.loadings}>
                                {intl.get("FABIAO")}
                            </Button>
                        </div>
                        {
                            this.state.formTypeData.map((item) => {
                                return (
                                    <>
                                        {
                                            item?.data.map((item2, index) => {
                                                return (
                                                    <Form.Item
                                                        label={item2.label}
                                                        name={item2.name}
                                                        rules={[
                                                            {
                                                                required: item2.require ? item2.require : false,
                                                                message: item2.requireMsg,
                                                            },
                                                        ]}
                                                        key={index}
                                                    >
                                                        {this.judgeComponent(item2.type, item2.typeData, item2.mutial)}
                                                    </Form.Item>
                                                );
                                            })
                                        }
                                    </>
                                )
                            })
                        }



                    </Form>
                </div>

            </>
        );
    }
}

