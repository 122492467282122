import React from 'react'
import './FilterComponent.css'
import column from '../../api/column';
import forum from '../../api/bbs'


import intl from 'react-intl-universal';



export default class FilterComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            mainTypeList: [],
            secondaryTypeList: [],
            cityTypeList: [
                {
                    id: '',
                    name: intl.get('QUANBUDIQU')
                },
                {
                    id: 1,
                    name: intl.get('BULISB')
                },
                {
                    id: 2,
                    name: intl.get('HUANGJIANHAN')
                },
                {
                    id: 3,
                    name: intl.get('YANGHUANGHANHAN')
                },
                {
                    id: 4,
                    name: intl.get('KAIENSI')
                },
            ],
            mainTypeName: '',
            cityTypeName: intl.get('QUANBUDIQU'),

            sellerTypeData: [
                {
                    label: intl.get("GEREN"),
                    value: 1,
                },
                {
                    label: intl.get("SHANGJIA"),
                    value: 2,
                },
            ],

            sellerTypeName: intl.get('BUXIAN'),
            dealtypeData: [
                {
                    label: intl.get("CHUSHOU"),
                    value: 1,
                },
                {
                    label: intl.get("QIUGOU"),
                    value: 2,
                },
                {
                    label: intl.get("ZENGSONG"),
                    value: 3,
                },
            ],
            dealtypeName: intl.get('BUXIAN'),
            neworoldData: [
                {
                    label: intl.get("QUANXIAN"),
                    value: 1,
                },
                {
                    label: intl.get("ERSHOU"),
                    value: 2,
                },
            ],
            industryName: intl.get('BUXIAN'),
            industryData: [
                {
                    label: intl.get("CANGTING"),
                    value: 1,
                },
                {
                    label: intl.get("CHAOSHI"),
                    value: 2,
                },
                {
                    label: intl.get("KAFEITING"),
                    value: 3,
                },
                {
                    label: intl.get("QINGJIE"),
                    value: 4,
                },
                {
                    label: intl.get("JIUDIAN"),
                    value: 5,
                },
                {
                    label: intl.get("LIPINGDIAN"),
                    value: 6,
                },
                {
                    label: intl.get("JIAYOUZHAN"),
                    value: 7,
                },
                {
                    label: intl.get("MIANBAODIAN"),
                    value: 8,
                },
                {
                    label: intl.get("NONGCHANG"),
                    value: 9,
                },
                {
                    label: intl.get("QITAHANGYE"),
                    value: 10,
                },
            ],
            letoutTypeName: intl.get('BUXIAN'),
            letoutTypeData: [
                {
                    label: intl.get("DANJIAN"),
                    value: 1,
                },
                {
                    label: intl.get("KETING"),
                    value: 2,
                },
                {
                    label: intl.get("ZHENGZU"),
                    value: 3,
                },
                {
                    label: intl.get("HEZHU"),
                    value: 4,
                },
                {
                    label: intl.get("ZHUANGZU"),
                    value: 5,
                },
                {
                    label: intl.get("CHUANGWEI"),
                    value: 6,
                },
            ]
        }
    }

    componentDidMount() {
        this.initData()
    }

    getShopListByApi = async ({ parentId = 2, language = sessionStorage['language'], stat = 1 }) => {
        const result = await column.columnInfoWeb({ parentId, language, stat })
        const apiData = await result.json()
        return apiData.data.sort((item1, item2) => {
            return item1.id - item2.id
        })
    }

    getBbsListByApi = async ({
        parentId = 0,
        language = sessionStorage['language']
    }) => {
        const result = await forum.forumCategoryListWeb({ parentId, language })
        const apiData = await result.json()
        return apiData.data.sort((item1, item2) => {
            return item1.id - item2.id
        })
    }

    initData = async () => {
        console.log('sessionStorage====>', sessionStorage['MAINTYPE'])
        let a = []
        let b = []
        switch (this.props.type) {
            case '1':
                a = await this.getShopListByApi({})
                b = await this.getShopListByApi({ parentId: sessionStorage['MAINTYPE'] ? sessionStorage['MAINTYPE'] : 11 })
                if (sessionStorage['MAINTYPE'] === undefined) {
                    sessionStorage['MAINTYPE'] = 11
                }
                if (sessionStorage['CITY'] === undefined) {
                    sessionStorage['CITY'] = ''
                }
                this.setState({
                    mainTypeList: a ?? [],
                    secondaryTypeList: b ?? []
                }, () => {
                    this.changeLang()
                })

                break;
            case '2':
                a = await this.getBbsListByApi({})
                b = await this.getBbsListByApi({ parentId: sessionStorage['MAINTYPE'] ? sessionStorage['MAINTYPE'] : 41 })
                for (let i in a) {
                    a[i].columnName = a[i].categoryName
                }

                for (let j in b) {
                    b[j].columnName = b[j].categoryName
                }
                if (sessionStorage['MAINTYPE'] === undefined) {
                    sessionStorage['MAINTYPE'] = 41
                }
                sessionStorage['SELLERTYPE'] = ''
                sessionStorage['DEALTYPE'] = ''
                sessionStorage['INDUSTRY'] = ''
                sessionStorage['LETOUTTYPE'] = ''
                sessionStorage['SORT'] = ''
                this.setState({
                    mainTypeList: a ?? [],
                    secondaryTypeList: b ?? []
                }, () => {
                    this.changeLang()
                    this.props.filterAction()

                })
                break;
            default:
                break;
        }
    }

    //主分类
    handleChooseMainType = async (val) => {
        sessionStorage['MAINTYPE'] = val.id
        this.props.filterAction()
        sessionStorage['SECONDTYPE'] = ''
        sessionStorage['SELLERTYPE'] = ''
        sessionStorage['DEALTYPE'] = ''
        sessionStorage['INDUSTRY'] = ''
        sessionStorage['LETOUTTYPE'] = ''
        sessionStorage['SORT'] = ''
        let b = []
        switch (this.props.type) {
            case '1':
                b = await this.getShopListByApi({ parentId: val.id })
                this.setState({
                    secondaryTypeList: b ?? []
                }, () => {
                    this.changeLang()
                })
                break;
            case '2':
                b = await this.getBbsListByApi({ parentId: val.id })
                for (let j in b) {
                    b[j].columnName = b[j].categoryName
                }
                this.setState({
                    secondaryTypeList: b ?? []
                }, () => {
                    this.changeLang()
                })
                break;
            default:
                break
        }

    }

    // 次级分类
    handleChooseSecondType = (val) => {
        sessionStorage['SECONDTYPE'] = val.id
        this.props.filterAction()
        this.setState({})
    }

    // 城市
    handleChooseCity = (val) => {
        sessionStorage['CITY'] = val.id
        this.props.filterAction()
        this.setState({}, () => {
            this.changeLang()
        })
    }

    // 语言切换
    changeLang = () => {
        const mainObj = this.state.mainTypeList.find(item => {
            return Number(sessionStorage['MAINTYPE']) === item.id
        })
        const cityObj = this.state.cityTypeList.find(item => {
            if (sessionStorage['CITY'] === '') {
                return item.id === ''
            } else {
                return Number(sessionStorage['CITY']) === item.id
            }
        })
        this.setState({
            mainTypeName: mainObj?.columnName,
            cityTypeName: cityObj?.name
        })

    }

    // 排序
    handleSort = (val) => {
        console.log('handleSort', val)
        sessionStorage['SORT'] = val
        this.props.filterAction()
        this.setState({})
    }

    sortFilter = () => {
        if (this.props.type === '1') {
            return (
                <div className="filter_by_sort">
                    <div className={`${Number(sessionStorage['SORT']) === 0 ? 'filter_by_sort_item_active' : 'filter_by_sort_item customer_hover'}`} onClick={this.handleSort.bind(this, 0)}>{intl.get('MORENPAIXU')}</div>
                    <div className={`${Number(sessionStorage['SORT']) === 1 ? 'filter_by_sort_item_active' : 'filter_by_sort_item customer_hover'}`} onClick={this.handleSort.bind(this, 1)}>{intl.get('ANRENQI')}</div>
                    <div className={`${Number(sessionStorage['SORT']) === 3 ? 'filter_by_sort_item_active' : 'filter_by_sort_item customer_hover'}`} onClick={this.handleSort.bind(this, 3)}>{intl.get('ANREDU')}</div>
                </div>
            )
        } else {
            return (
                <div className="filter_by_sort">
                    <div className={`${Number(sessionStorage['SORT']) === 0 ? 'filter_by_sort_item_active' : 'filter_by_sort_item customer_hover'}`} onClick={this.handleSort.bind(this, 0)}>{intl.get('MORENPAIXU')}</div>
                    {/* <div className={`${Number(sessionStorage['SORT']) === 1 ? 'filter_by_sort_item_active' : 'filter_by_sort_item'}`} onClick={this.handleSort.bind(this, 1)}>{intl.get('ANREDU')}</div> */}
                </div>
            )
        }
    }

    // 销售类型
    handleChooseSellerType = (val) => {
        sessionStorage['SELLERTYPE'] = val.value
        this.props.filterAction()
        this.setState({
            sellerTypeName: val.label
        })
    }

    // 交易类型
    handleChooseDealType = (val) => {
        sessionStorage['DEALTYPE'] = val.value
        this.props.filterAction()
        this.setState({
            dealtypeName: val.label
        })
    }

    // 行业
    handleChooseIndustry = (val) => {
        sessionStorage['INDUSTRY'] = val.value
        this.props.filterAction()
        this.setState({
            industryName: val.label
        })
    }

    // 出租方式
    handleChooseLetOut = (val) => {
        sessionStorage['LETOUTTYPE'] = val.value
        this.props.filterAction()
        this.setState({
            letoutTypeName: val.label
        })
    }

    otherFilter = () => {
        console.log('sessionStorage[MAINTYPE]', sessionStorage['MAINTYPE'])
        if (this.props.type === '2' && sessionStorage['MAINTYPE'] === '11') {
            return (
                <>
                    <div className="filter_lines_other_item">
                        <div className="filter_lines_other_item_first">
                            <div className="filter_lines_other_item_first_text">{intl.get('FATIELEIXING')}</div>
                            <div className="filter_icon"></div>
                        </div>
                        <div className="filter_lines_other_item_second">
                            <div className="filter_lines_other_item_second_text">{this.state.sellerTypeName}</div>
                            <div className="filter_icon"></div>
                        </div>
                        <div className="filter_lines_other_item_special">{intl.get('REMENFENLEI')}：</div>
                        <div className="filter_lines_other_item_third">

                            {
                                // eslint-disable-next-line array-callback-return
                                this.state.sellerTypeData.map((item) => {
                                    if (item.value !== '') {
                                        return (
                                            <div className={`${Number(sessionStorage['SELLERTYPE']) === item.value ? 'filter_lines_other_item_third_item_active' : 'filter_lines_other_item_third_item customer_hover'}`} key={item.value} onClick={this.handleChooseSellerType.bind(this, item)}>{item.label}</div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className={`${sessionStorage['SELLERTYPE'] === '' ? 'filter_lines_other_item_fouth_active' : 'filter_lines_other_item_fouth customer_hover'}`} onClick={this.handleChooseSellerType.bind(this, { value: '', label: intl.get('BUXIAN') })}>
                            {intl.get('BUXIAN')}
                        </div>
                    </div>


                    <div className="filter_lines_other_item">
                        <div className="filter_lines_other_item_first">
                            <div className="filter_lines_other_item_first_text">{intl.get('JIAOYILEIXING')}</div>
                            <div className="filter_icon"></div>
                        </div>
                        <div className="filter_lines_other_item_second">
                            <div className="filter_lines_other_item_second_text">{this.state.dealtypeName}</div>
                            <div className="filter_icon"></div>
                        </div>
                        <div className="filter_lines_other_item_special">{intl.get('REMENFENLEI')}：</div>
                        <div className="filter_lines_other_item_third">

                            {
                                this.state.dealtypeData.map((item) => {
                                    if (item.value !== '') {
                                        return (
                                            <div className={`${Number(sessionStorage['DEALTYPE']) === item.value ? 'filter_lines_other_item_third_item_active' : 'filter_lines_other_item_third_item customer_hover'}`} key={item.value} onClick={this.handleChooseDealType.bind(this, item)}>{item.label}</div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className={`${sessionStorage['DEALTYPE'] === '' ? 'filter_lines_other_item_fouth_active' : 'filter_lines_other_item_fouth customer_hover'}`} onClick={this.handleChooseDealType.bind(this, { value: '', label: intl.get('BUXIAN') })}>
                            {intl.get('BUXIAN')}
                        </div>
                    </div>
                </>
            )
        }

        if (this.props.type === '2' && sessionStorage['MAINTYPE'] === '9') {
            return (
                <>
                    <div className="filter_lines_other_item">
                        <div className="filter_lines_other_item_first">
                            <div className="filter_lines_other_item_first_text">{intl.get('HANGYE')}</div>
                            <div className="filter_icon"></div>
                        </div>
                        <div className="filter_lines_other_item_second">
                            <div className="filter_lines_other_item_second_text">{this.state.industryName}</div>
                            <div className="filter_icon"></div>
                        </div>
                        <div className="filter_lines_other_item_special">{intl.get('REMENFENLEI')}：</div>
                        <div className="filter_lines_other_item_third">

                            {
                                this.state.industryData.map((item) => {
                                    if (item.value !== '') {
                                        return (
                                            <div className={`${Number(sessionStorage['INDUSTRY']) === item.value ? 'filter_lines_other_item_third_item_active' : 'filter_lines_other_item_third_item customer_hover'}`} key={item.value} onClick={this.handleChooseIndustry.bind(this, item)}>{item.label}</div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className={`${sessionStorage['INDUSTRY'] === '' ? 'filter_lines_other_item_fouth_active' : 'filter_lines_other_item_fouth customer_hover'}`} onClick={this.handleChooseIndustry.bind(this, { value: '', label: intl.get('BUXIAN') })}>
                            {intl.get('BUXIAN')}
                        </div>
                    </div>

                </>
            )
        }

        if (this.props.type === '2' && sessionStorage['MAINTYPE'] === '7') {
            return (
                <>
                    <div className="filter_lines_other_item">
                        <div className="filter_lines_other_item_first">
                            <div className="filter_lines_other_item_first_text">{intl.get('CHUZHUFANGSHI')}</div>
                            <div className="filter_icon"></div>
                        </div>
                        <div className="filter_lines_other_item_second">
                            <div className="filter_lines_other_item_second_text">{this.state.letoutTypeName}</div>
                            <div className="filter_icon"></div>
                        </div>
                        <div className="filter_lines_other_item_special">{intl.get('REMENFENLEI')}：</div>
                        <div className="filter_lines_other_item_third">

                            {
                                this.state.letoutTypeData.map((item) => {
                                    if (item.value !== '') {
                                        return (
                                            <div className={`${Number(sessionStorage['LETOUTTYPE']) === item.value ? 'filter_lines_other_item_third_item_active' : 'filter_lines_other_item_third_item customer_hover'}`} key={item.value} onClick={this.handleChooseLetOut.bind(this, item)}>{item.label}</div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className={`${sessionStorage['LETOUTTYPE'] === '' ? 'filter_lines_other_item_fouth_active' : 'filter_lines_other_item_fouth customer_hover'}`} onClick={this.handleChooseLetOut.bind(this, { value: '', label: intl.get('BUXIAN') })}>
                            {intl.get('BUXIAN')}
                        </div>
                    </div>

                </>
            )
        }
    }


    render() {
        return (
            <div className="filter">
                <div className="filter_lines">
                    <div className="filter_lines_main">

                        {
                            this.state.mainTypeList.map((item, index) => {
                                return (
                                    <div className={`${Number(sessionStorage['MAINTYPE']) === item.id ? 'filter_lines_main_item_active' : 'filter_lines_main_item customer_hover'}`} key={item.id} onClick={this.handleChooseMainType.bind(this, item)}>{item.columnName}</div>
                                )
                            })
                        }

                    </div>
                    <div className="filter_lines_other">
                        <div className="filter_lines_other_item">
                            <div className="filter_lines_other_item_first">
                                <div className="filter_lines_other_item_first_text">{intl.get('FENLEI')}</div>
                                <div className="filter_icon"></div>
                            </div>
                            <div className="filter_lines_other_item_second">
                                <div className="filter_lines_other_item_second_text">
                                    {this.state.mainTypeName}
                                </div>
                                <div className="filter_icon"></div>
                            </div>
                            <div className="filter_lines_other_item_special">{intl.get('REMENFENLEI')}：</div>
                            <div className="filter_lines_other_item_third">
                                {
                                    this.state.secondaryTypeList.map((item) => {
                                        return (
                                            <div className={`${Number(sessionStorage['SECONDTYPE']) === item.id ? 'filter_lines_other_item_third_item_active' : 'filter_lines_other_item_third_item customer_hover'}`} key={item.id} onClick={this.handleChooseSecondType.bind(this, item)}>{item.columnName}</div>
                                        )
                                    })
                                }
                            </div>
                            <div className={`${sessionStorage['SECONDTYPE'] === '' ? 'filter_lines_other_item_fouth_active' : 'filter_lines_other_item_fouth customer_hover'}`} onClick={this.handleChooseSecondType.bind(this, { id: '' })}>
                                {intl.get('QUANBUFENLEI')}
                            </div>
                        </div>


                        <div className="filter_lines_other_item">
                            <div className="filter_lines_other_item_first">
                                <div className="filter_lines_other_item_first_text">{intl.get('DIQU')}</div>
                                <div className="filter_icon"></div>
                            </div>
                            <div className="filter_lines_other_item_second">
                                <div className="filter_lines_other_item_second_text">{this.state.cityTypeName}</div>
                                <div className="filter_icon"></div>
                            </div>
                            <div className="filter_lines_other_item_special">{intl.get('REMENCHENGSHI')}：</div>
                            <div className="filter_lines_other_item_third">

                                {
                                    this.state.cityTypeList.map((item) => {
                                        if (item.id !== '') {
                                            return (
                                                <div className={`${Number(sessionStorage['CITY']) === item.id ? 'filter_lines_other_item_third_item_active' : 'filter_lines_other_item_third_item customer_hover'}`} key={item.id} onClick={this.handleChooseCity.bind(this, item)}>{item.name}</div>
                                            )
                                        }
                                    })
                                }
                            </div>
                            <div className={`${sessionStorage['CITY'] === '' ? 'filter_lines_other_item_fouth_active' : 'filter_lines_other_item_fouth customer_hover'}`} onClick={this.handleChooseCity.bind(this, { id: '' })}>
                                {intl.get('QUANBUDIQU')}
                            </div>
                        </div>



                        {
                            this.otherFilter()
                        }


                        {
                            this.sortFilter()
                        }



                    </div>
                </div>

            </div>
        )
    }
}


