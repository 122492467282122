import { httpPost } from '../utils/request'




const CHAT = {
    // 获取历史信息
    queryFrame(parms, token) {
        let query = {}
        if (parms.memberId) {
            query.memberId = parms.memberId // 普通客户id
        }
        if (parms.userId) {
            query.userId = parms.userId // 商家id
        }
        const url = '/website/chat/queryFrame'
        return httpPost(url, query, token)
    },
}


export default CHAT