import intl from "react-intl-universal";
export function nomalType() {
  return (
    [
      {
        baseComponent: intl.get("JICHUXINXI"),
        show: true,
        id: 1,
        data: [
          {
            type: "specialSelect",
            name: "childCategory",
            require: true,
            requireMsg: 'please select',
            label: intl.get("XINGZXHI"),
            typeData: [],
          },
          {
            type: "input",
            name: "mainTitle",
            require: true,
            requireMsg: 'please input',
            label: intl.get("BIAOTIE"),
          },
          {
            type: "input",
            name: "subTitle",
            require: false,
            requireMsg: 'please input',
            label: intl.get("FUBIAOTIE"),
          },
          {
            type: "select",
            name: "regionId",
            mutial: true,
            require: true,
            requireMsg: 'please select',
            label: intl.get("SUOZAIQUYU"),
            typeData: [
              {
                label: intl.get("BULISB"),
                value: 1,
              },
              {
                label: intl.get("HUANGJIANHAN"),
                value: 2,
              },
              {
                label: intl.get("YANGHUANGHANHAN"),
                value: 3,
              },
              {
                label: intl.get("KAIENSI"),
                value: 4,
              }
            ]
          },
          {
            baseComponent: intl.get("XIANGQINGMIAOSHU"),
            show: true,
            id: 2,
            data: [
              {
                type: "edit",
                name: "context",
                require: true,
                label: intl.get("XIANGQINGMIAOSHU"),
              }
            ]
          },
          {
            baseComponent: intl.get("SHANGCHUANGTUPIAN"),
            show: true,
            id: 3,
            data: [
              {
                type: "upload",
                name: "path",
                require: false,
                label: intl.get("SHANGCHUANGTUPIAN"),
              }
            ]
          },]
      },
      {
        baseComponent: intl.get("XIANGQINGMIAOSHU"),
        show: true,
        id: 2,
        data: [
          {
            type: "edit",
            name: "context",
            require: true,
            label: intl.get("XIANGQINGMIAOSHU"),
          }
        ]
      },
      {
        baseComponent: intl.get("SHANGCHUANGTUPIAN"),
        show: true,
        id: 3,
        data: [
          {
            type: "upload",
            name: "path",
            require: false,
            label: intl.get("SHANGCHUANGTUPIAN"),
          }
        ]
      },
      {
        baseComponent: intl.get("LIANXIFANGSHI"),
        show: true,
        id: 4,
        data: [
          {
            type: "input",
            name: "phone",
            require: false,
            requireMsg: 'please input',
            label: intl.get("LIANXIDIANHUA"),
          },
          {
            type: "input",
            name: "email",
            require: false,
            label: intl.get("DIANZIYOUJIAN"),
          },
          {
            type: "input",
            name: "wechat",
            require: false,
            label: intl.get("WEIXIN"),
          },
          {
            type: "input",
            name: "line",
            require: false,
            label: intl.get("LINE"),
          },
          {
            type: "input",
            name: "facebook",
            require: false,
            label: intl.get("Facebook"),
          },
          {
            type: "input",
            name: "qq",
            require: false,
            label: intl.get("QQ"),
          },
        ]
      }
    ]
  )
}