import React from 'react'
import './AdConsult.css'
import { Input, Button, Select, message } from 'antd';
import connect from '../../api/connnect'

import intl from 'react-intl-universal';

const { Option } = Select;
export default class AdConsult extends React.Component {
    constructor() {
        super()
        this.state = {
            industry: '',
            name: '',
            phone: '',
            loading: false
        }
    }

    handleChangeIndustry = (e) => {
        console.log('e', e)
        this.setState({
            industry: e
        })
    }

    handleChangeName = (e) => {
        console.log('e', e.target.value)
        this.setState({
            name: e.target.value
        })
    }

    handleChangePhone = (e) => {
        this.setState({
            phone: e.target.value
        })
    }

    handleSubmit = () => {
        if (!this.state.industry || !this.state.name || !this.state.phone) {
            message.warning(intl.get('QINGSHURU'));
            return
        }
        this.setState({
            loading: true
        })

        let parms = {
            // email: 'esoqld.com.au@gmail.com',
            email: 'news@acnews.com.au',
            context: ` ${intl.get('XINGMIMIGHN')}: ${this.state.name}
            ${intl.get('SHOUJIHAOMA')}: ${this.state.phone}
            ${intl.get('XUQIUNEIRONG')}: ${this.state.industry}
            `
        }

        console.log('parms', parms)

        connect.sendContent(parms)
            .then(res => res.json())
            .then(result => {
                if (result.ret === 200) {
                    this.setState({
                        phone: '',
                        name: '',
                        industry: ''
                    })
                    message.success(intl.get('FASONGCHENGG'));
                }
                this.setState({
                    loading: false
                })
            })
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 5000)
    }




    render() {
        return (
            <div className="ad">
                <div className="ad_top">
                    {intl.get('GUANGGAOZIXUN')}
                </div>
                <div className="ad_middle">
                    {intl.get('ADRESULT')}
                </div>
                <div className="ad_bottom">
                    <div className="ad_bottom_item">
                        <Select style={{ width: '2.35rem' }} size={'large'} onChange={this.handleChangeIndustry}>
                            {
                                this.props.industyData.map(item => {
                                    return (
                                        <Option value={item.title} key={item.id}>{item.title}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className="ad_bottom_item">
                        <Input placeholder={intl.get('SHURUNIDEXINGM')} style={{ width: '2.35rem' }} size={'large'} value={this.state.name} onChange={this.handleChangeName} />
                    </div>
                    <div className="ad_bottom_item">
                        <Input placeholder={intl.get('SHURUNIDEDIANHUA')} style={{ width: '2.35rem' }} size={'large'} value={this.state.phone} onChange={this.handleChangePhone} />
                    </div>
                    <div className="ad_bottom_item">
                        <Button type="primary" style={{ width: '2.35rem', background: '#3FD5D3', borderColor: '#3FD5D3' }} loading={this.state.loading} size={'large'} onClick={this.handleSubmit}>{intl.get('TIJIAO')} </Button>
                    </div>
                </div>

            </div>
        )
    }
}


