import React from 'react'
import './Menu.css'
import { Menu } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as house } from '../../icons/menuicons/house.svg';
import { ReactComponent as car } from '../../icons/menuicons/car.svg';
import { ReactComponent as buessiness } from '../../icons/menuicons/buessiness.svg';
import { ReactComponent as medic } from '../../icons/menuicons/medic.svg';
import { ReactComponent as migrant } from '../../icons/menuicons/migrant.svg';
import { ReactComponent as Professional } from '../../icons/menuicons/Professional.svg';
import { ReactComponent as food } from '../../icons/menuicons/food.svg';
import { ReactComponent as home } from '../../icons/menuicons/home.svg';
import { ReactComponent as repair } from '../../icons/menuicons/repair.svg';

import { ReactComponent as friends } from '../../icons/bbs/friends.svg';
import { ReactComponent as secondhand } from '../../icons/bbs/secondhand.svg';
import { ReactComponent as job } from '../../icons/bbs/job.svg';
import { ReactComponent as renthome } from '../../icons/bbs/renthome.svg';


import intl from 'react-intl-universal';

const { SubMenu } = Menu;

export default class Menus extends React.Component {
    constructor() {
        super()
        this.state = {
            iconsFilter: {
                38: <Icon component={car} style={{ fontSize: '.20rem' }} className="init-icons" />,
                18: <Icon component={house} style={{ fontSize: '.20rem' }} className="init-icons" />,
                17: <Icon component={buessiness} style={{ fontSize: '.20rem' }} className="init-icons" />,
                16: <Icon component={medic} style={{ fontSize: '.20rem' }} className="init-icons" />,
                15: <Icon component={migrant} style={{ fontSize: '.20rem' }} className="init-icons" />,
                14: <Icon component={Professional} style={{ fontSize: '.20rem' }} className="init-icons" />,
                13: <Icon component={food} style={{ fontSize: '.20rem' }} className="init-icons" />,
                12: <Icon component={home} style={{ fontSize: '.20rem' }} className="init-icons" />,
                11: <Icon component={repair} style={{ fontSize: '.20rem' }} className="init-icons" />,

                'bbs41': <Icon component={friends} style={{ fontSize: '.20rem' }} className="init-icons" />,
                'bbs11': <Icon component={secondhand} style={{ fontSize: '.20rem' }} className="init-icons" />,
                'bbs10': <Icon component={car} style={{ fontSize: '.20rem' }} className="init-icons" />,
                'bbs9': <Icon component={buessiness} style={{ fontSize: '.20rem' }} className="init-icons" />,
                'bbs8': <Icon component={house} style={{ fontSize: '.20rem' }} className="init-icons" />,
                'bbs7': <Icon component={renthome} style={{ fontSize: '.20rem' }} className="init-icons" />,
                'bbs6': <Icon component={job} style={{ fontSize: '.20rem' }} className="init-icons" />,
            }
        }
    }

    handleGetId = (id, id2) => {
        console.log('id', id)
        console.log('id2', id2)
        this.props.setMenuId(id, id2)
    }

    diffMenuType = (item) => {
        switch(this.props.menuType) {
            case 1: 
          return  (item?.children?.sort((ar1, ar2) => {
                return ar1.id - ar2.id
            }).map((item2, index2) => {
                return (
                    <div key={index2} className="ant-menu-title-content-context-secondary" onClick={this.handleGetId.bind(this, item.id, item2.id)}>{item2.columnName ? item2.columnName : item2.categoryName}</div>

                )
            }))
         
        case 2: 
        return (
            item?.children?.map((item2, index2) => {
                return (
                    <div key={index2} className="ant-menu-title-content-context-secondary" onClick={this.handleGetId.bind(this, item.id, item2.id)}>{item2.columnName ? item2.columnName : item2.categoryName}</div>
                )
            })
        )
         
        default:
            break

        }
    }

    render() {
        return (
            <div className="menus">
                <Menu style={{ width: '3.84rem' }} mode="vertical">
                    {
                        this.props.menuData.map((item, index) => {
                            return (
                                <SubMenu key={index} icon={this.state.iconsFilter[item.iconid]}
                                    title={React.createElement('span', { className: 'ant-menu-title-content-main' }, item.title, React.createElement('span', { className: 'ant-menu-title-content-secondary' }, item.subTitle), React.createElement('span', { className: 'ant-menu-title-content-secondary' }, item.subTitle2))}>

                                    <Menu.Item key={item.title} style={{ width: '3.84rem', minHeight: '2.2rem', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                        {
                                            <>
                                                <div className="ant-menu-title-content-item">
                                                    <div className="ant-menu-title-content-item-main">
                                                        {item.title}
                                                    </div>
                                                    <div className="ant-menu-title-content-lines"></div>
                                                    <div className="ant-menu-title-content-context">
                                                        {
                                                            this.diffMenuType(item)
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </Menu.Item>
                                </SubMenu>
                            )
                        })
                    }


                </Menu>
            </div>
        )
    }
}


