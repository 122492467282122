import React from "react";
import { Avatar } from "antd";

import { LeftOutlined, UserOutlined } from "@ant-design/icons";
import PhoneChatDetail from '../PhoneChatDetail/PhoneChatDetail'
import { Search, Popup } from "antd-mobile";
import "./PhoneChatList.css";
import intl from "react-intl-universal";
import CHAT from '../../api/chat'




export default class PhoneChatList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageVisible: this.props.messageVisible,
      messageDetailVisible: false,
      chatData: [],
      chatDetail: undefined
    };
  }

  componentDidMount() {
    this.getHistory()
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.messageVisible !== state.messageVisible) {
      return {
        messageVisible: props.messageVisible,
      };
    }
    return null;
  };

  handleGoBack = () => {
    this.setState({
      messageVisible: false
    }, () => {
      this.props.listClose()
    })

  };
  handleMessageDetail = (item) => {
    console.log('item', item)
    this.setState({
      messageDetailVisible: true,
      chatDetail: item
    }, () => {
      this.$child.sendChild()
    })

    // // 普通客户
    // if (JSON.parse(sessionStorage.getItem("USERDATA"))?.customer?.isMerchants === 0) {
    //   CHAT.queryFrame({
    //     memberId: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
    //   }, JSON.parse(sessionStorage.getItem("USERDATA"))?.token).then(res => res.json())
    //     .then(result => {
    //       console.log('result', result)
    //       if (result.ret === 200 && result.data.content.length !== 0) {
    //         this.setState({
    //           chatDetail: result.data.content.filter(item2 => {
    //             return item2.id === item.id
    //           })[0],
    //           messageDetailVisible: true,

    //         }, () => {
    //           this.$child.sendChild()
    //         })
    //       }
    //     })
    // } else {
    //   // 商家账号
    //   CHAT.queryFrame({
    //     userId: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
    //   }, JSON.parse(sessionStorage.getItem("USERDATA"))?.token).then(res => res.json())
    //     .then(result => {
    //       console.log('result', result)
    //       if (result.ret === 200 && result.data.content.length !== 0) {
    //         this.setState({
    //           chatDetail: result.data.content.filter(item2 => {
    //             return item2.id === item.id
    //           })[0],
    //           messageDetailVisible: true,
    //         }, () => {
    //           this.$child.sendChild()
    //         })
    //       }
    //     })
    // }
  }


  getClose = () => {
    this.setState({
      messageDetailVisible: false
    }, () => {
      this.getHistory()

    })
  }


  getHistory = () => {
    if (JSON.parse(sessionStorage.getItem("USERDATA"))) {
      // 普通客户
      if (JSON.parse(sessionStorage.getItem("USERDATA"))?.customer?.isMerchants === 0) {
        CHAT.queryFrame({
          memberId: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
        }, JSON.parse(sessionStorage.getItem("USERDATA"))?.token).then(res => res.json())
          .then(result => {
            console.log('result', result)
            if (result.ret === 200 && result.data.content.length !== 0) {
              this.setState({
                chatData: result.data.content
              })
            } else {
              this.setState({
                chatData: [{
                  chatRecords: []
                }]
              })
            }
          })
      } else {
        // 商家账号
        CHAT.queryFrame({
          userId: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
        }, JSON.parse(sessionStorage.getItem("USERDATA"))?.token).then(res => res.json())
          .then(result => {
            console.log('result', result)
            if (result.ret === 200 && result.data.content.length !== 0) {
              this.setState({
                chatData: result.data.content
              })
            } else {
              this.setState({
                chatData: [{
                  chatRecords: []
                }]
              })
            }
          })
      }
    }

  }


  // 不同账号的消息中心
  diffContentList = () => {
    // 商家接收信息
    if (JSON.parse(sessionStorage.getItem("USERDATA"))?.customer?.isMerchants !== 0) {
      return (
        this.state.chatData.map((item) => {

          return (
            <div className="message_list_content_item" onClick={this.handleMessageDetail.bind(this, item)} key={item.id}>
              <div className="message_list_content_item_avatar">
                <Avatar size={40} icon={<UserOutlined />} src={item.memberPath} />
              </div>
              <div className="message_list_content_item_text">
                <div className="message_list_content_item_titles">
                  <div className="message_list_content_item_title">{item.memberName}</div>
                  <div className="message_list_content_item_title" dangerouslySetInnerHTML={{ __html: item.chatRecords[item.chatRecords.length - 1]?.content }}></div>
                </div>
                <div className="message_list_content_item_time">{item.chatRecords[item.chatRecords.length - 1]?.time}</div>
              </div>
            </div>
          )
        })
      )
    } else {

      return (
        this.state.chatData.map((item) => {

          return (
            <div className="message_list_content_item" onClick={this.handleMessageDetail.bind(this, item)} key={item.id}>
              <div className="message_list_content_item_avatar">
                <Avatar size={40} icon={<UserOutlined />} src={item.userPath} />
              </div>
              <div className="message_list_content_item_text">
                <div className="message_list_content_item_titles">
                  <div className="message_list_content_item_title">{item.userName}</div>
                  <div className="message_list_content_item_title" dangerouslySetInnerHTML={{ __html: item.chatRecords[item.chatRecords.length - 1]?.content }}></div>

                </div>
                <div className="message_list_content_item_time">{item.chatRecords[item.chatRecords.length - 1]?.time}</div>

              </div>
            </div>
          )
        })
      )
    }
  }

  // 此事件接收子对象
  childEvevnt = childDate => {
    this.$child = childDate;
  };

  render() {
    return (
      <Popup
        position="top"
        visible={this.state.messageVisible}
        bodyStyle={{ height: "100vh" }}
      >
        <div className="message_list">
          <div className="message_list_top">
            <div className="message_list_top_left">
              <LeftOutlined onClick={this.handleGoBack} />
            </div>
            <div className="message_list_top_right">{intl.get('XIAOXIZHONGXIN')}</div>
          </div>
          <div className="message_list_content">

            {
              this.diffContentList()
            }


          </div>

          <PhoneChatDetail messageDetailVisible={this.state.messageDetailVisible} chatDetail={this.state.chatDetail} childEvevnt={this.childEvevnt} closeDetail={this.getClose} />
        </div>
      </Popup>
    );
  }
}
