import * as actions from '../constants'
import login from '../api/login'


export function get_user(user) {
    return {
        type: actions.USERDATA,
        user
    }
}


export const getUserInfo =(state) => {
    return dispatch => {
     return  login.userlogin(state)       
    }
}