import intl from "react-intl-universal";
export function jobRecruitmentType() {
    return (
        [

            {
                baseComponent: intl.get("JICHUXINXI"),
                show: true,
                id: 1,
                data: [
                    {
                        type: "specialSelect",
                        name: "childCategory",
                        require: false,
                        requireMsg: 'please select',
                        label: intl.get("XINGZXHI"),
                        typeData: [],
                      },
                    {
                        type: "input",
                        name: "mainTitle",
                        require: true,
                        requireMsg: 'please input',
                        label: intl.get("BIAOTIE"),
                    },
                    {
                        type: "checkbox",
                        name: "workType",
                        require: true,
                        requireMsg: 'please select',
                        label: intl.get("GONGZUOXINGZHI"),
                        typeData: [
                            {
                                label: intl.get("QUANZHI"),
                                value: 1,
                            },
                            {
                                label: intl.get("JIANZHI"),
                                value: 2,
                            },
                            {
                                label: intl.get("Casual"),
                                value: 3,
                            },
                            {
                                label: intl.get("HETONGGONG"),
                                value: 4,
                            },
                            {
                                label: intl.get("SHIXI"),
                                value: 5,
                            },
                            {
                                label: intl.get("ZHIYUANZE"),
                                value: 6,
                            },
                        ],
                    },
                    {
                        type: "select",
                        name: "industry",
                        mutial: true,
                        require: true,
                        requireMsg: 'please select',
                        label: intl.get("SUOSHUXANGYE"),
                        typeData: [
                            {
                                label: intl.get("DICHANGHANGYE"),
                                value: 1,
                            },
                            {
                                label: intl.get("YILIAOWEISHENGHANGYE"),
                                value: 2,
                            },
                            {
                                label: intl.get("CHUANGYEHANGYE"),
                                value: 3,
                            },
                            {
                                label: intl.get("LVSHIHANGYE"),
                                value: 4,
                            },
                            {
                                label: intl.get("KUAIJIHANGYE"),
                                value: 5,
                            },
                            {
                                label: intl.get("CHAOSHI"),
                                value: 6,
                            },
                            {
                                label: intl.get("CANGYINGHANGYE"),
                                value: 7,
                            },
                            {
                                label: intl.get("LINGSHOUYE"),
                                value: 8,
                            },
                            {
                                label: intl.get("JIANZHUHANGYE"),
                                value: 9,
                            },
                            {
                                label: intl.get("LAONIANHULI"),
                                value: 10,
                            },
                            {
                                label: intl.get("YIMEIHANGYE"),
                                value: 11,
                            },
                            {
                                label: intl.get("JIAOYUHANGYE"),
                                value: 12,
                            },
                            {
                                label: intl.get("LVYOUHANGYE"),
                                value: 13,
                            },
                            {
                                label: intl.get("JIAZHANGHANGYE"),
                                value: 14,
                            },
                            {
                                label: intl.get("YULEHANGYE"),
                                value: 15,
                            },
                            {
                                label: intl.get("QITA"),
                                value: 16,
                            }
                        ]
                    },

                    {
                        type: "select",
                        name: "regionId",
                        mutial: true,
                        require: true,
                        requireMsg: 'please select',
                        label: intl.get("SUOZAIQUYU"),
                        typeData: [
                            {
                                label: intl.get("BULISB"),
                                value: 1,
                            },
                            {
                                label: intl.get("HUANGJIANHAN"),
                                value: 2,
                            },
                            {
                                label: intl.get("YANGHUANGHANHAN"),
                                value: 3,
                            },
                            {
                                label: intl.get("KAIENSI"),
                                value: 4,
                            }
                        ]
                    },

                    {
                        type: "input",
                        name: "companyName",
                        require: false,
                        requireMsg: 'please input',
                        label: intl.get("GONGSIMINGCHENG"),
                    },
                    {
                        type: "input",
                        name: "address",
                        require: false,
                        requireMsg: 'please input',
                        label: intl.get("DIZHI"),
                    },
                    {
                        type: "input",
                        name: "post",
                        require: false,
                        requireMsg: 'please input',
                        label: intl.get("ZHIEWEIMINGCHENG"),
                    },


                    {
                        type: "select",
                        name: "eduBack",
                        mutial: true,
                        require: true,
                        requireMsg: 'please select',
                        label: intl.get("XUELIYAOQIU"),
                        typeData: [
                            {
                                label: intl.get("BOSHI"),
                                value: 1,
                            },
                            {
                                label: intl.get("SHUOSHI"),
                                value: 2,
                            },
                            {
                                label: intl.get("BENKE"),
                                value: 3,
                            },
                            {
                                label: intl.get("GAOZHONG"),
                                value: 4,
                            },
                            {
                                label: intl.get("BUXIAN"),
                                value: 5,
                            }
                        ]
                    },

                    {
                        type: "radio",
                        name: "expType",
                        require: false,
                        requireMsg: 'please select',
                        label: intl.get("GONGZUOJINGYAN"),
                        typeData: [
                            {
                                label: intl.get("BUXIAN"),
                                value: 1,
                            },
                            {
                                label: intl.get("XUYOUJINGYAN"),
                                value: 2,
                            },
                        ],
                    },

                    {
                        type: "radio",
                        name: "gender",
                        require: false,
                        requireMsg: 'please select',
                        label: intl.get("XINGBIEYAOQIU"),
                        typeData: [
                            {
                                label: intl.get("BUXIAN"),
                                value: 1,
                            },
                            {
                                label: intl.get("NANXING"),
                                value: 2,
                            },
                            {
                                label: intl.get("NVXING"),
                                value: 3,
                            },
                        ],
                    },

                    {
                        type: "select",
                        name: "signType",
                        require: false,
                        mutial: true,
                        requireMsg: 'please select',
                        label: intl.get("SUOCHIQIANZHENG"),
                        typeData: [
                            {
                                label: intl.get("XUESHENGQIANZHENG"),
                                value: 1,
                            },
                            {
                                label: intl.get("GONGZUOQIANZHENG"),
                                value: 2,
                            },
                            {
                                label: intl.get("DAGONGDUJIAQIANZHENG"),
                                value: 3,
                            },
                            {
                                label: intl.get("YONGJUQIANZHENG"),
                                value: 4,
                            },
                            {
                                label: intl.get("AOZHOUGONGMING"),
                                value: 5,
                            },
                            {
                                label: intl.get("QITA"),
                                value: 6,
                            },
                        ]
                    }
                ]
            },


            {
                baseComponent: intl.get("DAIYUSHUIPING"),
                show: true,
                id: 2,
                data: [
                    {
                        type: "input",
                        name: "salary",
                        require: true,
                        requireMsg: 'please input',
                        label: intl.get("XINZISHUIPING"),
                    },

                    {
                        type: "radio",
                        name: "salaryType",
                        require: true,
                        requireMsg: 'please select',
                        label: intl.get("XINZILEIXING"),
                        typeData: [
                            {
                                label: intl.get("SHIXING"),
                                value: 1,
                            },
                            {
                                label: intl.get("NIANXING"),
                                value: 2,
                            },
                            {
                                label: intl.get("MIANYI"),
                                value: 3,
                            },
                        ],
                    },
                ]
            },


            {
                baseComponent: intl.get("XIANGQINGMIAOSHU"),
                show: true,
                id: 3,
                data: [
                    {
                        type: "edit",
                        name: "context",
                        require: true,
                        label: intl.get("XIANGQINGMIAOSHU"),
                    }
                ]
            },
            {
                baseComponent: intl.get("SHANGCHUANGTUPIAN"),
                show: true,
                id: 4,
                data: [
                    {
                        type: "upload",
                        name: "path",
                        require: false,
                        label: intl.get("SHANGCHUANGTUPIAN"),
                    }
                ]
            },

            {
                baseComponent: intl.get("LIANXIFANGSHI"),
                show: true,
                id: 5,
                data: [
                    {
                        type: "input",
                        name: "phone",
                        require: true,
                        requireMsg: 'please input',
                        label: intl.get("LIANXIDIANHUA"),
                    },
                    {
                        type: "input",
                        name: "email",
                        require: false,
                        label: intl.get("DIANZIYOUJIAN"),
                    },
                    {
                        type: "input",
                        name: "wechat",
                        require: false,
                        label: intl.get("WEIXIN"),
                    },
                    {
                        type: "input",
                        name: "line",
                        require: false,
                        label: intl.get("LINE"),
                    },
                    {
                        type: "input",
                        name: "facebook",
                        require: false,
                        label: intl.get("Facebook"),
                    },
                    {
                        type: "input",
                        name: "qq",
                        require: false,
                        label: intl.get("QQ"),
                    },
                ]
            }



        ]
    )
}