import { NAVDATA } from '../constants'

const initialState = {
    navdata: 1
}

const navdata = (state = initialState, action) => {
    switch(action.type) {
        case NAVDATA:
            return {
                navdata: action.navdata
            };
        default:
            return state
    }
}
export default navdata