import React from 'react'
import './LeftTool.css'

import intl from 'react-intl-universal';





export default class LeftTool extends React.Component {
    constructor() {
        super()
        this.state = {
        }
    }

    handleRouterTo = (val) => {
        switch (val) {
            case 1:
                this.props.history.push({ pathname: "/connectus" });
                break;
            case 2:
                this.props.history.push({ pathname: "/merchantsettled" });
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className='leftTool'>
                <div className="leftTool_item" onClick={this.handleRouterTo.bind(this, 1)}>
                    <div className="leftTool_item_img"></div>
                    <div className="leftTool_item_title">{intl.get('LIANXIWOMEN')}</div>
                </div>

                <div className="leftTool_item" onClick={this.handleRouterTo.bind(this, 2)}>
                    <div className="leftTool_item_img"></div>
                    <div className="leftTool_item_title">{intl.get('SHANGJAIRUZHU')}</div>
                </div>

            </div>
        )
    }
}


