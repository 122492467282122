import React from 'react'
import './Banner.css'
import { Button, message } from 'antd';

import WebSearch from '../WebSearch/WebSearch'

import intl from 'react-intl-universal';
import forum from '../../api/bbs';
import merchantsInfo from '../../api/yellow'




export default class Banner extends React.Component {
    constructor() {
        super()
        this.state = {
            serachResult: '',
            parentOptions: []
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
        console.log('this.props.history', this.props.history)
    }

    // 此事件接收子对象
    childEvevnt = childDate => {
        this.$child = childDate;
    };


    getThrottle = (e) => {

        switch (this.props?.type) {
            case '1':
                merchantsInfo.merchantsInfoListWeb({
                    merchantsName: e,
                    pageSize: 10,
                    pageNum: 1
                })
                    .then(res => res.json())
                    .then(result => {
                        console.log('result3', result)
                        if (result.ret === 200) {
                            this.setState({
                                parentOptions: result.data.list.map((item) => {
                                    return {
                                        value: item.merchantsName,
                                        label: item.merchantsName,
                                    }
                                })
                            }, () => {
                                this.$child.sendChild()
                            })
                        }
                    })
                break;
            case '2':
                forum.forumListWeb({
                    mainTitle: e,
                    pageSize: 10,
                    pageNum: 1
                })
                    .then(res => res.json())
                    .then(result => {
                        console.log('result3', result)
                        if (result.ret === 200) {
                            this.setState({
                                parentOptions: result.data.list.map(item => {
                                    return {
                                        value: item.mainTitle,
                                        label: item.mainTitle,
                                    }
                                })
                            }, () => {
                                this.$child.sendChild()
                            })
                        }
                    })
                break;
            default:
                break;
        }
    }

    getResult = (e) => {
        console.log('e', e)
        this.setState({
            serachResult: e
        }, () => {
            this.props.setBannerSearch()
        })
    }

    getChangeResult = (e) => {
        console.log('e', e)

        this.setState({
            serachResult: e
        })
    }

    // 搜索
    handleSearch = () => {
        
        this.props.setBannerSearch()
    }

    handleGoMain = () => {
        this.props.history.push({ pathname: "/main" });
    }

    shopRoute = () => {
        switch (this.props?.type) {
            case '1':
                this.props.history.push({ pathname: "/merchantsettled" });
                break;
            case '2':
                if (!JSON.parse(sessionStorage.getItem("USERDATA"))) {
                    message.warning(intl.get('QINGXIANDENGLU'))
                    return
                }
                this.props.history.push({ pathname: "/bbspagenew" });
                break;
            default:
                break;
        }

    }

    diffDiffent = () => {
        switch (this.props?.type) {
            case '1':
                return (
                    <Button size="large" className="banner_bottom_button_shop" onClick={this.shopRoute}>{intl.get('SHANGJAIRUZHU')}</Button>
                )
            case '2':
                return (
                    <Button size="large" className="banner_bottom_button_shop" onClick={this.shopRoute}>{intl.get('FABIAOXINTIE')}</Button>
                )
            default:
                break;
        }
    }

    diffPic = () => {
        switch (this.props?.type) {
            case '1':
                return (
                    <div className="banner_top_right"></div>

                )
            case '2':
                return (
                    <div className="banner_top_right2"></div>
                )
            default:
                break;
        }
    }




    render() {
        return (
            <div className="banner">
                <div className="banner_top" onClick={this.handleGoMain}>
                    <div className="banner_top_left"></div>
                    {
                        this.diffPic()
                    }
                </div>
                <div className="banner_bottom">
                    <div className="banner_bottom_search">
                        <WebSearch history={this.props.history} searchResult={this.getResult} changeResult={this.getChangeResult} autoOptions={this.state.parentOptions} setThrottle={this.getThrottle} childEvevnt={this.childEvevnt} />
                    </div>
                    <div className="banner_bottom_button">
                        <Button size="large" type="primary" onClick={this.handleSearch}>{intl.get('DIANJISOUSUO')}</Button>
                        {
                            this.diffDiffent()
                        }
                    </div>
                </div>
            </div>
        )
    }
}


