import intl from "react-intl-universal";
import { secondHandFormType } from '../BbsForm/secondHandData'
import { businessTransferType } from '../BbsForm/businessTransfer'
import { homeRentType } from '../BbsForm/homeRent'
import { jobRecruitmentType } from '../BbsForm/jobRecruitment'
// import { nomalType } from '../BbsForm/nomalData'



function cutNull(val) {
    if ((val ?? '') === '') {
        return false
    }

    return true
}

export function BbsFilterFun(data) {
    if (!data) {
        return
    }
    switch (data.categoryId) {
        case 11:
            return (
                // 二手市场
                <div className="bbs_detail_list_item_bottom_right_content">


                    {
                        cutNull(data.productName) ? <>{intl.get("SHANGPING")}: {data.productName} <br /> </> : ''
                    }
                    {
                        cutNull(data.subTitle) ? <>  {intl.get("FUBIAOTIE")}：{data.subTitle}<br /></> : ''
                    }

                    {
                        cutNull(data.sellerType) ? <>{intl.get("FATIELEIXING")}:  {data.sellerType ? secondHandFormType()[0].data[2].typeData.filter(item =>
                            item.value === data.sellerType
                        )[0].label : ''} <br /> </> : ''
                    }



                    {
                        cutNull(data.dealType) ? <>{intl.get("JIAOYILEIXING")}: {data.dealType ? secondHandFormType()[0].data[3].typeData.filter(item =>
                            item.value === data.dealType
                        )[0].label : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.productType) ? <>{intl.get("SHANGPIINGFENLEI")}： {data.productType ? data.productType.split(',').map(item => {
                            return (secondHandFormType()[0].data[4].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.newOrOld) ? <>{intl.get("XINJIUCHENGDU")}: {data.newOrOld ? secondHandFormType()[0].data[5].typeData.filter(item =>
                            item.value === data.newOrOld
                        )[0].label : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.regionId) ? <>{intl.get("SUOZAIQUYU")}： {data.regionId ? data.regionId.split(',').map(item => {
                            return (secondHandFormType()[0].data[6].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.price) ? <> {intl.get("JIAGE")}：{data.price}<br /></> : ''
                    }
                    {/* {intl.get("SUOZAIQUYU")}： {data.regionId ? data.regionId.split(',').map(item => {
                        return (secondHandFormType()[0].data[6].typeData.filter(item2 => {
                            return Number(item) === item2.value
                        })[0].label)
                    }).join(',') : ''}<br /> */}



                    {
                        cutNull(data.sendType) ? <>{intl.get("SONGHUOFANGSI")}：{data.sendType ? secondHandFormType()[0].data[8].typeData.filter(item =>
                            item.value === data.sendType
                        )[0].label : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.phone) ? <>{intl.get("LIANXIDIANHUA")}：{data.phone}<br /> </> : ''
                    }


                    {
                        cutNull(data.email) ? <>{intl.get("DIANZIYOUJIAN")}：{data.email}<br /> </> : ''
                    }

                    {
                        cutNull(data.wechat) ? <>{intl.get("WEIXIN")}：{data.wechat}<br /> </> : ''
                    }

                    {
                        cutNull(data.line) ? <>{intl.get("LINE")}：{data.line}<br /> </> : ''
                    }

                    {
                        cutNull(data.facebook) ? <> {intl.get("Facebook")}：{data.facebook}<br /></> : ''
                    }

                    {
                        cutNull(data.qq) ? <>{intl.get("QQ")}：{data.qq}<br /> </> : ''
                    }
                </div>
            );
        case 9:
            return (
                // 生意转让
                <div className="bbs_detail_list_item_bottom_right_content">

                    {
                        cutNull(data.mainTitle) ? <> {intl.get("BIAOTIE")}: {data.mainTitle} <br /> </> : ''
                    }

                    {
                        cutNull(data.subTitle) ? <>  {intl.get("FUBIAOTIE")}：{data.subTitle}<br /></> : ''
                    }
                    {
                        cutNull(data.sellerType) ? <> {intl.get("FATIELEIXING")}: {data.sellerType ? businessTransferType()[0].data[2].typeData.filter(item =>
                            item.value === data.sellerType
                        )[0].label : ''} <br /> </> : ''
                    }

                    {
                        cutNull(data.serviceType) ? <>{intl.get("FUWULEIXING")}： {data.serviceType ? data.serviceType.split(',').map(item => {
                            return (businessTransferType()[0].data[3].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }

                    {
                        cutNull(data.regionId) ? <>{intl.get("SUOZAIQUYU")}： {data.regionId ? data.regionId.split(',').map(item => {
                            return (secondHandFormType()[0].data[6].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }

                    {
                        cutNull(data.industry) ? <>{intl.get("HANGYE")}： {data.industry ? data.industry.split(',').map(item => {
                            return (businessTransferType()[0].data[5].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }

                    {
                        cutNull(data.companyName) ? <> {intl.get("GONGSIMINGCHENG")}：{data.companyName}<br /> </> : ''
                    }

                    {
                        cutNull(data.address) ? <>{intl.get("DIZHI")}：{data.address}<br /> </> : ''
                    }

                    {
                        cutNull(data.price) ? <> {intl.get("JIAGE")}：{data.price}<br /> </> : ''
                    }

                    {
                        cutNull(data.phone) ? <>{intl.get("LIANXIDIANHUA")}：{data.phone}<br /> </> : ''
                    }

                    {
                        cutNull(data.email) ? <>{intl.get("DIANZIYOUJIAN")}：{data.email}<br /> </> : ''
                    }
                    {
                        cutNull(data.wechat) ? <>{intl.get("WEIXIN")}：{data.wechat}<br /> </> : ''
                    }
                    {
                        cutNull(data.line) ? <>{intl.get("LINE")}：{data.line}<br /> </> : ''
                    }
                    {
                        cutNull(data.facebook) ? <>{intl.get("Facebook")}：{data.facebook}<br /> </> : ''
                    }
                    {
                        cutNull(data.qq) ? <>{intl.get("QQ")}：{data.qq}<br /> </> : ''
                    }
                </div>
            )

        case 7:
            // 房屋租赁
            return (
                <div className="bbs_detail_list_item_bottom_right_content">


                    {
                        cutNull(data.mainTitle) ? <> {intl.get("BIAOTIE")}: {data.mainTitle} <br /> </> : ''
                    }
                    {
                        cutNull(data.subTitle) ? <>  {intl.get("FUBIAOTIE")}：{data.subTitle}<br /></> : ''
                    }

                    {
                        cutNull(data.sellerType) ? <>{intl.get("FATIELEIXING")}:  {data.sellerType ? homeRentType()[0].data[2].typeData.filter(item =>
                            item.value === data.sellerType
                        )[0].label : ''} <br /> </> : ''
                    }

                    {
                        cutNull(data.letOutType) ? <>{intl.get("CHUZHUFANGSHI")}:  {data.letOutType ? homeRentType()[0].data[3].typeData.filter(item =>
                            item.value === data.letOutType
                        )[0].label : ''} <br /> </> : ''
                    }

                    {
                        cutNull(data.rentMoney) ? <> {intl.get("FNAGWUZUJIN")}：{data.rentMoney}<br /> </> : ''
                    }

                    {
                        cutNull(data.checkInTime) ? <> {intl.get("RUZHUSHIJIAN")}:  {data.checkInTime ? homeRentType()[0].data[5].typeData.filter(item =>
                            item.value === Number(data.checkInTime)
                        )[0].label : ''} <br /> </> : ''
                    }

                    {
                        cutNull(data.rentTime) ? <> {intl.get("ZUIDUANZUQI")}：{data.rentTime}<br /> </> : ''
                    }



                    {
                        cutNull(data.roomType) ? <> {intl.get("FANGWUHUXING")}： {data.roomType ? data.roomType.split(',').map(item => {
                            return (homeRentType()[0].data[7].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }

                    {
                        cutNull(data.regionId) ? <>{intl.get("SUOZAIQUYU")}： {data.regionId ? data.regionId.split(',').map(item => {
                            return (secondHandFormType()[0].data[6].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.address) ? <> {intl.get("DIZHI")}：{data.address}<br /></> : ''
                    }


                    {
                        cutNull(data.roomConfig) ? <> {intl.get("FANGWUPEIZHI")}： {data.roomConfig ? data.roomConfig.split(',').map(item => {
                            return (homeRentType()[0].data[10].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br />
                        </> : ''
                    }

                    {
                        cutNull(data.nearConfig) ? <>{intl.get("FUJINSESHI")}： {data.nearConfig ? data.nearConfig.split(',').map(item => {
                            return (homeRentType()[0].data[11].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.phone) ? <> {intl.get("LIANXIDIANHUA")}：{data.phone}<br /></> : ''
                    }

                    {
                        cutNull(data.email) ? <>{intl.get("DIANZIYOUJIAN")}：{data.email}<br /> </> : ''
                    }
                    {
                        cutNull(data.wechat) ? <> {intl.get("WEIXIN")}：{data.wechat}<br /></> : ''
                    }
                    {
                        cutNull(data.line) ? <> {intl.get("LINE")}：{data.line}<br /></> : ''
                    }
                    {
                        cutNull(data.facebook) ? <> {intl.get("Facebook")}：{data.facebook}<br /> </> : ''
                    }
                    {
                        cutNull(data.qq) ? <>{intl.get("QQ")}：{data.qq}<br /> </> : ''
                    }


                </div>
            )

        case 6:
            // 求职招聘
            return (
                <div className="bbs_detail_list_item_bottom_right_content">
                    {
                        cutNull(data.mainTitle) ? <>{intl.get("BIAOTIE")}: {data.mainTitle} <br /></> : ''
                    }

                    {
                        cutNull(data.subTitle) ? <>  {intl.get("FUBIAOTIE")}：{data.subTitle}<br /></> : ''
                    }

                     
                    {
                        cutNull(data.workType) ? <> {intl.get("GONGZUOXINGZHI")}： {data.workType ? data.workType.split(',').map(item => {
                            return (jobRecruitmentType()[0].data[2].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br />
                        </> : ''
                    }

                    {
                        cutNull(data.workType) ? <> </> : ''
                    }

                    {
                        cutNull(data.industry) ? <>{intl.get("SUOSHUXANGYE")}： {data.industry ? data.industry.split(',').map(item => {
                            return (jobRecruitmentType()[0].data[3].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.regionId) ? <>{intl.get("SUOZAIQUYU")}： {data.regionId ? data.regionId.split(',').map(item => {
                            return (secondHandFormType()[0].data[6].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.companyName) ? <>{intl.get("GONGSIMINGCHENG")}: {data.companyName} <br /> </> : ''
                    }

                    {
                        cutNull(data.address) ? <> {intl.get("DIZHI")}: {data.address} <br /></> : ''
                    }


                    {
                        cutNull(data.post) ? <>{intl.get("ZHIEWEIMINGCHENG")}: {data.post} <br /> </> : ''
                    }


                    {
                        cutNull(data.salary) ? <>{intl.get("XINZISHUIPING")}: {data.salary} <br /> </> : ''
                    }

                    {
                        cutNull(data.salaryType) ? <>{intl.get("XINZILEIXING")}：{data.salaryType ? jobRecruitmentType()[1].data[1].typeData.filter(item =>
                            item.value === data.salaryType
                        )[0].label : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.eduBack) ? <>{intl.get("XUELIYAOQIU")}： {data.eduBack ? data.eduBack.split(',').map(item => {
                            return (jobRecruitmentType()[0].data[8].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.expType) ? <>  {intl.get("GONGZUOJINGYAN")}：{data.expType ? jobRecruitmentType()[0].data[9].typeData.filter(item =>
                            item.value === data.expType
                        )[0].label : ''}<br /></> : ''
                    }

                    {
                        cutNull(data.gender) ? <>{intl.get("XINGBIEYAOQIU")}：{data.gender ? jobRecruitmentType()[0].data[10].typeData.filter(item =>
                            item.value === data.gender
                        )[0].label : ''}<br /> </> : ''
                    }


                    {
                        cutNull(data.signType) ? <>  {intl.get("SUOCHIQIANZHENG")}： {data.signType ? data.signType.split(',').map(item => {
                            return (jobRecruitmentType()[0].data[11].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }



                    {
                        cutNull(data.phone) ? <> {intl.get("LIANXIDIANHUA")}：{data.phone}<br /></> : ''
                    }




                    {
                        cutNull(data.email) ? <>{intl.get("DIANZIYOUJIAN")}：{data.email}<br /> </> : ''
                    }


                    {
                        cutNull(data.wechat) ? <>{intl.get("WEIXIN")}：{data.wechat}<br /> </> : ''
                    }


                    {
                        cutNull(data.line) ? <> {intl.get("LINE")}：{data.line}<br /></> : ''
                    }


                    {
                        cutNull(data.facebook) ? <>{intl.get("Facebook")}：{data.facebook}<br /> </> : ''
                    }

                    {
                        cutNull(data.qq) ? <>{intl.get("QQ")}：{data.qq}<br /> </> : ''
                    }



                </div >

            )
        default:
            // 其他
            return (
                <div className="bbs_detail_list_item_bottom_right_content">

                    {
                        cutNull(data.mainTitle) ? <>{intl.get("BIAOTIE")}: {data.mainTitle} <br /> </> : ''
                    }
                    {
                        cutNull(data.subTitle) ? <>  {intl.get("FUBIAOTIE")}：{data.subTitle}<br /></> : ''
                    }


                    {
                        cutNull(data.regionId) ? <>{intl.get("SUOZAIQUYU")}： {data.regionId ? data.regionId.split(',').map(item => {
                            return (secondHandFormType()[0].data[6].typeData.filter(item2 => {
                                return Number(item) === item2.value
                            })[0].label)
                        }).join(',') : ''}<br /> </> : ''
                    }

                    {
                        cutNull(data.phone) ? <>{intl.get("LIANXIDIANHUA")}：{data.phone}<br /> </> : ''
                    }
                    {
                        cutNull(data.email) ? <> {intl.get("DIANZIYOUJIAN")}：{data.email}<br /> </> : ''
                    }
                    {
                        cutNull(data.wechat) ? <>{intl.get("WEIXIN")}：{data.wechat}<br /> </> : ''
                    }

                    {
                        cutNull(data.line) ? <>{intl.get("LINE")}：{data.line}<br /> </> : ''
                    }
                    {
                        cutNull(data.facebook) ? <>{intl.get("Facebook")}：{data.facebook}<br /> </> : ''
                    }

                    {
                        cutNull(data.qq) ? <>{intl.get("QQ")}：{data.qq}<br /> </> : ''
                    }








                </div >

            )
                ;
    }
}