import { LANG } from '../constants'

const initialState = {
    lang: 1
}

const lang = (state = initialState, action) => {
    switch(action.type) {
        case LANG:
            return {
                lang: action.lang
            };
        default:
            return state
    }
}
export default lang