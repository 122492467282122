import React from 'react'
import { Input, notification, Button } from 'antd';
import Nav from '../../component/nav/nav'
import GoogleMapReact from 'google-map-react';
import { LeftOutlined } from '@ant-design/icons';

import Foot from '../../component/Foot/Foot'
import intl from 'react-intl-universal';
import connect from '../../api/connnect'
import './components/connectus.css'




const { TextArea } = Input;

const getInfoWindowString = () => `
    <div>
      <div style="font-size: 16px;padding: 10px">
      邮箱/Email: news@acnews.com.au
      </div>
      <div style="font-size: 14px;padding: 10px">
        <span style="color: grey;">
        电话/Phone:  (07)3323 3535 & 0421 217 268
        </span>
      </div>
      <div style="font-size: 14px; color: grey;padding: 10px">
      地址/Address: 18 Selvage St, Sunnybank QLD 4109
      </div>
    </div>`;

const handleApiLoaded = (map, maps, places) => {
    const markers = [];
    const infowindows = [];


    markers.push(new maps.Marker({
        position: {
            lat: -27.579028095112353,
            lng: 153.06314305641257,
        },
        map,
    }));

    infowindows.push(new maps.InfoWindow({
        content: getInfoWindowString(),
    }));


    markers.forEach((marker, i) => {
        infowindows[i].open(map, marker);
    });
};
export default class Connectus extends React.Component {
    constructor() {
        super()
        this.state = {
            center: {
                lat: -27.579028095112353,
                lng: 153.06314305641257
            },
            zoom: 17,
            userName: '',
            email: '',
            phone: '',
            content: '',
            buttonloading: false,
            context: '',
            context2: '',
            context3: "",
            title: '发新帖'
        }
    }
    componentDidMount() {
        console.log('========>', sessionStorage['language'])

        this.getpagedata()
    }

    getpagedata = () => {
        const parms = {
            columnId: 6,
            pageSize: 10,
            language: sessionStorage['language'],
            pageNum: 1
        }
        connect.webBodyForWeb(parms)
            .then(res => res.json())
            .then(result => {
                console.log('result121212', result)
                if (result.ret === 200 && result.data.list.length !== 0) {
                    this.setState({
                        context: result.data.list[0].context,
                        context2: result.data.list[0].context2,
                        context3: result.data.list[0].context3,

                        title: result.data.list[0].mainTitle
                    })
                }
            })
    }

    diffContent = () => {
        switch (sessionStorage['language']) {
            case '1':
                return (
                    <div className="why_choose_content" dangerouslySetInnerHTML={{ __html: this.state.context }}></div>

                )
            case '2':
                return (
                    <div className="why_choose_content" dangerouslySetInnerHTML={{ __html: this.state.context2 }}></div>
                )
            case '3':
                return (
                    <div className="why_choose_content" dangerouslySetInnerHTML={{ __html: this.state.context3 }}></div>
                )
            default:
                break
        }
    }

    handleChangeUserName = (e) => {
        this.setState({
            userName: e.target.value
        })
    }


    handleChangeEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    handleChangePhone = (e) => {
        this.setState({
            phone: e.target.value
        })
    }

    handleChangeContent = (e) => {
        this.setState({
            content: e.target.value
        })
    }

    handlesenddata = () => {
        this.setState({
            buttonloading: true
        })
        // eslint-disable-next-line no-useless-escape
        if (!(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.state.email))) {
            notification['warning']({
                message: intl.get('YOUXIANGCUOWU'),
                description:
                    intl.get('YOUXIANGCUOWU'),
            });
            this.setState({
                buttonloading: false
            })
            return false
        }

        if(!this.state.phone) {
            notification['warning']({
                message: intl.get('SHOUJIHAOBUNENGWEIKONG'),
                description:
                    intl.get('SHOUJIHAOBUNENGWEIKONG'),
            });
            this.setState({
                buttonloading: false
            })
            return false
        }


        let parms = {
            // email: 'esoqld.com.au@gmail.com',
            email: 'news@acnews.com.au',
            context: ` ${intl.get('XINGMIMIGHN')}: ${this.state.userName}
            ${intl.get('YOUXIANG')}: ${this.state.email}
            ${intl.get('SHOUJIHAOMA')}: ${this.state.phone}
            ${intl.get('XUQIUNEIRONG')}: ${this.state.content}
            `
        }

        console.log('parms', parms)

        connect.sendContent(parms)
            .then(res => res.json())
            .then(result => {
                if (result.ret === 200) {
                    this.setState({
                        userName: '',
                        email: '',
                        content: ''
                    })
                    notification['success']({
                        message: intl.get('FASONGCHENGG'),
                        description:
                            intl.get('FASONGCHENGG'),
                    });
                }
                this.setState({
                    buttonloading: false
                })
            })
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    render() {
        document.title = "EQLD昆士兰易搜-专注服务昆州本地社区";
        document.querySelector('meta[property="og:title"]').setAttribute('content', 'EQLD昆士兰易搜');
        document.querySelector('meta[property="og:description"]').setAttribute('content', '专注服务昆州本地社区');
        document.querySelector('meta[itemprop="description"]').setAttribute('content', '专注服务昆州本地社区');
        document.querySelector('meta[property="og:type"]').setAttribute('content', 'website');
        document.querySelector('meta[property="og:image"]').setAttribute('content', 'http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png');
        document.querySelector('meta[itemprop="image"]').setAttribute('content', 'http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png');
        document.querySelector('meta[itemprop="name"]').setAttribute('content', '专注服务昆州本地社区');

        return (
            <>
                <div className="main_content">
                    <Nav history={this.props.history} props={this.props} />
                    <div className="connet_us">
                        <div className="connet_us_title">{intl.get('LIANXIWOMEN')}</div>
                        <div className="connet_us_subtitle">{intl.get('RUXUDINGGOUHUOCHAS')}</div>
                        <div className="connet_us_input_item">
                            <Input placeholder={intl.get('XINGMIMIGHN')} value={this.state.userName} onChange={this.handleChangeUserName} className="connet_us_input" />

                        </div>

                        <div className="connet_us_input_item">
                            <Input placeholder={intl.get('SHOUJIHAOMA')} value={this.state.phone} onChange={this.handleChangePhone} className="connet_us_input" />
                        </div>

                        <div className="connet_us_input_item">
                            <Input placeholder={intl.get('YOUXIANG')} value={this.state.email} onChange={this.handleChangeEmail} className="connet_us_input" />
                        </div>
                        <div className="connet_us_input_item_textArea">
                            <TextArea placeholder={intl.get('XUQIUNEIRONG')} value={this.state.content} onChange={this.handleChangeContent} className="connet_us_input_item_textArea" autoSize={{ minRows: 8, maxRows: 8 }} />
                        </div>
                        <div className="connect_us_btn">
                            <Button loading={this.state.buttonloading} style={{ background: '#6acccd', borderColor: "#6acccd", color: '#fff' }} onClick={this.handlesenddata}>
                                {intl.get('TIJIAO')}
                            </Button>
                        </div>
                    </div>

                    <div className="middle_content">
                        <div className="why_choose">
                            <div className="why_choose_title">{this.state.title}</div>
                        </div>
                        {
                            this.diffContent()
                        }



                    </div>


                    <div className="connnect_us_map">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyB37jXlk9_-rcuRxc5YBB3qmLeKCl70UGU' }}
                            defaultCenter={this.state.center}
                            defaultZoom={this.state.zoom}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        >
                        </GoogleMapReact>
                    </div>

                    <div className="connect_type">
                        <div className="connect_type_item">
                            <div className="connect_type_item_img"></div>
                            <div className="connect_type_item_title"></div>
                            <div className="connect_type_item_content"><a href='tel:0733233535' style={{color: '#3fd5d3'}}>0733233535</a> {"&"} <a href='tel:0421217268' style={{color: '#3fd5d3'}}>0421217268</a></div>
                        </div>

                        <div className="connect_type_item">
                            <div className="connect_type_item_img"></div>
                            <div className="connect_type_item_title">{intl.get('DIANZIYOUJIAN')}</div>
                            <div className="connect_type_item_content">news@acnews.com.au</div>
                        </div>

                        <div className="connect_type_item">
                            <div className="connect_type_item_img"></div>
                            <div className="connect_type_item_title">{intl.get('DIZHI')}</div>
                            <div className="connect_type_item_content">18 Selvage St, Sunnybank QLD 4109</div>
                        </div>
                    </div>

                    <Foot history={this.props.history} />
                </div>
                {/* 移动端==============================================================================================================================================================================================================================> */}
                <div className="phone_content bbs_phone_content">
                    <div className="connect_top">
                        <div className="phone_person_top_first">
                            <LeftOutlined style={{ color: "#000", fontSize: '15px' }} onClick={this.handleBack} />
                        </div>
                    </div>
                    <div className="connet_us">
                        <div className="connet_us_title">{intl.get('LIANXIWOMEN')}</div>
                        <div className="connet_us_subtitle">{intl.get('RUXUDINGGOUHUOCHAS')}</div>
                        <div className="connet_us_input_item">
                            <Input placeholder={intl.get('XINGMIMIGHN')} value={this.state.userName} onChange={this.handleChangeUserName} className="connet_us_input" />

                        </div>

                        <div className="connet_us_input_item">
                            <Input placeholder={intl.get('SHOUJIHAOMA')} value={this.state.phone} onChange={this.handleChangePhone} className="connet_us_input" />
                        </div>

                        <div className="connet_us_input_item">
                            <Input placeholder={intl.get('YOUXIANG')} value={this.state.email} onChange={this.handleChangeEmail} className="connet_us_input" />
                        </div>
                        <div className="connet_us_input_item_textArea">
                            <TextArea placeholder={intl.get('XUQIUNEIRONG')} value={this.state.content} onChange={this.handleChangeContent} className="connet_us_input_item_textArea" autoSize={{ minRows: 4, maxRows: 4 }} />
                        </div>
                        <div className="connect_us_btn">
                            <Button loading={this.state.buttonloading} style={{ background: '#6acccd', borderColor: "#6acccd", color: '#fff' }} onClick={this.handlesenddata}>
                                {intl.get('TIJIAO')}
                            </Button>
                        </div>
                    </div>
                    <div className="middle_content">
                        <div className="why_choose">
                            <div className="why_choose_title">{this.state.title}</div>
                        </div>
                        {
                            this.diffContent()
                        }
                    </div>
                    <div className="connnect_us_map">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyB37jXlk9_-rcuRxc5YBB3qmLeKCl70UGU' }}
                            defaultCenter={this.state.center}
                            defaultZoom={this.state.zoom}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        >
                        </GoogleMapReact>
                    </div>

                    <div className="connect_type">
                        <div className="connect_type_item">
                            <div className="connect_type_item_img"></div>
                            <div className="connect_type_item_title"></div>
                            <div className="connect_type_item_content"><a href='tel:0733233535' style={{color: '#3fd5d3'}}>0733233535</a> {"&"} <a href='tel:0421217268' style={{color: '#3fd5d3'}}>0421217268</a></div>
                        </div>

                        <div className="connect_type_item">
                            <div className="connect_type_item_img"></div>
                            <div className="connect_type_item_title">{intl.get('DIANZIYOUJIAN')}</div>
                            <div className="connect_type_item_content">news@acnews.com.au</div>
                        </div>

                        <div className="connect_type_item">
                            <div className="connect_type_item_img"></div>
                            <div className="connect_type_item_title">{intl.get('DIZHI')}</div>
                            <div className="connect_type_item_content">18 Selvage St, Sunnybank QLD 4109</div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


}