import { httpPost, googleGet } from '../utils/request'


const merchantsInfo = {
    // 店铺列表
    merchantsInfoListWeb(parms) {
        const url = '/website/merchantsInfo/merchantsInfoListWeb'
        let query = {}
        if (parms.merchantsName) {
            query.merchantsName = parms.merchantsName
        }
        if (parms.merchantsType) {
            query.merchantsType = parms.merchantsType
        }

        if (parms.firstCategory) {
            query.firstCategory = parms.firstCategory
        }

        if (parms.phone) {
            query.phone = parms.phone
        }
        if (parms.language) {
            query.language = parms.language
        }

        if (parms.stat) {
            query.stat = parms.stat
        }

        if (parms.sort) {
            query.sort = parms.sort
        }
        if (parms.stars) {
            query.stars = parms.stars
        }
        if (parms.isHot) {
            query.isHot = parms.isHot
        }
        if (parms.regionId) {
            query.regionId = parms.regionId
        }
        if (parms.customerId) {
            query.customerId = parms.customerId
        }

        query.stat = 1
        query.endStat = 1
        query.pageSize = parms.pageSize
        query.pageNum = parms.pageNum
        return httpPost(url, query)
    },

    // 店铺详情
    merchantsInfoDetailWeb(parms) {
        const url = '/website/merchantsInfo/merchantsInfoDetailWeb'
        let query = {}
        if (parms.merchantsInfoId) {
            query.merchantsInfoId = parms.merchantsInfoId
        }

        if (parms.customerId) {
            query.customerId = parms.customerId
        }

        if (parms.phone) {
            query.phone = parms.phone
        }

        if (parms.language) {
            query.language = parms.language
        }
        return httpPost(url, query)
    },

    // 店铺收藏
    addMerchantsCollect(parms, token) {
        const url = '/website/merchantsInfo/addMerchantsCollect'
        let query = {}
        if (parms.merchantsId) {
            query.merchantsId = parms.merchantsId
        }

        if (parms.customerId) {
            query.customerId = parms.customerId
        }
        return httpPost(url, query, token)
    },
    // 取消店铺收藏
    cancelMerchantsCollect(parms, token) {
        const url = '/website/merchantsInfo/cancelMerchantsCollect'
        let query = {}
        if (parms.merchantsId) {
            query.merchantsId = parms.merchantsId
        }

        if (parms.customerId) {
            query.customerId = parms.customerId
        }
        return httpPost(url, query, token)
    },

    // 通过店铺id查询绑定的商家客户
    customerList(parms, token) {
        const url = '/website/customer/customerList'
        let query = {}
        if (parms.merchantsId) {
            query.merchantsId = parms.merchantsId
        }
        return httpPost(url, query, token)
    },

    // 通过谷歌地图把地址转为经纬度
    getLocationByAddress(parms) {
        return googleGet(parms)
    },


    // 获取广告
     advertisementList(parms, token) {
        let query = {}
        if (parms.type) {
            query.type = parms.type
        }
        const url = '/website/advertisement/advertisementList'
        return httpPost(url, query, token)
    },
}


export default merchantsInfo