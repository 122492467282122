import React from "react";
import "./Foot.css";
import Icon from '@ant-design/icons';
import intl from "react-intl-universal";
import { message } from 'antd';
import { ReactComponent as facebook } from '../../icons/footicons/facebook.svg';
import { ReactComponent as ins } from '../../icons/footicons/ins.svg';
import { ReactComponent as twitter } from '../../icons/footicons/twitter.svg';
import { ReactComponent as youtube } from '../../icons/footicons/youtube.svg';



export default class Foot extends React.Component {
  handlegotopage = (e) => {
    console.log("e", e);
    switch (e) {
      case 1:
        console.log(1212);

        this.props.history.push({ pathname: "/yellowpages" });
        break;
      case 2:

        this.props.history.push({ pathname: "/bbspages" });
        break;
      case 3:
        if (!JSON.parse(sessionStorage.getItem("USERDATA"))) {
          message.warning(intl.get('QINGXIANDENGLU'))
          return false
        }
        this.props.history.push({ pathname: "/myplace" });
        break;
      case 4:
        this.props.history.push({ pathname: "/merchantsettled" });
        break;
      default:
        this.props.history.push({ pathname: "/main" });
        break;
    }
  };

  handlegoOtherSMS = (e) => {
    switch (e) {
      case 1:
        window.open('https://www.facebook.com/ACNEWSBNE')
        break;
      case 3:
        window.open('https://twitter.com/ACNews_QLD?s=20')
        break
      case 4:
        window.open('https://www.youtube.com/channel/UCcxLW6-B7RtkLyA700LN6Nw')
        break;
      default:
        break
    }
  }
  render() {
    return (
      <div className="foot">
        <div className="foot_content">
          <div className="foot_logo"></div>
          <div className="foot_menu">
            <div className="foot_menu_item customer_hover" onClick={this.handlegotopage.bind(this, 1)}>
              {intl.get('YISOUHUANGYE')}
            </div>
            <div className="foot_menu_item_line"></div>

            <div className="foot_menu_item customer_hover" onClick={this.handlegotopage.bind(this, 2)}>
              {intl.get('YISHOULUNTAN')}
            </div>

            <div className="foot_menu_item_line"></div>

            <div className="foot_menu_item customer_hover" onClick={this.handlegotopage.bind(this, 3)}>
              {intl.get('WODEYISOU')}
            </div>

            <div className="foot_menu_item_line"></div>

            <div className="foot_menu_item customer_hover" onClick={this.handlegotopage.bind(this, 4)}>
              {intl.get('SHANGJIARUZHU')}
            </div>
          </div>
          <div className="foot_line"></div>
          <div className="foot_link">
            <div className="foot_connect_item" onClick={this.handlegoOtherSMS.bind(this, 1)}>
              <Icon component={facebook} style={{ color: '#3fd5d3', fontSize: '0.32rem' }} />
            </div>
            {/* <div className="foot_connect_item" onClick={this.handlegoOtherSMS.bind(this, 2)}>
              <Icon component={ins} style={{ color: '#3fd5d3', fontSize: '0.32rem' }} />

            </div> */}
            <div className="foot_connect_item" onClick={this.handlegoOtherSMS.bind(this, 3)}>
              <Icon component={twitter} style={{ color: '#3fd5d3', fontSize: '0.32rem' }} />

            </div>
            <div className="foot_connect_item" onClick={this.handlegoOtherSMS.bind(this, 4)}>
              <Icon component={youtube} style={{ color: '#3fd5d3', fontSize: '0.32rem' }} />

            </div>
          </div>
          <div className="foot_connect">
            <div className="foot_connect_us">
              {intl.get('LIANXIWOMEN')}
            </div>
            <div className="foot_connect_email">
              {intl.get('YOUXIANG')}：news@acnews.com.au
            </div>
            <div className="foot_connect_phone">
              {intl.get('LIANXIDIANHUA')}：<a href='tel:0733233535' style={{ color: '#3fd5d3' }}>0733233535</a> {"&"} <a href='tel:0421217268' style={{ color: '#3fd5d3' }}>0421217268</a>
            </div>
            <div className="foot_connect_adress">
              {intl.get('XIANGXIDIZHI')}
            </div>
            <div className="foot_connect_adressdetail">
            18 Selvage St, Sunnybank QLD 4109
            </div>
          </div>
        </div>
        <div className="foot_bottom">
          <div className="foot_bottom_content">
            <div className="foot_bottom_title">
              {intl.get('KUSNHILIANYISOU')}
            </div>
            <div className="foot_bottom_title_bottom">
              Copyright © 2021 华友易搜Esoqld
            </div>
          </div>

        </div>
      </div>
    );
  }
}
