import { combineReducers }  from 'redux'
import user from './user'
import navdata from './nav'
import lang from './lang'

const rootReducer = combineReducers({
    user,
    navdata,
    lang
})


export default rootReducer
