import { httpPost } from '../utils/request'


const connect = {
    // 内容管理内容
    webBodyForWeb(parms) {
        const url = '/website/webBody/webBodyForWeb'
        let query = {}
        if (parms.mainTitle) {
            query.mainTitle = parms.mainTitle
        }
        if (parms.subTitle) {
            query.subTitle = parms.subTitle
        }

        if (parms.columnId) {
            query.columnId = parms.columnId
        }
        if (parms.language) {
            query.language = parms.language
        }

        if (parms.context) {
            query.context = parms.context
        }

        query.pageSize = parms.pageSize
        query.pageNum = parms.pageNum
        return httpPost(url, query)
    },
    // 发送邮件
    sendContent(parms) {
        const url = '/website/customer/sendContent'
        let query = {}
        if (parms.email) {
            query.email = parms.email
        }
        if (parms.context) {
            query.context = parms.context
        }
        return httpPost(url, query)
    },

}


export default connect