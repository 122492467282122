import React from 'react'
import './websearch.css'
import { Input, AutoComplete } from 'antd';
import {
  SearchOutlined
} from '@ant-design/icons'

import { debounce } from '../../utils'

import intl from 'react-intl-universal';




export default class WebSearch extends React.Component {
  constructor() {
    super()
    this.state = {
      result: '',
      options: []
    }

    this.handlethrottle = debounce(this.handlethrottle, 1000)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidMount() {
    this.setState({
      result: sessionStorage['SHOPSEARCHPARMS']
    })
    this.props.childEvevnt(this);
  }



  sendChild = () => {
    console.log('this.props', this.props.autoOptions)
    let arr = this.props.autoOptions
    this.setState({
      options: arr
    })

  }

  handleKeyDown = (e) => {
    console.log('ekey', e)
    if (e.keyCode === 13) {
      sessionStorage['SHOPSEARCHPARMS'] = e.target.value
      sessionStorage['mainTitle'] = e.target.value
      sessionStorage['MAINTYPE'] = ''
      sessionStorage['SECONDTYPE'] = ''
      sessionStorage['CITY'] = ''
      this.props.searchResult(e.target.value)
    }
  }

  handleChange = (e) => {
    this.setState({
      result: e.target.value
    })
    sessionStorage['SHOPSEARCHPARMS'] = e.target.value
    sessionStorage['MAINTYPE'] = ''
    sessionStorage['SECONDTYPE'] = ''
    sessionStorage['CITY'] = ''
    this.props.changeResult(e.target.value)
    document.addEventListener('keydown', this.handleKeyDown);
  }





  handlethrottle = (e) => {
    console.log('ee', e)
    this.props.setThrottle(e)
    this.setState({
      options: [{ value: e, label: e }]
    })
  }


  onSelect = (value) => {
    sessionStorage['SHOPSEARCHPARMS'] = value
    sessionStorage['MAINTYPE'] = ''
    sessionStorage['SECONDTYPE'] = ''
    sessionStorage['CITY'] = ''
    this.props.searchResult(value)
  }

  handleSearch = (value) => {
    this.handlethrottle(value)
  };


  testconsole = () => {
    console.log('test1', 1)
  }



  getRandomInt(max, min = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
  }


  render() {
    return (
      <div>
        <AutoComplete
          style={{ width: "100%" }}
          options={this.state.options}
          onSelect={this.onSelect}
          onSearch={this.handleSearch}
        >
          <Input
            placeholder={intl.get('QINGSHRUSH')}
            size="large"
            prefix={<SearchOutlined />}
            className="site-form-item-icon"
            onKeyDown={(e) => this.handleKeyDown(e)}
            value={this.state.result}
            onChange={this.handleChange}
          />
        </AutoComplete>
      </div>
    )
  }
}


