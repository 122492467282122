import intl from "react-intl-universal";
export function businessTransferType() {
    return (
        [
            {
                baseComponent: intl.get("JICHUXINXI"),
                show: true,
                id: 1,
                data: [
                    {
                        type: "specialSelect",
                        name: "childCategory",
                        require: false,
                        requireMsg: 'please select',
                        label: intl.get("XINGZXHI"),
                        typeData: [],
                      },
                    {
                        type: "input",
                        name: "mainTitle",
                        require: true,
                        requireMsg: 'please input',
                        label: intl.get("BIAOTIE"),
                    },
                    {
                        type: "radio",
                        name: "sellerType",
                        require: true,
                        requireMsg: 'please select',
                        label: intl.get("FATIELEIXING"),
                        typeData: [
                            {
                                label: intl.get("GEREN"),
                                value: 1,
                            },
                            {
                                label: intl.get("SHANGJIA"),
                                value: 2,
                            },
                            {
                                label: intl.get("ZHONGJIE"),
                                value: 3,
                            },
                        ],
                    },
                    {
                        type: "select",
                        name: "serviceType",
                        require: true,
                        requireMsg: 'please select',
                        mutial: true,
                        label: intl.get("FUWULEIXING"),
                        typeData: [
                            {
                                label: intl.get("SHENGYIZHUANRANG"),
                                value: 1,
                            },
                            {
                                label: intl.get("ZHAOSHANGJIAMENG"),
                                value: 2,
                            },
                            {
                                label: intl.get("QITA"),
                                value: 3,
                            },
                        ],
                    },
                    {
                        type: "select",
                        name: "regionId",
                        mutial: true,
                        require: true,
                        requireMsg: 'please select',
                        label: intl.get("SUOZAIQUYU"),
                        typeData: [
                            {
                                label: intl.get("BULISB"),
                                value: 1,
                            },
                            {
                                label: intl.get("HUANGJIANHAN"),
                                value: 2,
                            },
                            {
                                label: intl.get("YANGHUANGHANHAN"),
                                value: 3,
                            },
                            {
                                label: intl.get("KAIENSI"),
                                value: 4,
                            }
                        ]
                    },

                    {
                        type: "select",
                        name: "industry",
                        mutial: true,
                        require: true,
                        requireMsg: 'please select',
                        label: intl.get("HANGYE"),
                        typeData: [
                            {
                                label: intl.get("CANGTING"),
                                value: 1,
                            },
                            {
                                label: intl.get("CHAOSHI"),
                                value: 2,
                            },
                            {
                                label: intl.get("KAFEITING"),
                                value: 3,
                            },
                            {
                                label: intl.get("QINGJIE"),
                                value: 4,
                            },
                            {
                                label: intl.get("JIUDIAN"),
                                value: 5,
                            },
                            {
                                label: intl.get("LIPINGDIAN"),
                                value: 6,
                            },
                            {
                                label: intl.get("JIAYOUZHAN"),
                                value: 7,
                            },
                            {
                                label: intl.get("MIANBAODIAN"),
                                value: 8,
                            },
                            {
                                label: intl.get("NONGCHANG"),
                                value: 9,
                            },
                            {
                                label: intl.get("QITAHANGYE"),
                                value: 10,
                            },
                        ]
                    },

                    {
                        type: "input",
                        name: "companyName",
                        require: false,
                        requireMsg: 'please input',
                        label: intl.get("GONGSIMINGCHENG"),
                    },
                    {
                        type: "input",
                        name: "address",
                        require: false,
                        requireMsg: 'please input',
                        label: intl.get("DIZHI"),
                    },

                    {
                        type: "input",
                        name: "price",
                        require: true,
                        requireMsg: 'please input',
                        label: intl.get("JIAGE"),
                    },

                ]
            },

            {
                baseComponent: intl.get("XIANGQINGMIAOSHU"),
                show: true,
                id: 2,
                data: [
                    {
                        type: "edit",
                        name: "context",
                        require: true,
                        label: intl.get("XIANGQINGMIAOSHU"),
                    }
                ]
            },
            {
                baseComponent: intl.get("SHANGCHUANGTUPIAN"),
                show: true,
                id: 3,
                data: [
                    {
                        type: "upload",
                        name: "path",
                        require: false,
                        label: intl.get("SHANGCHUANGTUPIAN"),
                    }
                ]
            },

            {
                baseComponent: intl.get("LIANXIFANGSHI"),
                show: true,
                id: 4,
                data: [
                    {
                        type: "input",
                        name: "phone",
                        require: true,
                        requireMsg: 'please input',
                        label: intl.get("LIANXIDIANHUA"),
                    },
                    {
                        type: "input",
                        name: "email",
                        require: false,
                        label: intl.get("DIANZIYOUJIAN"),
                    },
                    {
                        type: "input",
                        name: "wechat",
                        require: false,
                        label: intl.get("WEIXIN"),
                    },
                    {
                        type: "input",
                        name: "line",
                        require: false,
                        label: intl.get("LINE"),
                    },
                    {
                        type: "input",
                        name: "facebook",
                        require: false,
                        label: intl.get("Facebook"),
                    },
                    {
                        type: "input",
                        name: "qq",
                        require: false,
                        label: intl.get("QQ"),
                    },
                ]
            }




        ]
    )
}