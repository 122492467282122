import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.less'
import './i18n'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers'
import { Provider} from 'react-redux'
import thunk from 'redux-thunk'






const store = createStore(rootReducer, {}, applyMiddleware(thunk))


const render = () => {
  ReactDOM.render(
    <Provider store= { store }>
     
     <App />
   
    </Provider>
   ,
  document.getElementById('root')
);
}

render()
store.subscribe(render)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
