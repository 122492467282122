import React from 'react'
import Nav from '../../component/nav/nav'
import WebSearch from '../../component/WebSearch/WebSearch'
import './components/all.css'
import intl from 'react-intl-universal';

import PopupSearch from '../../component/PopupSearch/PopupSearch'

import merchantsInfo from '../../api/yellow'


import { Search, Button } from 'antd-mobile'
export default class Main extends React.Component {
    constructor() {
        super()
        this.state = {
            yellowPageColumns: [],
            bbsColumns: [],
            popvisible: false,
            parentOptions: []
        }
    }
    getResult = (e) => {
        console.log('e1', e)
        this.props.history.push('/yellowPageList')
    }

    getChangeResult = (e) => {
        console.log('e2', e)
    }

    // 此事件接收子对象
    childEvevnt = childDate => {
        this.$child = childDate;
    };


    getThrottle = (e) => {

        merchantsInfo.merchantsInfoListWeb({
            merchantsName: e,
            pageSize: 10,
            pageNum: 1
        })
            .then(res => res.json())
            .then(result => {
                console.log('result3', result)
                if (result.ret === 200) {
                    this.setState({
                        parentOptions: result.data.list.map((item) => {
                            return {
                                value: item.merchantsName,
                                label: item.merchantsName,
                            }
                        })
                    }, () => {
                        this.$child.sendChild()
                    })
                }
            })

    }



    componentDidMount() {
    }





    handleGoTO = () => {
        this.props.history.push('/yellowpages')
    }

    handleGoTOBbs = () => {
        this.props.history.push('/bbspages')
    }

    handleLogin = () => {
        this.props.history.push('/login')
    }

    handleReginst = () => {
        sessionStorage["ISREGINS"] = 'true'
        this.handleLogin()
    }

    handleGoToConnect = () => {
        this.props.history.push('/connectus')
    }

    handleFocus = (e) => {
        this.setState({
            popvisible: true
        })
        this.$phoneSearchEvent.searchFocus()
    }

    searchEvevnt = (element) => {
        this.$phoneSearchEvent = element
    }

    handleSetVisible = (e) => {
        this.setState({
            popvisible: false
        })
    }

    gotoOutSide = () => {
        window.open("http://www.acnews.com.au")
    }

    judgeLogin = () => {
        if (!JSON.parse(sessionStorage.getItem("USERDATA"))) {
            return (
                <div className="phone_page_bottom">
                    <div className="phone_page_bottom_left">
                        <Button style={{ background: '#1890FF', color: '#fff', borderColor: '#1890FF', width: '100%' }} onClick={this.handleLogin}>
                            {intl.get('DENGLU')}
                        </Button>
                    </div>
                    <div className="phone_page_bottom_right">
                        <Button style={{ background: '#1F1F1F', color: '#fff', borderColor: '#1F1F1F', width: '100%' }} onClick={this.handleReginst}>
                            {intl.get('ZHUCE')}
                        </Button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="phone_page_bottom">
                    <div className="phone_page_bottom_left">

                    </div>
                    <div className="phone_page_bottom_right">

                    </div>
                </div>
            )

        }
    }

    render() {
        document.title = "EQLD昆士兰易搜-专注服务昆州本地社区";
        document.querySelector('meta[property="og:title"]').setAttribute('content', 'EQLD昆士兰易搜');
        document.querySelector('meta[property="og:description"]').setAttribute('content', '专注服务昆州本地社区');
        document.querySelector('meta[itemprop="description"]').setAttribute('content', '专注服务昆州本地社区');
        document.querySelector('meta[property="og:type"]').setAttribute('content', 'website');
        document.querySelector('meta[property="og:image"]').setAttribute('content', 'http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png');
        document.querySelector('meta[itemprop="image"]').setAttribute('content', 'http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png');
        document.querySelector('meta[itemprop="name"]').setAttribute('content', '专注服务昆州本地社区');




        return (
            <>
                <div className="main_content">
                    <Nav history={this.props.history} props={this.props} />
                    <div className="page_content">
                        <div className="page_content_left">
                            <div className="page_content_left_top">
                                <div className="page_content_left_top_line"></div>
                                <div className="page_content_left_top_icon"></div>

                            </div>
                            <div className="page_content_left_middle">
                                <span>{intl.get('FUWUYUAOZHOU')}</span>
                            </div>
                            <div className="page_content_left_bottom">
                                <WebSearch searchResult={this.getResult} changeResult={this.getChangeResult} autoOptions={this.state.parentOptions} setThrottle={this.getThrottle} childEvevnt={this.childEvevnt} />
                            </div>
                        </div>
                        <div className="page_content_right">
                            {/* 黄页 */}
                            <div className="page_content_right_first">
                                <div className="page_content_right_first_title">{intl.get('KUNSHILANYISOUHUANGYE')}</div>
                                {/* <div className="page_content_right_first_title">QLD Yellow Page</div> */}
                                <div className="page_content_right_first_line"></div>
                                <div className="page_content_right_first_column">
                                    {intl.get('MAINTAGE')}
                                </div>
                                <div className="page_content_right_first_button" onClick={this.handleGoTO}>{intl.get('LIJIJINRU')}</div>
                            </div>
                            <div className="page_content_right_second">
                                <div className="page_content_right_second_top">
                                    {/* 论坛 */}
                                    <div className="page_content_right_second_top_first" >
                                        <div className="page_content_right_first_title"> {intl.get('YISHOULUNT')}</div>
                                        {/* <div className="page_content_right_first_title">{intl.get('ENBBS')}</div> */}
                                        <div className="page_content_right_first_line"></div>
                                        <div className="page_content_right_first_column">{intl.get('BBSTAGE')}</div>
                                        <div className="page_content_right_first_column_button" onClick={this.handleGoTOBbs}>{intl.get('LIJIJINRU')}</div>

                                    </div>
                                    {/*联系我们*/}
                                    <div className="page_content_right_second_top_second">
                                        <div className="page_content_right_first_title"> {intl.get('LIANGXIWOMENTAG')}</div>
                                        <div className="page_content_right_first_line"></div>
                                        <div className="page_content_right_second_top_second_button" onClick={this.handleGoToConnect}>{intl.get('LIANXIWOMEN')}</div>
                                    </div>
                                </div>
                                {/* 华友 */}
                                <div className="page_content_right_second_bottom" onClick={this.gotoOutSide}>
                                    <div className="page_content_right_second_bottom_title">{intl.get('HUAYOUZHOUBAO')}</div>
                                    {/* <div className="page_content_right_second_bottom_title">{intl.get('ENBAOSHOU')}</div> */}
                                    <div className="page_content_right_second_bottom_line"></div>
                                    <div className="page_content_right_second_bottom_column">
                                        {intl.get('DACHANGJU')}
                                    </div>

                                    <div className="page_content_right_second_bottom_button" onClick={this.gotoOutSide}>{intl.get('DIANJIYUEDU')}</div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                {/* 移动端 */}
                <div className="phone_page">
                    <div className="phone_page_top">
                        <div className="phone_page_title">{intl.get('KUNSHILANYISHOU')}</div>
                        <div className="phone_page_subtitle">{intl.get('FAXIANKUNZHOU')}</div>
                        <div className="phone_page_top_search">
                            <Search placeholder={intl.get('KUNSHILANYISHOU')} onFocus={this.handleFocus} />
                            <PopupSearch visible={this.state.popvisible} type="1" history={this.props.history} setVisible={this.handleSetVisible} searchEvevnt={this.searchEvevnt} />

                        </div>
                    </div>
                    <div className="phone_page_middle">
                        <div className="phone_page_middle_first">
                            <div className="phone_page_middle_first_left">
                                <div className="phone_page_middle_first_left_title"> {intl.get('KUNSHILANYISOUHUANGYE')}</div>
                                <div className="phone_page_middle_first_left_subtitle">QLD Yellow Page</div>
                                <div className="phone_page_middle_first_left_column">
                                    {intl.get('MAINTAGE')}
                                </div>
                                <div className="phone_page_content_right_first_button" onClick={this.handleGoTO}>{intl.get('LIJIJINRU')}</div>

                            </div>
                            <div className="phone_page_middle_first_right">
                                {/* bbs */}
                                <div className="phone_page_middle_first_right_item">
                                    <div className="phone_page_middle_first_left_title">{intl.get('YISHOULUNT')}</div>
                                    <div className="phone_page_middle_first_left_subtitle">{intl.get('ENBBS')}</div>
                                    <div className="phone_page_middle_first_left_column">
                                        {intl.get('BBSTAGE')}
                                    </div>
                                    <div className="phone_page_content_right_first_button_bbs" onClick={this.handleGoTOBbs}>{intl.get('LIJIJINRU')}</div>

                                </div>

                            </div>
                        </div>
                        {/* 华友易搜 */}
                        <div className="phone_page_middle_second" onClick={this.gotoOutSide}>
                            <div className="phone_page_middle_first_left_title"> {intl.get('HUAYOUZHOUBAO')}</div>
                            <div className="phone_page_middle_first_left_title"> {intl.get('ENBAOSHOU')} </div>
                            <div className="phone_page_middle_first_left_column">
                                {intl.get('DACHANGJU')}
                            </div>
                            <div className="phone_page_middle_first_right_bottom_btn" onClick={this.gotoOutSide} style={{ marginTop: '10px' }}>{intl.get('DIANJIYUEDU')}</div>

                        </div>
                        {/* 联系我们 */}
                        <div className="phone_page_middle_first_right_bottom">
                            <div className="phone_page_middle_first_right_bottom_title">{intl.get('LIANGXIWOMENTAG')}</div>
                            <div className="phone_page_middle_first_right_bottom_btn" onClick={this.handleGoToConnect} style={{ marginTop: '10px' }}>{intl.get('LIANXIWOMEN')}</div>
                        </div>
                    </div>
                    {
                        this.judgeLogin()
                    }

                    <div className="phone_page_content_left_top_icon"></div>
                    <div className="phone_page_content_left_middle">
                        <span>{intl.get('FUWUYUAOZHOU')}</span>
                    </div>
                </div>
            </>
        )
    }
}