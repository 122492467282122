import {httpPost} from '../utils/request'


const column = {
    // 获取栏目
    columnInfoWeb(parms) {
        const url = '/website/columninfo/columnInfoWeb'
        let query = {}
        if (parms.columnId) {
            query.columnId = parms.columnId
        }
        
        if (parms.columnName) {
            query.columnName = parms.columnName
        }
        if (parms.categoryId) {
            query.categoryId = parms.categoryId
        }

        if (parms.parentId) {
            query.parentId = parms.parentId
        }
        if (parms.language) {
            query.language = parms.language
        }
        query.stat = 1
        query.pageSize = parms.pageSize
        query.pageNum = parms.pageNum
        return httpPost(url, parms)
    },
     
}


export default column