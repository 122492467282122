/*
 *@author shawnz
 *@Date 2021 0430
 **/

// 格式化参数
import qs from "qs";
import { message } from 'antd'
let baseURL = "";

let checkStatus = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  429: '请求速率过快',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
}

let baseURLOBj = {
  development: process.env.REACT_APP_DEV_API,
  test: "",
  production: process.env.REACT_APP_PRODUCT_API,
};
baseURL = baseURLOBj[process.env.NODE_ENV];
export function httpGet(url, query, token) {
  url = baseURL + url + "?" + qs.stringify(query);
  const result = fetch(url, {
    method: "GET",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      Accept: "application/json,text/plain,*/*",
      token: token,
    },
  });
  return result;
}

export function httpPost(url, query, token) {
  // console.log("process.env.NODE_ENV", process.env.NODE_ENV);

  url = baseURL + url;
  const result = fetch(url, {
    method: "POST",
    credentials: 'include',
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      Accept: "application/json,text/plain,*/*",
      token: token,
    },
    body: qs.stringify(query),
  });
  return result;
}



export function httpPostByJSON(url, query, token) {
  // console.log("process.env.NODE_ENV", process.env.NODE_ENV);

  url = baseURL + url;
  const result = fetch(url, {
    method: "POST",
    credentials: 'include',
    headers: {
      "Content-type": "application/json",
      Accept: "application/json,text/plain,*/*",
      token: token,
    },
    body: query,
  });
  return result;
}

export function httpPostWithUUID(url, query, token, uuid) {
  // console.log("process.env.NODE_ENV", process.env.NODE_ENV);

  url = baseURL + url;
  const result = fetch(url, {
    method: "POST",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      Accept: "application/json,text/plain,*/*",
      token: token,
      uuid: uuid
    },
    body: qs.stringify(query),
  }).then(res => {
    const { status } = res
    if (status === 200) {
      return res
    }
    const errorcontext = checkStatus[status] || res.status
    message.error(errorcontext)
    return res
  }).then(result => {
    return result
  }).then(data => data)

  return result
}

export function googleGet(query) {
  let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + query + '&key=AIzaSyB37jXlk9_-rcuRxc5YBB3qmLeKCl70UGU';
  const result = fetch(url, {
    method: "GET",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      Accept: "application/json,text/plain,*/*",
    },
  });
  return result;
}
