import React from 'react'


import { Search, Popup } from 'antd-mobile'
import './PopupSearch.css'
import intl from 'react-intl-universal';

export default class PopupSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: this.props.visible,
            historyResult: [],
            searchValue: '',
            cancelVisible: true
        }
        this.SearchRef = React.createRef()
    }


    static getDerivedStateFromProps = (props, state) => {

        if (props.visible !== state.visible) {
            return {
                visible: props.visible,
            };
        } else if (state.cancelVisible === false) {
            return {
                visible: false,
                cancelVisible: true
            };
        }

        return null
    }

    componentDidMount = () => {
        this.props.searchEvevnt(this);

        this.setState({
            historyResult: sessionStorage["HISTORYDATA"] ? Array.from(new Set(JSON.parse(sessionStorage["HISTORYDATA"]))) : []
        })

    }




    searchFocus = () => {
        setTimeout(() => {
            this.SearchRef.current?.focus()
        }, 200)

    }





    handleSearch = (val) => {
        let arr = this.state.historyResult
        arr.push(val)
        sessionStorage["HISTORYDATA"] = JSON.stringify(arr)
        sessionStorage['SHOPSEARCHPARMS'] = val
        sessionStorage['MAINTYPE'] = ''
        sessionStorage['SECONDTYPE'] = ''
        sessionStorage['CITY'] = ''
        switch (this.props?.type) {
            case "1":
                this.setState({
                    historyResult: arr
                }, () => {
                    this.handleCancel()
                    this.props.history.push('/yellowPageList')
                })

                break;
            case "2":
                this.setState({
                    historyResult: arr
                }, () => {
                    this.handleCancel()
                    this.props.history.push('/bbspagelist')
                })
                break;
            default:
                break;

        }
    }


    handleCancel = () => {
        this.setState({
            cancelVisible: false
        }, () => {
            this.props.setVisible(this.state.searchValue)
        })
    }

    setHistory = (val) => {
        this.setState({
            searchValue: val
        }, () => {
            this.handleSearch(val)
        })
    }

    handleChange = (e) => {
        this.setState({
            searchValue: e
        }, () => {
            sessionStorage['SHOPSEARCHPARMS'] = e
        })
    }

    handleClear = () => {
        sessionStorage["HISTORYDATA"] = null
        this.setState({
            historyResult: []
        })
    }
    handleClearValue = () => {
        this.setState({
            searchValue: ''
        })
    }
    render() {
        return (
            <Popup position='top' visible={this.state.visible} bodyStyle={{ height: '100vh' }}>
                <div className="phone_popup_search">
                    <Search
                        ref={this.SearchRef}
                        placeholder={intl.get('QINGSHURUNEIRONGSERA')}
                        showCancelButton
                        value={this.state.searchValue}
                        onSearch={this.handleSearch}
                        onChange={this.handleChange}
                        onCancel={this.handleCancel}
                        onClear={this.handleClearValue}
                    />

                    <div className="phone_popup_search_title">
                        <span className="phone_popup_search_title_left">{intl.get('SOUSUOLISHI')}</span>
                        <span className="phone_popup_search_title_right" onClick={this.handleClear}>{intl.get('QIGNCHULISHI')}</span>
                    </div>

                    <div className="phone_popup_search_history">
                        {
                            this.state.historyResult.map((item, index) => {
                                return (
                                    <div className="phone_popup_search_history_item" key={index} onClick={this.setHistory.bind(this, item)}>{item}</div>

                                )
                            })
                        }

                    </div>
                </div>
            </Popup>
        )
    }

}