import React from "react";
import { Avatar, message } from "antd";

import { LeftOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";

import { Popup, Input, Button } from "antd-mobile";
import "./PhoneChatDetail.css";
import intl from "react-intl-universal";
import ReactWebsocket from '../../pages/myplace/components/ReactWebsocket'

export default class PhoneChatDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageDetailVisible: this.props.messageDetailVisible,
      historyResult: [],
      searchValue: "",
      cancelVisible: true,
      chatData: this.props.chatDetail?.chatRecords,
      liveMessage: ''
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.messageDetailVisible !== state.messageDetailVisible) {
      return {
        messageDetailVisible: props.messageDetailVisible,
      };
    }
    return null;
  };


  componentDidMount() {
    this.props.childEvevnt(this);
  }


  sendChild = () => {
    this.setState({
      chatData: this.props.chatDetail?.chatRecords
    })
  }

  handleGoBack = () => {
    this.setState({
      messageDetailVisible: false
    }, () => {
      this.props.closeDetail()
      this.onClose()
    })
  };


  diffTitle = () => {
    // 商家接收信息
    if (JSON.parse(sessionStorage.getItem("USERDATA"))?.customer?.isMerchants !== 0) {
      return (
        <div className="message_detail_top_middle">
          <div>{this.props.chatDetail?.memberName}</div>
        </div>
      )
    } else {
      // 普通客户接收信息
      return (
        <div className="message_detail_top_middle">
          <div>{this.props.chatDetail?.userName}</div>
        </div>
      )
    }
  }


  diffContent = () => {
    // 商家接收信息
    if (JSON.parse(sessionStorage.getItem("USERDATA"))?.customer?.isMerchants !== 0) {

      return (
        this.state?.chatData?.map((item, index) => {
          console.log('item.userId', item.userId)
          // console.log("JSON.parse(sessionStorage.getItem('USERDATA'))?.customer.id", JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id)

          if (item.userId === JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id) {
            return (
              <div className="message_detail_content_right" key={index}>
                <div className="message_detail_content_right_text_item">
                  <span className="message_detail_content_right_text" dangerouslySetInnerHTML={{ __html: item.content }}></span>
                </div>
                <Avatar size={30} icon={<UserOutlined />} src={this.props.chatDetail.userPath} />
              </div>
            )
          } else {
            return (
              <div className="message_detail_content_left" key={index}>
                <Avatar size={30} icon={<UserOutlined />} src={this.props.chatDetail.memberPath} />{"      "}
                <div className="message_detail_content_left_item">
                  <span dangerouslySetInnerHTML={{ __html: item.content }}></span>
                </div>
              </div>
            )
          }
        })
      )

    } else {
      // 普通客户接收信息
      return (
        this.state?.chatData?.map((item, index) => {
          if (item.memberId === JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id) {
            return (
              <div className="message_detail_content_right" key={index}>
                <div className="message_detail_content_right_text_item">
                  <span className="message_detail_content_right_text" dangerouslySetInnerHTML={{ __html: item.content }}></span>
                </div>
                <Avatar size={30} icon={<UserOutlined />} src={this.props.chatDetail.memberPath} />
              </div>
            )
          } else {
            return (
              <div className="message_detail_content_left" key={index}>
                <Avatar size={30} icon={<UserOutlined />} src={this.props.chatDetail.userPath} />{"     "}
                <div className="message_detail_content_left_item">
                  <span dangerouslySetInnerHTML={{ __html: item.content }}></span>
                </div>
              </div>
            )
          }


        })
      )

    }
  }

  changeValue = (liveMessage) => {
    this.setState({
      liveMessage
    })
  }


  onMessage = (e) => {
    let arr = this.props.chatDetail
    let objmessage = JSON.parse(e)
    if (arr.memberId === objmessage.memberId || arr.userId === objmessage.userId) {
      arr.chatRecords?.push(objmessage)
      this.setState({
        chatData: arr.chatRecords,
      }, () => {
        var div = document.getElementById('contentmessage');
        div.scrollTop = div.scrollHeight;

      })
    }
  }

  onOpen = (e) => {
    var div = document.getElementById('contentmessage');
    console.log('div', div)
    div.scrollTop = div.scrollHeight;
  }

  onClose = (e) => {
    console.log('close', e)
  }


  handleSendMessage = (e) => {

    let sendMessage = this.state.liveMessage
    sendMessage = sendMessage.replace(/\s/g, '')
    if (sendMessage === '') {
      message.warning(intl.get('QINGSHURUNEIRONG'))
      return false
    }


    if (!this.props.chatDetail.userId) {
      this.props.chatDetail.userId = this.props.shopUserData.id
      this.props.chatDetail.userName = this.props.shopUserData.account
    }
    // 普通客户回复商家
    if (JSON.parse(sessionStorage.getItem("USERDATA"))?.customer?.isMerchants === 0) {
      let willmessage = {
        memberId: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
        memberName: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.account,
        content: this.state.liveMessage,
        shopUserId: this.props.chatDetail.userId,
        shopUserName: this.props.chatDetail.userName
      }
      // message : {
      //  memberId;  用户id
      //  memberName;用户名
      //  userId; 商家用户id
      //  userName;商家用户名
      //  content; 聊天数据
      //  shopUserId (用户发商家需要, shopUserData商家用户id )
      // shopUserName (用户发商家需要, shopUserData商家用户名字 )
      // }
      let arr = this.props.chatDetail
      if (this.props.chatDetail?.chatRecords) {

      }
      let objmessage = {
        content: this.state.liveMessage,
        memberId: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
        memberName: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.account,
        shopUserId: this.props.chatDetail.userId,
        shopUserName: this.props.chatDetail.userName
      }
      arr.chatRecords.push(objmessage)
      this.refWebSocket.refWebSocket.sendMessage(JSON.stringify(willmessage))
      this.setState({
        liveMessage: '',

      }, () => {
        var div = document.getElementById('contentmessage');
        div.scrollTop = div.scrollHeight;
      })
    } else {
      // 商家回复普通客户
      let willmessage = {
        userId: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
        userName: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.account,
        content: this.state.liveMessage,
        frameId: this.props.chatDetail.chatRecords[0].frameId
      }
      // message : {
      //  memberId;  用户id
      //  memberName;用户名
      //  userId; 商家用户id
      //  userName;商家用户名
      //  content; 聊天数据
      //  shopUserId (用户发商家需要, shopUserData商家用户id )
      // shopUserName (用户发商家需要, shopUserData商家用户名字 )
      // }
      let arr = this.props.chatDetail
      let objmessage = {
        content: this.state.liveMessage,
        userId: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
        userName: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.account,
        frameId: this.props.chatDetail.chatRecords[0].frameId
      }
      arr.chatRecords.push(objmessage)
      this.refWebSocket.refWebSocket.sendMessage(JSON.stringify(willmessage))
      this.setState({
        liveMessage: '',

      }, () => {
        var div = document.getElementById('contentmessage');
        div.scrollTop = div.scrollHeight;

      })
    }
  }




  render() {
    const obj = {
      user_id: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer.id,
      user_type: JSON.parse(sessionStorage.getItem("USERDATA"))?.customer?.isMerchants === 2 ? 1 : 2, // 1商家， 2普通客户
    }
    let webSocketUrl = 'wss://' + process.env.REACT_APP_WEBSOCKET_API + '/website/websocket?msg=' + encodeURI(JSON.stringify(obj))

    return (
      <Popup
        position="top"
        visible={this.state.messageDetailVisible}
        bodyStyle={{ height: "100vh" }}
      >
        <div className="message_detail">
          <div className="message_detail_top">
            <div className="message_detail_top_left">
              <LeftOutlined onClick={this.handleGoBack} />
            </div>
            {
              this.diffTitle()
            }
            <div className="message_detail_top_right">
              <a href={'tel:' + this.props.chatDetail?.userPhone}> <PhoneOutlined /></a>
            </div>
          </div>

          <div className="message_detail_content" id="contentmessage">
            {
              this.diffContent()
            }
          </div>

          <div className="message_detail_bottom">
            <Input
              value={this.state.liveMessage}
              placeholder={intl.get('QINGSHURUNEIRONGSERA')}
              className="send_box"
              onChange={this.changeValue}
            />

            <div className="send_box_btn"> <Button type="primary" style={{ background: '#6d9cf8', color: '#fff' }} onClick={this.handleSendMessage}>{intl.get('FASONG')}</Button></div>

          </div>

          <ReactWebsocket
            url={webSocketUrl}
            onMessage={this.onMessage} //接受信息的回调
            onOpen={this.onOpen} //websocket打开
            onClose={this.onClose} //websocket关闭
            reconnect={true}
            debug={true}
            ref={Websocket => {
              this.refWebSocket = Websocket;
            }}
          />
        </div>
      </Popup>
    );
  }
}
